<template>
  <div
    class="flex items-center gap-4"
    :class="{ '!pointer-events-none !opacity-60': disabled, ' w-full justify-between': fullWidth }"
  >
    <div
      v-if="label"
      class="text-skin-base text-sm font-medium leading-none"
      :class="{ 'whitespace-nowrap': wrap === false }"
    >
      {{ label }}
    </div>
    <Switch
      v-model="enabled"
      :class="[
        enabled ? 'bg-skin-accent' : 'bg-lightGray',
        'focus:ring-skin-accent relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2',
        { '!h-4 !w-8 focus:!ring-1 focus:!ring-offset-1': small },
      ]"
    >
      <span
        :class="[
          enabled ? (small ? 'translate-x-4' : 'translate-x-5') : 'translate-x-0',
          'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
          { '!h-[10px]  !w-[10px]': small },
        ]"
      >
        <span
          :class="[
            enabled ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in',
            'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
          ]"
          aria-hidden="true"
        >
          <svg
            class="text-skin-muted h-3 w-3"
            fill="none"
            viewBox="0 0 12 12"
            :class="{ '!h-2 !w-2': small }"
          >
            <path
              d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
        <span
          :class="[
            enabled ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out',
            'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
          ]"
          aria-hidden="true"
        >
          <svg
            class="text-skin-accent h-3 w-3"
            :class="{ '!h-2 !w-2': small }"
            fill="currentColor"
            viewBox="0 0 12 12"
          >
            <path
              d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"
            />
          </svg>
        </span>
      </span>
    </Switch>
  </div>
</template>

<script setup lang="ts">
import { Switch } from '@headlessui/vue';

type Props = {
  modelValue?: boolean;
  label?: string;
  disabled?: boolean;
  small?: boolean;
  fullWidth?: boolean;
  wrap?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  small: false,
});

type Emit = {
  (event: 'update:modelValue', value: boolean): void;
};

const emit = defineEmits<Emit>();

const enabled = useVModel(props, 'modelValue', emit);
</script>
