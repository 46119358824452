export default {
  cs_super_admin: [
    { subject: 'workspace.catalogues', action: 'export' },
    { subject: 'workspace.catalogues', action: 'view' },
    { subject: 'workspace.catalogues.annonces', action: 'update' },
    { subject: 'workspace.catalogues.annonces', action: 'view' },
    { subject: 'workspace.catalogues.articles', action: 'create' },
    { subject: 'workspace.catalogues.articles', action: 'update' },
    { subject: 'workspace.catalogues.articles', action: 'view' },
    { subject: 'workspace.catalogues.articles.gammes', action: 'create' },
    { subject: 'workspace.catalogues.articles.gammes', action: 'delete' },
    { subject: 'workspace.catalogues.articles.gammes', action: 'update' },
    { subject: 'workspace.catalogues.articles.gammes', action: 'view' },
    { subject: 'workspace.catalogues.articles.lots', action: 'create' },
    { subject: 'workspace.catalogues.articles.lots', action: 'delete' },
    { subject: 'workspace.catalogues.articles.lots', action: 'update' },
    { subject: 'workspace.catalogues.articles.lots', action: 'view' },
    { subject: 'workspace.catalogues.articles.prix', action: 'view' },
    { subject: 'workspace.catalogues.articles.prixModifiable', action: 'update' },
    { subject: 'workspace.catalogues.articles.types_articles', action: 'create' },
    { subject: 'workspace.catalogues.articles.types_articles', action: 'delete' },
    { subject: 'workspace.catalogues.articles.types_articles', action: 'update' },
    { subject: 'workspace.catalogues.articles.types_articles', action: 'view' },
    { subject: 'workspace.catalogues.constructions', action: 'view' },
    { subject: 'workspace.catalogues.constructions.gammes', action: 'delete' },
    { subject: 'workspace.catalogues.constructions.gammes', action: 'update' },
    { subject: 'workspace.catalogues.constructions.gammes', action: 'view' },
    { subject: 'workspace.catalogues.constructions.modeles', action: 'delete' },
    { subject: 'workspace.catalogues.constructions.modeles', action: 'update' },
    { subject: 'workspace.catalogues.constructions.modeles', action: 'view' },
    { subject: 'workspace.catalogues.metres', action: 'view' },
    { subject: 'workspace.catalogues.metres', action: 'update' },
    { subject: 'workspace.catalogues.metres.debours', action: 'view' },
    { subject: 'workspace.catalogues.metres.lignes', action: 'update' },
    { subject: 'workspace.catalogues.metres.lignes', action: 'delete' },
    { subject: 'workspace.catalogues.metres.lignes.debours', action: 'view' },
    { subject: 'workspace.catalogues.metres.lignes.prixunitaire', action: 'view' },
    { subject: 'workspace.catalogues.metres.marge', action: 'view' },
    { subject: 'workspace.catalogues.metres.options', action: 'create' },
    { subject: 'workspace.catalogues.metres.options.debours', action: 'view' },
    { subject: 'workspace.catalogues.metres.options.marge', action: 'view' },
    { subject: 'workspace.catalogues.terrains', action: 'delete' },
    { subject: 'workspace.catalogues.terrains', action: 'update' },
    { subject: 'workspace.catalogues.terrains', action: 'view' },
    { subject: 'workspace.contacts', action: 'view' },
    { subject: 'workspace.contacts.clients', action: 'create' },
    { subject: 'workspace.contacts.clients', action: 'update' },
    { subject: 'workspace.contacts.clients', action: 'view' },
    { subject: 'workspace.contacts.ssttfours', action: 'delete' },
    { subject: 'workspace.contacts.ssttfours', action: 'update' },
    { subject: 'workspace.contacts.ssttfours', action: 'view' },
    { subject: 'workspace.dashboard', action: 'view' },
    { subject: 'workspace.documents', action: 'update' },
    { subject: 'workspace.documents', action: 'view' },
    { subject: 'workspace.gesfi', action: 'view' },
    { subject: 'workspace.gesfi.achats', action: 'view' },
    { subject: 'workspace.gesfi.achats.commandes', action: 'view' },
    { subject: 'workspace.gesfi.achats.factures', action: 'view' },
    { subject: 'workspace.gesfi.achats.factures.import', action: 'view' },
    { subject: 'workspace.gesfi.achats.factures.reglements.status', action: 'update' },
    { subject: 'workspace.gesfi.achats.reglements', action: 'view' },
    { subject: 'workspace.gesfi.dashboards', action: 'view' },
    { subject: 'workspace.gesfi.dashboards.facturation', action: 'view' },
    { subject: 'workspace.gesfi.dashboards.ventes', action: 'view' },
    { subject: 'workspace.gesfi.export', action: 'view' },
    { subject: 'workspace.gesfi.ventes', action: 'view' },
    { subject: 'workspace.gesfi.ventes.suiviFactu', action: 'view' },
    { subject: 'workspace.parametres', action: 'update' },
    { subject: 'workspace.parametres', action: 'view' },
    { subject: 'workspace.parametres.diffusion', action: 'view' },
    { subject: 'workspace.parametres.diffusion.annonces', action: 'view' },
    { subject: 'workspace.parametres.diffusion.ubiflow', action: 'view' },
    { subject: 'workspace.parametres.documents', action: 'view' },
    { subject: 'workspace.parametres.documents.types', action: 'view' },
    { subject: 'workspace.parametres.editionsPdf', action: 'view' },
    { subject: 'workspace.parametres.editionsPdf.general', action: 'view' },
    { subject: 'workspace.parametres.editionsPdf.notices', action: 'view' },
    { subject: 'workspace.parametres.editionsPdf.ppcst', action: 'view' },
    { subject: 'workspace.parametres.financiers', action: 'view' },
    { subject: 'workspace.parametres.garants', action: 'update' },
    { subject: 'workspace.parametres.garants', action: 'view' },
    { subject: 'workspace.parametres.marque', action: 'view' },
    { subject: 'workspace.parametres.secteurs', action: 'update' },
    { subject: 'workspace.parametres.secteurs', action: 'view' },
    { subject: 'workspace.parametres.utilisateurs', action: 'view' },
    { subject: 'workspace.parametres.utilisateurs', action: 'update' },
    { subject: 'workspace.parametres.vente', action: 'view' },
    { subject: 'workspace.projets', action: 'cancel' },
    { subject: 'workspace.projets', action: 'create' },
    { subject: 'workspace.projets', action: 'delete' },
    { subject: 'workspace.projets', action: 'update' },
    { subject: 'workspace.projets', action: 'view' },
    { subject: 'workspace.projets.avps', action: 'create' },
    { subject: 'workspace.projets.avps', action: 'demande-nd' },
    { subject: 'workspace.projets.avps', action: 'update' },
    { subject: 'workspace.projets.avps', action: 'view' },
    { subject: 'workspace.projets.avps.documents.avp', action: 'create' },
    { subject: 'workspace.projets.avps.documents.davp', action: 'create' },
    { subject: 'workspace.projets.avps.documents.dnd', action: 'create' },
    { subject: 'workspace.projets.avps.documents.vt', action: 'create' },
    { subject: 'workspace.projets.avps.metres', action: 'create' },
    { subject: 'workspace.projets.avps.metres', action: 'delete' },
    { subject: 'workspace.projets.avps.metres', action: 'unlock' },
    { subject: 'workspace.projets.avps.metres', action: 'update' },
    { subject: 'workspace.projets.avps.metres', action: 'validate' },
    { subject: 'workspace.projets.avps.metres', action: 'view' },
    { subject: 'workspace.projets.avps.metres.debours', action: 'view' },
    { subject: 'workspace.projets.avps.metres.lignes', action: 'delete' },
    { subject: 'workspace.projets.avps.metres.lignes', action: 'edit' },
    { subject: 'workspace.projets.avps.metres.lignes', action: 'export' },
    { subject: 'workspace.projets.avps.metres.lignes', action: 'view' },
    { subject: 'workspace.projets.avps.metres.lignes.debours', action: 'view' },
    { subject: 'workspace.projets.avps.metres.lignes.description', action: 'edit' },
    { subject: 'workspace.projets.avps.metres.lignes.prixunitaire', action: 'view' },
    { subject: 'workspace.projets.avps.metres.marge', action: 'view' },
    { subject: 'workspace.projets.avps.metres.marge', action: 'update' },
    { subject: 'workspace.projets.avps.metres.options', action: 'create' },
    { subject: 'workspace.projets.avps.metres.options', action: 'update' },
    { subject: 'workspace.projets.avps.metres.options', action: 'view' },
    { subject: 'workspace.projets.avps.metres.options.debours', action: 'view' },
    { subject: 'workspace.projets.avps.metres.options.marge', action: 'view' },
    { subject: 'workspace.projets.avps.metres.parametres', action: 'update' },
    { subject: 'workspace.projets.avps.metres.parametres', action: 'view' },
    { subject: 'workspace.projets.avps.notices', action: 'edit' },
    { subject: 'workspace.projets.avps.notices', action: 'present' },
    { subject: 'workspace.projets.avps.notices.draft', action: 'view' },
    { subject: 'workspace.projets.contrat', action: 'update' },
    { subject: 'workspace.projets.contrat', action: 'view' },
    { subject: 'workspace.projets.contrat', action: 'view_prices' },
    { subject: 'workspace.projets.contrat.analytics', action: 'view' },
    { subject: 'workspace.projets.contrat.avenants', action: 'create' },
    { subject: 'workspace.projets.contrat.avenants', action: 'update' },
    { subject: 'workspace.projets.contrat.avenants', action: 'update_pdf' },
    { subject: 'workspace.projets.contrat.avenants', action: 'validate' },
    { subject: 'workspace.projets.contrat.avenants', action: 'view' },
    { subject: 'workspace.projets.contrat.document', action: 'delete' },
    { subject: 'workspace.projets.contrat.facturation', action: 'download' },
    { subject: 'workspace.projets.contrat.facturation', action: 'generate' },
    { subject: 'workspace.projets.contrat.facturation', action: 'record_payment' },
    { subject: 'workspace.projets.contrat.facturation', action: 'update' },
    { subject: 'workspace.projets.contrat.facturation', action: 'view' },
    { subject: 'workspace.projets.contrat.signature', action: 'update' },
    { subject: 'workspace.projets.contrat.validationAdv', action: 'update' },
    { subject: 'workspace.projets.delais', action: 'update' },
    { subject: 'workspace.projets.delais', action: 'view' },
    { subject: 'workspace.projets.indicateursVentes', action: 'update' },
    { subject: 'workspace.projets.pp', action: 'view' },
    { subject: 'workspace.projets.pp.ci', action: 'update' },
    { subject: 'workspace.projets.pp.ci', action: 'view' },
    { subject: 'workspace.projets.pp.epe', action: 'update' },
    { subject: 'workspace.projets.pp.epe', action: 'view' },
    { subject: 'workspace.projets.pp.epe.documents', action: 'update' },
    { subject: 'workspace.projets.pp.financement', action: 'update' },
    { subject: 'workspace.projets.pp.financement', action: 'view' },
    { subject: 'workspace.projets.pp.garanties', action: 'update' },
    { subject: 'workspace.projets.pp.garanties', action: 'view' },
    { subject: 'workspace.projets.pp.pc', action: 'update' },
    { subject: 'workspace.projets.pp.pc', action: 'view' },
    { subject: 'workspace.projets.pp.prepaschantiers', action: 'update' },
    { subject: 'workspace.projets.pp.prepaschantiers', action: 'view' },
    { subject: 'workspace.projets.pp.terrain', action: 'update' },
    { subject: 'workspace.projets.pp.terrain', action: 'view' },
    { subject: 'workspace.projets.sav', action: 'view' },
    { subject: 'workspace.projets.travaux', action: 'view' },
    { subject: 'workspace.projets.travaux.avenants', action: 'create' },
    { subject: 'workspace.projets.travaux.avenants', action: 'delete' },
    { subject: 'workspace.projets.travaux.avenants', action: 'update' },
    { subject: 'workspace.projets.travaux.avenants', action: 'view' },
    { subject: 'workspace.projets.travaux.cmds', action: 'assign_cmd' },
    { subject: 'workspace.projets.travaux.cmds', action: 'assign_mt' },
    { subject: 'workspace.projets.travaux.cmds', action: 'create' },
    { subject: 'workspace.projets.travaux.cmds', action: 'delete' },
    { subject: 'workspace.projets.travaux.cmds', action: 'update' },
    { subject: 'workspace.projets.travaux.cmds', action: 'view' },
    { subject: 'workspace.projets.travaux.cmds', action: 'view_margins' },
    { subject: 'workspace.projets.travaux.cmds', action: 'view_prices' },
    { subject: 'workspace.projets.travaux.cmds.cmd', action: 'update' },
    { subject: 'workspace.projets.travaux.cmds.cmd', action: 'view_prices' },
    { subject: 'workspace.projets.travaux.cmds.cmd.datesLiv', action: 'update' },
    { subject: 'workspace.projets.travaux.cmds.cmd.datesLiv', action: 'view_prices' },
    { subject: 'workspace.projets.travaux.cmds.cmd.factures', action: 'view' },
    { subject: 'workspace.projets.travaux.cmds.pdf', action: 'create' },
    { subject: 'workspace.projets.travaux.cmds.pdf', action: 'delete' },
    { subject: 'workspace.projets.travaux.cmds.pdf', action: 'update' },
    { subject: 'workspace.projets.travaux.cmds.pdf', action: 'view' },
    { subject: 'workspace.projets.travaux.cmds.pdf', action: 'view_prices' },
    { subject: 'workspace.projets.travaux.documents', action: 'create' },
    { subject: 'workspace.projets.travaux.documents', action: 'delete' },
    { subject: 'workspace.projets.travaux.documents', action: 'view' },
    { subject: 'workspace.projets.travaux.planification', action: 'update' },
    { subject: 'workspace.projets.travaux.planification', action: 'view' },
    { subject: 'workspace.projets.travaux.planification.reception', action: 'view' },
    { subject: 'workspace.travaux', action: 'view' },
    { subject: 'workspace.travaux.marches', action: 'update' },
    { subject: 'workspace.travaux.marches', action: 'view' },
    { subject: 'workspace.travaux.mt', action: 'validate' },
    { subject: 'workspace.travaux.mt', action: 'view' },
    { subject: 'workspace.widgets.commandesSurfacturees', action: 'view' },
    { subject: 'workspace.widgets.contratSignes', action: 'view' },
    { subject: 'workspace.widgets.demandeAvp', action: 'view' },
    { subject: 'workspace.widgets.pipeCommerciale', action: 'view' },
  ],
  cs_admin: [
    { subject: 'workspace.catalogues', action: 'export' },
    { subject: 'workspace.catalogues', action: 'view' },
    { subject: 'workspace.catalogues.annonces', action: 'update' },
    { subject: 'workspace.catalogues.annonces', action: 'view' },
    { subject: 'workspace.catalogues.articles', action: 'create' },
    { subject: 'workspace.catalogues.articles', action: 'update' },
    { subject: 'workspace.catalogues.articles', action: 'view' },
    { subject: 'workspace.catalogues.articles.gammes', action: 'create' },
    { subject: 'workspace.catalogues.articles.gammes', action: 'delete' },
    { subject: 'workspace.catalogues.articles.gammes', action: 'update' },
    { subject: 'workspace.catalogues.articles.gammes', action: 'view' },
    { subject: 'workspace.catalogues.articles.lots', action: 'create' },
    { subject: 'workspace.catalogues.articles.lots', action: 'delete' },
    { subject: 'workspace.catalogues.articles.lots', action: 'update' },
    { subject: 'workspace.catalogues.articles.lots', action: 'view' },
    { subject: 'workspace.catalogues.articles.prix', action: 'view' },
    { subject: 'workspace.catalogues.articles.prixModifiable', action: 'update' },
    { subject: 'workspace.catalogues.articles.types_articles', action: 'create' },
    { subject: 'workspace.catalogues.articles.types_articles', action: 'delete' },
    { subject: 'workspace.catalogues.articles.types_articles', action: 'update' },
    { subject: 'workspace.catalogues.articles.types_articles', action: 'view' },
    { subject: 'workspace.catalogues.constructions', action: 'view' },
    { subject: 'workspace.catalogues.constructions.gammes', action: 'delete' },
    { subject: 'workspace.catalogues.constructions.gammes', action: 'update' },
    { subject: 'workspace.catalogues.constructions.gammes', action: 'view' },
    { subject: 'workspace.catalogues.constructions.modeles', action: 'delete' },
    { subject: 'workspace.catalogues.constructions.modeles', action: 'update' },
    { subject: 'workspace.catalogues.constructions.modeles', action: 'view' },
    { subject: 'workspace.catalogues.metres', action: 'view' },
    { subject: 'workspace.catalogues.metres', action: 'update' },
    { subject: 'workspace.catalogues.metres.debours', action: 'view' },
    { subject: 'workspace.catalogues.metres.lignes', action: 'update' },
    { subject: 'workspace.catalogues.metres.lignes', action: 'delete' },
    { subject: 'workspace.catalogues.metres.lignes.debours', action: 'view' },
    { subject: 'workspace.catalogues.metres.lignes.prixunitaire', action: 'view' },
    { subject: 'workspace.catalogues.metres.marge', action: 'view' },
    { subject: 'workspace.catalogues.metres.options', action: 'create' },
    { subject: 'workspace.catalogues.metres.options.debours', action: 'view' },
    { subject: 'workspace.catalogues.metres.options.marge', action: 'view' },
    { subject: 'workspace.catalogues.terrains', action: 'delete' },
    { subject: 'workspace.catalogues.terrains', action: 'update' },
    { subject: 'workspace.catalogues.terrains', action: 'view' },
    { subject: 'workspace.contacts', action: 'view' },
    { subject: 'workspace.contacts.clients', action: 'create' },
    { subject: 'workspace.contacts.clients', action: 'update' },
    { subject: 'workspace.contacts.clients', action: 'view' },
    { subject: 'workspace.contacts.ssttfours', action: 'delete' },
    { subject: 'workspace.contacts.ssttfours', action: 'update' },
    { subject: 'workspace.contacts.ssttfours', action: 'view' },
    { subject: 'workspace.dashboard', action: 'view' },
    { subject: 'workspace.documents', action: 'update' },
    { subject: 'workspace.documents', action: 'view' },
    { subject: 'workspace.gesfi', action: 'view' },
    { subject: 'workspace.gesfi.achats', action: 'view' },
    { subject: 'workspace.gesfi.achats.commandes', action: 'view' },
    { subject: 'workspace.gesfi.achats.factures', action: 'view' },
    { subject: 'workspace.gesfi.achats.factures.import', action: 'view' },
    { subject: 'workspace.gesfi.achats.factures.reglements.status', action: 'update' },
    { subject: 'workspace.gesfi.achats.reglements', action: 'view' },
    { subject: 'workspace.gesfi.dashboards', action: 'view' },
    { subject: 'workspace.gesfi.dashboards.facturation', action: 'view' },
    { subject: 'workspace.gesfi.dashboards.ventes', action: 'view' },
    { subject: 'workspace.gesfi.export', action: 'view' },
    { subject: 'workspace.gesfi.ventes', action: 'view' },
    { subject: 'workspace.gesfi.ventes.factures', action: 'view' },
    { subject: 'workspace.gesfi.ventes.suiviFactu', action: 'view' },
    { subject: 'workspace.parametres', action: 'update' },
    { subject: 'workspace.parametres', action: 'view' },
    { subject: 'workspace.parametres.diffusion', action: 'view' },
    { subject: 'workspace.parametres.diffusion.annonces', action: 'view' },
    { subject: 'workspace.parametres.diffusion.ubiflow', action: 'view' },
    { subject: 'workspace.parametres.documents', action: 'view' },
    { subject: 'workspace.parametres.documents.types', action: 'view' },
    { subject: 'workspace.parametres.editionsPdf', action: 'view' },
    { subject: 'workspace.parametres.editionsPdf.general', action: 'view' },
    { subject: 'workspace.parametres.editionsPdf.notices', action: 'view' },
    { subject: 'workspace.parametres.editionsPdf.ppcst', action: 'view' },
    { subject: 'workspace.parametres.financiers', action: 'view' },
    { subject: 'workspace.parametres.garants', action: 'update' },
    { subject: 'workspace.parametres.garants', action: 'view' },
    { subject: 'workspace.parametres.marque', action: 'view' },
    { subject: 'workspace.parametres.secteurs', action: 'update' },
    { subject: 'workspace.parametres.secteurs', action: 'view' },
    { subject: 'workspace.parametres.utilisateurs', action: 'view' },
    { subject: 'workspace.parametres.utilisateurs', action: 'update' },
    { subject: 'workspace.parametres.vente', action: 'view' },
    { subject: 'workspace.projets', action: 'cancel' },
    { subject: 'workspace.projets', action: 'create' },
    { subject: 'workspace.projets', action: 'delete' },
    { subject: 'workspace.projets', action: 'update' },
    { subject: 'workspace.projets', action: 'view' },
    { subject: 'workspace.projets.avps', action: 'create' },
    { subject: 'workspace.projets.avps', action: 'demande-nd' },
    { subject: 'workspace.projets.avps', action: 'update' },
    { subject: 'workspace.projets.avps', action: 'view' },
    { subject: 'workspace.projets.avps.documents.avp', action: 'create' },
    { subject: 'workspace.projets.avps.documents.davp', action: 'create' },
    { subject: 'workspace.projets.avps.documents.dnd', action: 'create' },
    { subject: 'workspace.projets.avps.documents.vt', action: 'create' },
    { subject: 'workspace.projets.avps.metres', action: 'create' },
    { subject: 'workspace.projets.avps.metres', action: 'delete' },
    { subject: 'workspace.projets.avps.metres', action: 'unlock' },
    { subject: 'workspace.projets.avps.metres', action: 'update' },
    { subject: 'workspace.projets.avps.metres', action: 'validate' },
    { subject: 'workspace.projets.avps.metres', action: 'view' },
    { subject: 'workspace.projets.avps.metres.debours', action: 'view' },
    { subject: 'workspace.projets.avps.metres.lignes', action: 'delete' },
    { subject: 'workspace.projets.avps.metres.lignes', action: 'edit' },
    { subject: 'workspace.projets.avps.metres.lignes', action: 'export' },
    { subject: 'workspace.projets.avps.metres.lignes', action: 'view' },
    { subject: 'workspace.projets.avps.metres.lignes.debours', action: 'view' },
    { subject: 'workspace.projets.avps.metres.lignes.description', action: 'edit' },
    { subject: 'workspace.projets.avps.metres.lignes.prixunitaire', action: 'view' },
    { subject: 'workspace.projets.avps.metres.marge', action: 'view' },
    { subject: 'workspace.projets.avps.metres.marge', action: 'update' },
    { subject: 'workspace.projets.avps.metres.options', action: 'create' },
    { subject: 'workspace.projets.avps.metres.options', action: 'update' },
    { subject: 'workspace.projets.avps.metres.options', action: 'view' },
    { subject: 'workspace.projets.avps.metres.options.debours', action: 'view' },
    { subject: 'workspace.projets.avps.metres.options.marge', action: 'view' },
    { subject: 'workspace.projets.avps.metres.parametres', action: 'update' },
    { subject: 'workspace.projets.avps.metres.parametres', action: 'view' },
    { subject: 'workspace.projets.avps.notices', action: 'edit' },
    { subject: 'workspace.projets.avps.notices', action: 'present' },
    { subject: 'workspace.projets.avps.notices.draft', action: 'view' },
    { subject: 'workspace.projets.contrat', action: 'update' },
    { subject: 'workspace.projets.contrat', action: 'view' },
    { subject: 'workspace.projets.contrat', action: 'view_prices' },
    { subject: 'workspace.projets.contrat.analytics', action: 'view' },
    { subject: 'workspace.projets.contrat.avenants', action: 'create' },
    { subject: 'workspace.projets.contrat.avenants', action: 'update' },
    { subject: 'workspace.projets.contrat.avenants', action: 'update_pdf' },
    { subject: 'workspace.projets.contrat.avenants', action: 'validate' },
    { subject: 'workspace.projets.contrat.avenants', action: 'view' },
    { subject: 'workspace.projets.contrat.document', action: 'delete' },
    { subject: 'workspace.projets.contrat.facturation', action: 'download' },
    { subject: 'workspace.projets.contrat.facturation', action: 'generate' },
    { subject: 'workspace.projets.contrat.facturation', action: 'record_payment' },
    { subject: 'workspace.projets.contrat.facturation', action: 'update' },
    { subject: 'workspace.projets.contrat.facturation', action: 'view' },
    { subject: 'workspace.projets.contrat.signature', action: 'update' },
    { subject: 'workspace.projets.contrat.validationAdv', action: 'update' },
    { subject: 'workspace.projets.delais', action: 'update' },
    { subject: 'workspace.projets.delais', action: 'view' },
    { subject: 'workspace.projets.indicateursVentes', action: 'update' },
    { subject: 'workspace.projets.pp', action: 'view' },
    { subject: 'workspace.projets.pp.ci', action: 'update' },
    { subject: 'workspace.projets.pp.ci', action: 'view' },
    { subject: 'workspace.projets.pp.epe', action: 'update' },
    { subject: 'workspace.projets.pp.epe', action: 'view' },
    { subject: 'workspace.projets.pp.epe.documents', action: 'update' },
    { subject: 'workspace.projets.pp.financement', action: 'update' },
    { subject: 'workspace.projets.pp.financement', action: 'view' },
    { subject: 'workspace.projets.pp.garanties', action: 'update' },
    { subject: 'workspace.projets.pp.garanties', action: 'view' },
    { subject: 'workspace.projets.pp.pc', action: 'update' },
    { subject: 'workspace.projets.pp.pc', action: 'view' },
    { subject: 'workspace.projets.pp.prepaschantiers', action: 'update' },
    { subject: 'workspace.projets.pp.prepaschantiers', action: 'view' },
    { subject: 'workspace.projets.pp.terrain', action: 'update' },
    { subject: 'workspace.projets.pp.terrain', action: 'view' },
    { subject: 'workspace.projets.sav', action: 'view' },
    { subject: 'workspace.projets.travaux', action: 'view' },
    { subject: 'workspace.projets.travaux.avenants', action: 'create' },
    { subject: 'workspace.projets.travaux.avenants', action: 'delete' },
    { subject: 'workspace.projets.travaux.avenants', action: 'update' },
    { subject: 'workspace.projets.travaux.avenants', action: 'view' },
    { subject: 'workspace.projets.travaux.cmds', action: 'assign_cmd' },
    { subject: 'workspace.projets.travaux.cmds', action: 'assign_mt' },
    { subject: 'workspace.projets.travaux.cmds', action: 'create' },
    { subject: 'workspace.projets.travaux.cmds', action: 'delete' },
    { subject: 'workspace.projets.travaux.cmds', action: 'update' },
    { subject: 'workspace.projets.travaux.cmds', action: 'view' },
    { subject: 'workspace.projets.travaux.cmds', action: 'view_margins' },
    { subject: 'workspace.projets.travaux.cmds', action: 'view_prices' },
    { subject: 'workspace.projets.travaux.cmds.cmd', action: 'update' },
    { subject: 'workspace.projets.travaux.cmds.cmd', action: 'view_prices' },
    { subject: 'workspace.projets.travaux.cmds.cmd.datesLiv', action: 'update' },
    { subject: 'workspace.projets.travaux.cmds.cmd.datesLiv', action: 'view_prices' },
    { subject: 'workspace.projets.travaux.cmds.cmd.factures', action: 'view' },
    { subject: 'workspace.projets.travaux.cmds.pdf', action: 'create' },
    { subject: 'workspace.projets.travaux.cmds.pdf', action: 'delete' },
    { subject: 'workspace.projets.travaux.cmds.pdf', action: 'update' },
    { subject: 'workspace.projets.travaux.cmds.pdf', action: 'view' },
    { subject: 'workspace.projets.travaux.cmds.pdf', action: 'view_prices' },
    { subject: 'workspace.projets.travaux.documents', action: 'create' },
    { subject: 'workspace.projets.travaux.documents', action: 'delete' },
    { subject: 'workspace.projets.travaux.documents', action: 'view' },
    { subject: 'workspace.projets.travaux.planification', action: 'update' },
    { subject: 'workspace.projets.travaux.planification', action: 'view' },
    { subject: 'workspace.projets.travaux.planification.reception', action: 'view' },
    { subject: 'workspace.travaux', action: 'view' },
    { subject: 'workspace.travaux.marches', action: 'update' },
    { subject: 'workspace.travaux.marches', action: 'view' },
    { subject: 'workspace.travaux.mt', action: 'validate' },
    { subject: 'workspace.travaux.mt', action: 'view' },
    { subject: 'workspace.widgets.commandesSurfacturees', action: 'view' },
    { subject: 'workspace.widgets.contratSignes', action: 'view' },
    { subject: 'workspace.widgets.demandeAvp', action: 'view' },
    { subject: 'workspace.widgets.pipeCommerciale', action: 'view' },
  ],
  cs_assistant: [
    { subject: 'workspace.catalogues', action: 'view' },
    { subject: 'workspace.catalogues.annonces', action: 'view' },
    { subject: 'workspace.catalogues.constructions', action: 'view' },
    { subject: 'workspace.catalogues.constructions.gammes', action: 'view' },
    { subject: 'workspace.catalogues.constructions.modeles', action: 'view' },
    { subject: 'workspace.catalogues.terrains', action: 'view' },
    { subject: 'workspace.contacts', action: 'view' },
    { subject: 'workspace.contacts.clients', action: 'create' },
    { subject: 'workspace.contacts.clients', action: 'update' },
    { subject: 'workspace.contacts.clients', action: 'view' },
    { subject: 'workspace.contacts.ssttfours', action: 'delete' },
    { subject: 'workspace.contacts.ssttfours', action: 'update' },
    { subject: 'workspace.contacts.ssttfours', action: 'view' },
    { subject: 'workspace.dashboard', action: 'view' },
    { subject: 'workspace.documents', action: 'update' },
    { subject: 'workspace.documents', action: 'view' },
    { subject: 'workspace.gesfi', action: 'view' },
    { subject: 'workspace.gesfi.achats', action: 'view' },
    { subject: 'workspace.gesfi.achats.commandes', action: 'view' },
    { subject: 'workspace.gesfi.achats.factures', action: 'view' },
    { subject: 'workspace.gesfi.achats.factures.import', action: 'view' },
    { subject: 'workspace.gesfi.achats.factures.reglements.status', action: 'update' },
    { subject: 'workspace.gesfi.achats.reglements', action: 'view' },
    { subject: 'workspace.gesfi.export', action: 'view' },
    { subject: 'workspace.gesfi.ventes', action: 'view' },
    { subject: 'workspace.gesfi.ventes.factures', action: 'view' },
    { subject: 'workspace.gesfi.ventes.suiviFactu', action: 'view' },
    { subject: 'workspace.parametres', action: 'update' },
    { subject: 'workspace.parametres', action: 'view' },
    { subject: 'workspace.parametres.diffusion', action: 'view' },
    { subject: 'workspace.parametres.diffusion.annonces', action: 'view' },
    { subject: 'workspace.parametres.diffusion.ubiflow', action: 'view' },
    { subject: 'workspace.parametres.documents', action: 'view' },
    { subject: 'workspace.parametres.documents.types', action: 'view' },
    { subject: 'workspace.parametres.editionsPdf', action: 'view' },
    { subject: 'workspace.parametres.editionsPdf.general', action: 'view' },
    { subject: 'workspace.parametres.editionsPdf.ppcst', action: 'view' },
    { subject: 'workspace.parametres.financiers', action: 'view' },
    { subject: 'workspace.parametres.garants', action: 'update' },
    { subject: 'workspace.parametres.garants', action: 'view' },
    { subject: 'workspace.parametres.marque', action: 'view' },
    { subject: 'workspace.parametres.secteurs', action: 'update' },
    { subject: 'workspace.parametres.secteurs', action: 'view' },
    { subject: 'workspace.parametres.utilisateurs', action: 'view' },
    { subject: 'workspace.parametres.utilisateurs', action: 'update' },
    { subject: 'workspace.parametres.vente', action: 'view' },
    { subject: 'workspace.projets', action: 'cancel' },
    { subject: 'workspace.projets', action: 'create' },
    { subject: 'workspace.projets', action: 'update' },
    { subject: 'workspace.projets', action: 'view' },
    { subject: 'workspace.projets.avps', action: 'update' },
    { subject: 'workspace.projets.avps', action: 'view' },
    { subject: 'workspace.projets.avps.documents.davp', action: 'create' },
    { subject: 'workspace.projets.avps.documents.vt', action: 'create' },
    { subject: 'workspace.projets.avps.notices', action: 'edit' },
    { subject: 'workspace.projets.avps.notices.draft', action: 'view' },
    { subject: 'workspace.projets.contrat', action: 'update' },
    { subject: 'workspace.projets.contrat', action: 'view' },
    { subject: 'workspace.projets.contrat', action: 'view_prices' },
    { subject: 'workspace.projets.contrat.avenants', action: 'create' },
    { subject: 'workspace.projets.contrat.avenants', action: 'update' },
    { subject: 'workspace.projets.contrat.avenants', action: 'update_pdf' },
    { subject: 'workspace.projets.contrat.avenants', action: 'view' },
    { subject: 'workspace.projets.contrat.facturation', action: 'download' },
    { subject: 'workspace.projets.contrat.facturation', action: 'record_payment' },
    { subject: 'workspace.projets.contrat.facturation', action: 'update' },
    { subject: 'workspace.projets.contrat.facturation', action: 'view' },
    { subject: 'workspace.projets.contrat.signature', action: 'update' },
    { subject: 'workspace.projets.contrat.validationAdv', action: 'update' },
    { subject: 'workspace.projets.delais', action: 'view' },
    { subject: 'workspace.projets.pp', action: 'view' },
    { subject: 'workspace.projets.pp.ci', action: 'update' },
    { subject: 'workspace.projets.pp.ci', action: 'view' },
    { subject: 'workspace.projets.pp.epe', action: 'update' },
    { subject: 'workspace.projets.pp.epe', action: 'view' },
    { subject: 'workspace.projets.pp.epe.documents', action: 'update' },
    { subject: 'workspace.projets.pp.financement', action: 'update' },
    { subject: 'workspace.projets.pp.financement', action: 'view' },
    { subject: 'workspace.projets.pp.garanties', action: 'update' },
    { subject: 'workspace.projets.pp.garanties', action: 'view' },
    { subject: 'workspace.projets.pp.pc', action: 'update' },
    { subject: 'workspace.projets.pp.pc', action: 'view' },
    { subject: 'workspace.projets.pp.prepaschantiers', action: 'update' },
    { subject: 'workspace.projets.pp.prepaschantiers', action: 'view' },
    { subject: 'workspace.projets.pp.terrain', action: 'update' },
    { subject: 'workspace.projets.pp.terrain', action: 'view' },
    { subject: 'workspace.projets.sav', action: 'view' },
    { subject: 'workspace.projets.travaux', action: 'view' },
    { subject: 'workspace.projets.travaux.avenants', action: 'create' },
    { subject: 'workspace.projets.travaux.avenants', action: 'delete' },
    { subject: 'workspace.projets.travaux.avenants', action: 'update' },
    { subject: 'workspace.projets.travaux.avenants', action: 'view' },
    { subject: 'workspace.projets.travaux.cmds', action: 'assign_cmd' },
    { subject: 'workspace.projets.travaux.cmds', action: 'assign_mt' },
    { subject: 'workspace.projets.travaux.cmds', action: 'create' },
    { subject: 'workspace.projets.travaux.cmds', action: 'update' },
    { subject: 'workspace.projets.travaux.cmds', action: 'view' },
    { subject: 'workspace.projets.travaux.cmds', action: 'view_margins' },
    { subject: 'workspace.projets.travaux.cmds', action: 'view_prices' },
    { subject: 'workspace.projets.travaux.cmds.cmd', action: 'update' },
    { subject: 'workspace.projets.travaux.cmds.cmd', action: 'view_prices' },
    { subject: 'workspace.projets.travaux.cmds.cmd.datesLiv', action: 'update' },
    { subject: 'workspace.projets.travaux.cmds.cmd.datesLiv', action: 'view_prices' },
    { subject: 'workspace.projets.travaux.cmds.cmd.factures', action: 'view' },
    { subject: 'workspace.projets.travaux.cmds.pdf', action: 'view' },
    { subject: 'workspace.projets.travaux.cmds.pdf', action: 'view_prices' },
    { subject: 'workspace.projets.travaux.documents', action: 'create' },
    { subject: 'workspace.projets.travaux.documents', action: 'view' },
    { subject: 'workspace.projets.travaux.planification', action: 'view' },
    { subject: 'workspace.travaux', action: 'view' },
    { subject: 'workspace.travaux.marches', action: 'update' },
    { subject: 'workspace.travaux.marches', action: 'view' },
    { subject: 'workspace.widgets.commandesSurfacturees', action: 'view' },
    { subject: 'workspace.widgets.pipeCommerciale', action: 'view' },
  ],
  cs_respbe: [
    { subject: 'workspace.catalogues', action: 'view' },
    { subject: 'workspace.catalogues.annonces', action: 'view' },
    { subject: 'workspace.catalogues.articles', action: 'create' },
    { subject: 'workspace.catalogues.articles', action: 'update' },
    { subject: 'workspace.catalogues.articles', action: 'view' },
    { subject: 'workspace.catalogues.articles.gammes', action: 'create' },
    { subject: 'workspace.catalogues.articles.gammes', action: 'update' },
    { subject: 'workspace.catalogues.articles.gammes', action: 'view' },
    { subject: 'workspace.catalogues.articles.lots', action: 'create' },
    { subject: 'workspace.catalogues.articles.lots', action: 'update' },
    { subject: 'workspace.catalogues.articles.lots', action: 'view' },
    { subject: 'workspace.catalogues.articles.prix', action: 'view' },
    { subject: 'workspace.catalogues.articles.prixModifiable', action: 'update' },
    { subject: 'workspace.catalogues.articles.types_articles', action: 'create' },
    { subject: 'workspace.catalogues.articles.types_articles', action: 'update' },
    { subject: 'workspace.catalogues.articles.types_articles', action: 'view' },
    { subject: 'workspace.catalogues.constructions', action: 'view' },
    { subject: 'workspace.catalogues.constructions.gammes', action: 'delete' },
    { subject: 'workspace.catalogues.constructions.gammes', action: 'update' },
    { subject: 'workspace.catalogues.constructions.gammes', action: 'view' },
    { subject: 'workspace.catalogues.constructions.modeles', action: 'delete' },
    { subject: 'workspace.catalogues.constructions.modeles', action: 'update' },
    { subject: 'workspace.catalogues.constructions.modeles', action: 'view' },
    { subject: 'workspace.catalogues.metres', action: 'view' },
    { subject: 'workspace.catalogues.metres', action: 'update' },
    { subject: 'workspace.catalogues.metres.debours', action: 'view' },
    { subject: 'workspace.catalogues.metres.lignes', action: 'update' },
    { subject: 'workspace.catalogues.metres.lignes', action: 'delete' },
    { subject: 'workspace.catalogues.metres.lignes.debours', action: 'view' },
    { subject: 'workspace.catalogues.metres.lignes.prixunitaire', action: 'view' },
    { subject: 'workspace.catalogues.metres.marge', action: 'view' },
    { subject: 'workspace.catalogues.metres.options', action: 'create' },
    { subject: 'workspace.catalogues.metres.options.debours', action: 'view' },
    { subject: 'workspace.catalogues.metres.options.marge', action: 'view' },
    { subject: 'workspace.catalogues.terrains', action: 'view' },
    { subject: 'workspace.contacts', action: 'view' },
    { subject: 'workspace.contacts.clients', action: 'create' },
    { subject: 'workspace.contacts.clients', action: 'update' },
    { subject: 'workspace.contacts.clients', action: 'view' },
    { subject: 'workspace.contacts.ssttfours', action: 'update' },
    { subject: 'workspace.contacts.ssttfours', action: 'view' },
    { subject: 'workspace.dashboard', action: 'view' },
    { subject: 'workspace.documents', action: 'update' },
    { subject: 'workspace.documents', action: 'view' },
    { subject: 'workspace.gesfi', action: 'view' },
    { subject: 'workspace.parametres', action: 'update' },
    { subject: 'workspace.parametres', action: 'view' },
    { subject: 'workspace.parametres.editionsPdf', action: 'view' },
    { subject: 'workspace.parametres.editionsPdf.notices', action: 'view' },
    { subject: 'workspace.parametres.editionsPdf.ppcst', action: 'view' },
    { subject: 'workspace.projets', action: 'update' },
    { subject: 'workspace.projets', action: 'view' },
    { subject: 'workspace.projets.avps', action: 'create' },
    { subject: 'workspace.projets.avps', action: 'demande-nd' },
    { subject: 'workspace.projets.avps', action: 'update' },
    { subject: 'workspace.projets.avps', action: 'view' },
    { subject: 'workspace.projets.avps.documents.avp', action: 'create' },
    { subject: 'workspace.projets.avps.documents.davp', action: 'create' },
    { subject: 'workspace.projets.avps.documents.dnd', action: 'create' },
    { subject: 'workspace.projets.avps.documents.vt', action: 'create' },
    { subject: 'workspace.projets.avps.metres', action: 'create' },
    { subject: 'workspace.projets.avps.metres', action: 'delete' },
    { subject: 'workspace.projets.avps.metres', action: 'unlock' },
    { subject: 'workspace.projets.avps.metres', action: 'update' },
    { subject: 'workspace.projets.avps.metres', action: 'validate' },
    { subject: 'workspace.projets.avps.metres', action: 'view' },
    { subject: 'workspace.projets.avps.metres.debours', action: 'view' },
    { subject: 'workspace.projets.avps.metres.lignes', action: 'delete' },
    { subject: 'workspace.projets.avps.metres.lignes', action: 'edit' },
    { subject: 'workspace.projets.avps.metres.lignes', action: 'export' },
    { subject: 'workspace.projets.avps.metres.lignes', action: 'view' },
    { subject: 'workspace.projets.avps.metres.lignes.debours', action: 'view' },
    { subject: 'workspace.projets.avps.metres.lignes.description', action: 'edit' },
    { subject: 'workspace.projets.avps.metres.lignes.prixunitaire', action: 'view' },
    { subject: 'workspace.projets.avps.metres.marge', action: 'view' },
    { subject: 'workspace.projets.avps.metres.marge', action: 'update' },
    { subject: 'workspace.projets.avps.metres.options', action: 'create' },
    { subject: 'workspace.projets.avps.metres.options', action: 'update' },
    { subject: 'workspace.projets.avps.metres.options', action: 'view' },
    { subject: 'workspace.projets.avps.metres.options.debours', action: 'view' },
    { subject: 'workspace.projets.avps.metres.options.marge', action: 'view' },
    { subject: 'workspace.projets.avps.metres.parametres', action: 'update' },
    { subject: 'workspace.projets.avps.metres.parametres', action: 'view' },
    { subject: 'workspace.projets.avps.notices', action: 'edit' },
    { subject: 'workspace.projets.avps.notices', action: 'present' },
    { subject: 'workspace.projets.avps.notices.draft', action: 'view' },
    { subject: 'workspace.projets.contrat', action: 'view' },
    { subject: 'workspace.projets.contrat', action: 'view_prices' },
    { subject: 'workspace.projets.contrat.analytics', action: 'view' },
    { subject: 'workspace.projets.contrat.avenants', action: 'create' },
    { subject: 'workspace.projets.contrat.avenants', action: 'update' },
    { subject: 'workspace.projets.contrat.avenants', action: 'validate' },
    { subject: 'workspace.projets.contrat.avenants', action: 'view' },
    { subject: 'workspace.projets.contrat.facturation', action: 'view' },
    { subject: 'workspace.projets.delais', action: 'view' },
    { subject: 'workspace.projets.pp', action: 'view' },
    { subject: 'workspace.projets.pp.ci', action: 'update' },
    { subject: 'workspace.projets.pp.ci', action: 'view' },
    { subject: 'workspace.projets.pp.epe', action: 'update' },
    { subject: 'workspace.projets.pp.epe', action: 'view' },
    { subject: 'workspace.projets.pp.epe.documents', action: 'update' },
    { subject: 'workspace.projets.pp.financement', action: 'update' },
    { subject: 'workspace.projets.pp.financement', action: 'view' },
    { subject: 'workspace.projets.pp.pc', action: 'update' },
    { subject: 'workspace.projets.pp.pc', action: 'view' },
    { subject: 'workspace.projets.pp.prepaschantiers', action: 'update' },
    { subject: 'workspace.projets.pp.prepaschantiers', action: 'view' },
    { subject: 'workspace.projets.pp.terrain', action: 'update' },
    { subject: 'workspace.projets.pp.terrain', action: 'view' },
    { subject: 'workspace.projets.sav', action: 'view' },
    { subject: 'workspace.projets.travaux', action: 'view' },
    { subject: 'workspace.projets.travaux.avenants', action: 'view' },
    { subject: 'workspace.projets.travaux.cmds', action: 'assign_cmd' },
    { subject: 'workspace.projets.travaux.cmds', action: 'assign_mt' },
    { subject: 'workspace.projets.travaux.cmds', action: 'create' },
    { subject: 'workspace.projets.travaux.cmds', action: 'update' },
    { subject: 'workspace.projets.travaux.cmds', action: 'view' },
    { subject: 'workspace.projets.travaux.cmds', action: 'view_margins' },
    { subject: 'workspace.projets.travaux.cmds', action: 'view_prices' },
    { subject: 'workspace.projets.travaux.cmds.cmd', action: 'update' },
    { subject: 'workspace.projets.travaux.cmds.cmd', action: 'view_prices' },
    { subject: 'workspace.projets.travaux.cmds.cmd.datesLiv', action: 'update' },
    { subject: 'workspace.projets.travaux.cmds.cmd.datesLiv', action: 'view_prices' },
    { subject: 'workspace.projets.travaux.cmds.cmd.factures', action: 'view' },
    { subject: 'workspace.projets.travaux.cmds.pdf', action: 'view' },
    { subject: 'workspace.projets.travaux.cmds.pdf', action: 'view_prices' },
    { subject: 'workspace.projets.travaux.documents', action: 'create' },
    { subject: 'workspace.projets.travaux.documents', action: 'view' },
    { subject: 'workspace.projets.travaux.planification', action: 'view' },
    { subject: 'workspace.travaux', action: 'view' },
    { subject: 'workspace.travaux.mt', action: 'validate' },
    { subject: 'workspace.travaux.mt', action: 'view' },
    { subject: 'workspace.widgets.commandesSurfacturees', action: 'view' },
    { subject: 'workspace.widgets.contratSignes', action: 'view' },
    { subject: 'workspace.widgets.demandeAvp', action: 'view' },
    { subject: 'workspace.widgets.pipeCommerciale', action: 'view' },
  ],
  cs_metreur_ext: [
    { subject: 'workspace.catalogues', action: 'view' },
    { subject: 'workspace.catalogues.constructions', action: 'view' },
    { subject: 'workspace.catalogues.constructions.modeles', action: 'view' },
    { subject: 'workspace.contacts', action: 'view' },
    { subject: 'workspace.contacts.clients', action: 'view' },
    { subject: 'workspace.dashboard', action: 'view' },
    { subject: 'workspace.documents', action: 'view' },
    { subject: 'workspace.projets', action: 'view' },
    { subject: 'workspace.projets.avps', action: 'update' },
    { subject: 'workspace.projets.avps', action: 'view' },
    { subject: 'workspace.projets.avps.documents.avp', action: 'create' },
    { subject: 'workspace.projets.avps.metres', action: 'create' },
    { subject: 'workspace.projets.avps.metres', action: 'delete' },
    { subject: 'workspace.projets.avps.metres', action: 'unlock' },
    { subject: 'workspace.projets.avps.metres', action: 'update' },
    { subject: 'workspace.projets.avps.metres', action: 'view' },
    { subject: 'workspace.projets.avps.metres.lignes', action: 'edit' },
    { subject: 'workspace.projets.avps.metres.lignes', action: 'view' },
    { subject: 'workspace.projets.avps.metres.options', action: 'create' },
    { subject: 'workspace.projets.avps.metres.options', action: 'update' },
    { subject: 'workspace.projets.avps.metres.options', action: 'view' },
    { subject: 'workspace.projets.avps.metres.parametres', action: 'update' },
    { subject: 'workspace.projets.avps.metres.parametres', action: 'view' },
    { subject: 'workspace.projets.avps.notices', action: 'edit' },
    { subject: 'workspace.projets.avps.notices.draft', action: 'view' },
    { subject: 'workspace.projets.contrat', action: 'view' },
    { subject: 'workspace.projets.contrat.avenants', action: 'create' },
    { subject: 'workspace.projets.contrat.avenants', action: 'update' },
    { subject: 'workspace.projets.contrat.avenants', action: 'view' },
    { subject: 'workspace.projets.pp', action: 'view' },
    { subject: 'workspace.projets.pp.ci', action: 'update' },
    { subject: 'workspace.projets.pp.ci', action: 'view' },
    { subject: 'workspace.projets.pp.epe', action: 'update' },
    { subject: 'workspace.projets.pp.epe', action: 'view' },
    { subject: 'workspace.projets.pp.epe.documents', action: 'update' },
    { subject: 'workspace.projets.pp.pc', action: 'update' },
    { subject: 'workspace.projets.pp.pc', action: 'view' },
    { subject: 'workspace.widgets.avpEnCours', action: 'view' },
    { subject: 'workspace.widgets.contratSignes', action: 'view' },
    { subject: 'workspace.widgets.pipeCommerciale', action: 'view' },
  ],
  cs_homeplanner: [
    { subject: 'workspace.catalogues', action: 'view' },
    { subject: 'workspace.catalogues.annonces', action: 'update' },
    { subject: 'workspace.catalogues.annonces', action: 'view' },
    { subject: 'workspace.catalogues.constructions', action: 'view' },
    { subject: 'workspace.catalogues.constructions.gammes', action: 'delete' },
    { subject: 'workspace.catalogues.constructions.gammes', action: 'update' },
    { subject: 'workspace.catalogues.constructions.gammes', action: 'view' },
    { subject: 'workspace.catalogues.constructions.modeles', action: 'delete' },
    { subject: 'workspace.catalogues.constructions.modeles', action: 'update' },
    { subject: 'workspace.catalogues.constructions.modeles', action: 'view' },
    { subject: 'workspace.catalogues.metres', action: 'view' },
    { subject: 'workspace.catalogues.metres', action: 'update' },
    { subject: 'workspace.catalogues.metres.debours', action: 'view' },
    { subject: 'workspace.catalogues.metres.lignes', action: 'update' },
    { subject: 'workspace.catalogues.metres.lignes.debours', action: 'view' },
    { subject: 'workspace.catalogues.metres.lignes.prixunitaire', action: 'view' },
    { subject: 'workspace.catalogues.metres.marge', action: 'view' },
    { subject: 'workspace.catalogues.metres.options', action: 'create' },
    { subject: 'workspace.catalogues.metres.options.debours', action: 'view' },
    { subject: 'workspace.catalogues.metres.options.marge', action: 'view' },
    { subject: 'workspace.catalogues.terrains', action: 'delete' },
    { subject: 'workspace.catalogues.terrains', action: 'update' },
    { subject: 'workspace.catalogues.terrains', action: 'view' },
    { subject: 'workspace.contacts', action: 'view' },
    { subject: 'workspace.contacts.clients', action: 'create' },
    { subject: 'workspace.contacts.clients', action: 'update' },
    { subject: 'workspace.contacts.clients', action: 'view' },
    { subject: 'workspace.dashboard', action: 'view' },
    { subject: 'workspace.documents', action: 'view' },
    { subject: 'workspace.projets', action: 'cancel' },
    { subject: 'workspace.projets', action: 'create' },
    { subject: 'workspace.projets', action: 'update' },
    { subject: 'workspace.projets', action: 'view' },
    { subject: 'workspace.projets.avps', action: 'create' },
    { subject: 'workspace.projets.avps', action: 'demande-nd' },
    { subject: 'workspace.projets.avps', action: 'update' },
    { subject: 'workspace.projets.avps', action: 'view' },
    { subject: 'workspace.projets.avps.documents.avp', action: 'create' },
    { subject: 'workspace.projets.avps.documents.davp', action: 'create' },
    { subject: 'workspace.projets.avps.documents.dnd', action: 'create' },
    { subject: 'workspace.projets.avps.metres', action: 'create' },
    { subject: 'workspace.projets.avps.metres', action: 'delete' },
    { subject: 'workspace.projets.avps.metres', action: 'unlock' },
    { subject: 'workspace.projets.avps.metres', action: 'update' },
    { subject: 'workspace.projets.avps.metres', action: 'view' },
    { subject: 'workspace.projets.avps.metres.debours', action: 'view' },
    { subject: 'workspace.projets.avps.metres.lignes', action: 'edit' },
    { subject: 'workspace.projets.avps.metres.lignes', action: 'view' },
    { subject: 'workspace.projets.avps.metres.lignes.debours', action: 'view' },
    { subject: 'workspace.projets.avps.metres.lignes.prixunitaire', action: 'view' },
    { subject: 'workspace.projets.avps.metres.marge', action: 'view' },
    { subject: 'workspace.projets.avps.metres.options', action: 'create' },
    { subject: 'workspace.projets.avps.metres.options', action: 'update' },
    { subject: 'workspace.projets.avps.metres.options', action: 'view' },
    { subject: 'workspace.projets.avps.metres.options.debours', action: 'view' },
    { subject: 'workspace.projets.avps.metres.options.marge', action: 'view' },
    { subject: 'workspace.projets.avps.metres.parametres', action: 'update' },
    { subject: 'workspace.projets.avps.metres.parametres', action: 'view' },
    { subject: 'workspace.projets.avps.notices', action: 'edit' },
    { subject: 'workspace.projets.avps.notices', action: 'present' },
    { subject: 'workspace.projets.avps.notices.draft', action: 'view' },
    { subject: 'workspace.projets.contrat', action: 'update' },
    { subject: 'workspace.projets.contrat', action: 'view' },
    { subject: 'workspace.projets.contrat', action: 'view_prices' },
    { subject: 'workspace.projets.contrat.avenants', action: 'create' },
    { subject: 'workspace.projets.contrat.avenants', action: 'update' },
    { subject: 'workspace.projets.contrat.avenants', action: 'view' },
    { subject: 'workspace.projets.contrat.signature', action: 'update' },
    { subject: 'workspace.projets.delais', action: 'view' },
    { subject: 'workspace.projets.pp', action: 'view' },
    { subject: 'workspace.projets.pp.ci', action: 'update' },
    { subject: 'workspace.projets.pp.ci', action: 'view' },
    { subject: 'workspace.projets.pp.epe', action: 'update' },
    { subject: 'workspace.projets.pp.epe', action: 'view' },
    { subject: 'workspace.projets.pp.epe.documents', action: 'update' },
    { subject: 'workspace.projets.pp.financement', action: 'update' },
    { subject: 'workspace.projets.pp.financement', action: 'view' },
    { subject: 'workspace.projets.pp.pc', action: 'update' },
    { subject: 'workspace.projets.pp.pc', action: 'view' },
    { subject: 'workspace.projets.pp.prepaschantiers', action: 'update' },
    { subject: 'workspace.projets.pp.prepaschantiers', action: 'view' },
    { subject: 'workspace.projets.pp.terrain', action: 'update' },
    { subject: 'workspace.projets.pp.terrain', action: 'view' },
    { subject: 'workspace.projets.travaux', action: 'view' },
    { subject: 'workspace.projets.travaux.planification', action: 'view' },
    { subject: 'workspace.travaux', action: 'view' },
    { subject: 'workspace.travaux.mt', action: 'view' },
    { subject: 'workspace.widgets.contratSignes', action: 'view' },
    { subject: 'workspace.widgets.pipeCommerciale', action: 'view' },
  ],
  cs_metreur: [
    { subject: 'workspace.catalogues', action: 'view' },
    { subject: 'workspace.catalogues.annonces', action: 'view' },
    { subject: 'workspace.catalogues.articles', action: 'view' },
    { subject: 'workspace.catalogues.articles.gammes', action: 'view' },
    { subject: 'workspace.catalogues.articles.lots', action: 'view' },
    { subject: 'workspace.catalogues.articles.prix', action: 'view' },
    { subject: 'workspace.catalogues.articles.types_articles', action: 'view' },
    { subject: 'workspace.catalogues.constructions', action: 'view' },
    { subject: 'workspace.catalogues.constructions.gammes', action: 'delete' },
    { subject: 'workspace.catalogues.constructions.gammes', action: 'update' },
    { subject: 'workspace.catalogues.constructions.gammes', action: 'view' },
    { subject: 'workspace.catalogues.constructions.modeles', action: 'update' },
    { subject: 'workspace.catalogues.constructions.modeles', action: 'view' },
    { subject: 'workspace.catalogues.metres', action: 'view' },
    { subject: 'workspace.catalogues.metres', action: 'update' },
    { subject: 'workspace.catalogues.metres.debours', action: 'view' },
    { subject: 'workspace.catalogues.metres.lignes', action: 'update' },
    { subject: 'workspace.catalogues.metres.lignes.debours', action: 'view' },
    { subject: 'workspace.catalogues.metres.lignes.prixunitaire', action: 'view' },
    { subject: 'workspace.catalogues.metres.marge', action: 'view' },
    { subject: 'workspace.catalogues.metres.options', action: 'create' },
    { subject: 'workspace.catalogues.metres.options.debours', action: 'view' },
    { subject: 'workspace.catalogues.metres.options.marge', action: 'view' },
    { subject: 'workspace.catalogues.terrains', action: 'view' },
    { subject: 'workspace.contacts', action: 'view' },
    { subject: 'workspace.contacts.clients', action: 'view' },
    { subject: 'workspace.dashboard', action: 'view' },
    { subject: 'workspace.documents', action: 'view' },
    { subject: 'workspace.projets', action: 'view' },
    { subject: 'workspace.projets.avps', action: 'update' },
    { subject: 'workspace.projets.avps', action: 'view' },
    { subject: 'workspace.projets.avps.documents.avp', action: 'create' },
    { subject: 'workspace.projets.avps.metres', action: 'create' },
    { subject: 'workspace.projets.avps.metres', action: 'delete' },
    { subject: 'workspace.projets.avps.metres', action: 'unlock' },
    { subject: 'workspace.projets.avps.metres', action: 'update' },
    { subject: 'workspace.projets.avps.metres', action: 'view' },
    { subject: 'workspace.projets.avps.metres.debours', action: 'view' },
    { subject: 'workspace.projets.avps.metres.lignes', action: 'edit' },
    { subject: 'workspace.projets.avps.metres.lignes', action: 'view' },
    { subject: 'workspace.projets.avps.metres.lignes.debours', action: 'view' },
    { subject: 'workspace.projets.avps.metres.lignes.prixunitaire', action: 'view' },
    { subject: 'workspace.projets.avps.metres.marge', action: 'view' },
    { subject: 'workspace.projets.avps.metres.options', action: 'create' },
    { subject: 'workspace.projets.avps.metres.options', action: 'update' },
    { subject: 'workspace.projets.avps.metres.options', action: 'view' },
    { subject: 'workspace.projets.avps.metres.options.debours', action: 'view' },
    { subject: 'workspace.projets.avps.metres.options.marge', action: 'view' },
    { subject: 'workspace.projets.avps.metres.parametres', action: 'update' },
    { subject: 'workspace.projets.avps.metres.parametres', action: 'view' },
    { subject: 'workspace.projets.avps.notices', action: 'edit' },
    { subject: 'workspace.projets.avps.notices.draft', action: 'view' },
    { subject: 'workspace.projets.contrat', action: 'view' },
    { subject: 'workspace.projets.contrat', action: 'view_prices' },
    { subject: 'workspace.projets.contrat.avenants', action: 'create' },
    { subject: 'workspace.projets.contrat.avenants', action: 'update' },
    { subject: 'workspace.projets.contrat.avenants', action: 'view' },
    { subject: 'workspace.projets.delais', action: 'view' },
    { subject: 'workspace.projets.pp', action: 'view' },
    { subject: 'workspace.projets.pp.ci', action: 'update' },
    { subject: 'workspace.projets.pp.ci', action: 'view' },
    { subject: 'workspace.projets.pp.epe', action: 'update' },
    { subject: 'workspace.projets.pp.epe', action: 'view' },
    { subject: 'workspace.projets.pp.epe.documents', action: 'update' },
    { subject: 'workspace.projets.pp.financement', action: 'view' },
    { subject: 'workspace.projets.pp.pc', action: 'update' },
    { subject: 'workspace.projets.pp.pc', action: 'view' },
    { subject: 'workspace.projets.pp.prepaschantiers', action: 'view' },
    { subject: 'workspace.projets.pp.terrain', action: 'update' },
    { subject: 'workspace.projets.pp.terrain', action: 'view' },
    { subject: 'workspace.projets.travaux', action: 'view' },
    { subject: 'workspace.projets.travaux.planification', action: 'view' },
    { subject: 'workspace.travaux', action: 'view' },
    { subject: 'workspace.travaux.mt', action: 'view' },
    { subject: 'workspace.widgets.avpEnCours', action: 'view' },
    { subject: 'workspace.widgets.contratSignes', action: 'view' },
    { subject: 'workspace.widgets.pipeCommerciale', action: 'view' },
  ],
  cs_dessinateur: [
    { subject: 'workspace.catalogues', action: 'view' },
    { subject: 'workspace.catalogues.constructions', action: 'view' },
    { subject: 'workspace.catalogues.constructions.modeles', action: 'update' },
    { subject: 'workspace.catalogues.constructions.modeles', action: 'view' },
    { subject: 'workspace.contacts', action: 'view' },
    { subject: 'workspace.contacts.clients', action: 'view' },
    { subject: 'workspace.dashboard', action: 'view' },
    { subject: 'workspace.documents', action: 'view' },
    { subject: 'workspace.projets', action: 'update' },
    { subject: 'workspace.projets', action: 'view' },
    { subject: 'workspace.projets.avps', action: 'view' },
    { subject: 'workspace.projets.avps.documents.avp', action: 'create' },
    { subject: 'workspace.projets.pp', action: 'view' },
    { subject: 'workspace.projets.pp.ci', action: 'update' },
    { subject: 'workspace.projets.pp.ci', action: 'view' },
    { subject: 'workspace.projets.pp.epe', action: 'update' },
    { subject: 'workspace.projets.pp.epe', action: 'view' },
    { subject: 'workspace.projets.pp.epe.documents', action: 'update' },
    { subject: 'workspace.projets.pp.pc', action: 'update' },
    { subject: 'workspace.projets.pp.pc', action: 'view' },
    { subject: 'workspace.projets.pp.prepaschantiers', action: 'update' },
    { subject: 'workspace.projets.pp.prepaschantiers', action: 'view' },
    { subject: 'workspace.projets.pp.terrain', action: 'update' },
    { subject: 'workspace.projets.pp.terrain', action: 'view' },
    { subject: 'workspace.projets.travaux', action: 'view' },
    { subject: 'workspace.projets.travaux.planification', action: 'update' },
    { subject: 'workspace.projets.travaux.planification', action: 'view' },
    { subject: 'workspace.projets.travaux.planification.reception', action: 'view' },
    { subject: 'workspace.widgets.avpEnCours', action: 'view' },
    { subject: 'workspace.widgets.contratSignes', action: 'view' },
    { subject: 'workspace.widgets.pipeCommerciale', action: 'view' },
  ],
  rm_commerce: [
    { subject: 'workspace.catalogues', action: 'view' },
    { subject: 'workspace.catalogues.annonces', action: 'update' },
    { subject: 'workspace.catalogues.annonces', action: 'view' },
    { subject: 'workspace.catalogues.constructions', action: 'view' },
    { subject: 'workspace.catalogues.constructions.gammes', action: 'view' },
    { subject: 'workspace.catalogues.constructions.modeles', action: 'update' },
    { subject: 'workspace.catalogues.constructions.modeles', action: 'view' },
    { subject: 'workspace.catalogues.terrains', action: 'delete' },
    { subject: 'workspace.catalogues.terrains', action: 'update' },
    { subject: 'workspace.catalogues.terrains', action: 'view' },
    { subject: 'workspace.contacts', action: 'view' },
    { subject: 'workspace.contacts.clients', action: 'create' },
    { subject: 'workspace.contacts.clients', action: 'update' },
    { subject: 'workspace.contacts.clients', action: 'view' },
    { subject: 'workspace.dashboard', action: 'view' },
    { subject: 'workspace.documents', action: 'view' },
    { subject: 'workspace.projets', action: 'cancel' },
    { subject: 'workspace.projets', action: 'create' },
    { subject: 'workspace.projets', action: 'update' },
    { subject: 'workspace.projets', action: 'view' },
    { subject: 'workspace.projets.avps', action: 'create' },
    { subject: 'workspace.projets.avps', action: 'demande-nd' },
    { subject: 'workspace.projets.avps', action: 'update' },
    { subject: 'workspace.projets.avps', action: 'view' },
    { subject: 'workspace.projets.avps.documents.davp', action: 'create' },
    { subject: 'workspace.projets.avps.documents.dnd', action: 'create' },
    { subject: 'workspace.projets.avps.notices', action: 'edit' },
    { subject: 'workspace.projets.avps.notices', action: 'present' },
    { subject: 'workspace.projets.contrat', action: 'update' },
    { subject: 'workspace.projets.contrat', action: 'view' },
    { subject: 'workspace.projets.contrat', action: 'view_prices' },
    { subject: 'workspace.projets.contrat.avenants', action: 'view' },
    { subject: 'workspace.projets.contrat.signature', action: 'update' },
    { subject: 'workspace.projets.delais', action: 'view' },
    { subject: 'workspace.projets.indicateursVentes', action: 'update' },
    { subject: 'workspace.projets.pp', action: 'view' },
    { subject: 'workspace.projets.pp.ci', action: 'view' },
    { subject: 'workspace.projets.pp.epe', action: 'view' },
    { subject: 'workspace.projets.pp.epe.documents', action: 'update' },
    { subject: 'workspace.projets.pp.financement', action: 'update' },
    { subject: 'workspace.projets.pp.financement', action: 'view' },
    { subject: 'workspace.projets.pp.pc', action: 'update' },
    { subject: 'workspace.projets.pp.pc', action: 'view' },
    { subject: 'workspace.projets.pp.prepaschantiers', action: 'view' },
    { subject: 'workspace.projets.pp.terrain', action: 'update' },
    { subject: 'workspace.projets.pp.terrain', action: 'view' },
    { subject: 'workspace.projets.travaux', action: 'view' },
    { subject: 'workspace.projets.travaux.planification', action: 'view' },
    { subject: 'workspace.widgets.contratSignes', action: 'view' },
    { subject: 'workspace.widgets.demandeAvp', action: 'view' },
    { subject: 'workspace.widgets.pipeCommerciale', action: 'view' },
  ],
  cs_respcom: [
    { subject: 'workspace.catalogues', action: 'view' },
    { subject: 'workspace.catalogues.annonces', action: 'update' },
    { subject: 'workspace.catalogues.annonces', action: 'view' },
    { subject: 'workspace.catalogues.constructions', action: 'view' },
    { subject: 'workspace.catalogues.constructions.gammes', action: 'delete' },
    { subject: 'workspace.catalogues.constructions.gammes', action: 'update' },
    { subject: 'workspace.catalogues.constructions.gammes', action: 'view' },
    { subject: 'workspace.catalogues.constructions.modeles', action: 'delete' },
    { subject: 'workspace.catalogues.constructions.modeles', action: 'update' },
    { subject: 'workspace.catalogues.constructions.modeles', action: 'view' },
    { subject: 'workspace.catalogues.terrains', action: 'delete' },
    { subject: 'workspace.catalogues.terrains', action: 'update' },
    { subject: 'workspace.catalogues.terrains', action: 'view' },
    { subject: 'workspace.contacts', action: 'view' },
    { subject: 'workspace.contacts.clients', action: 'create' },
    { subject: 'workspace.contacts.clients', action: 'update' },
    { subject: 'workspace.contacts.clients', action: 'view' },
    { subject: 'workspace.dashboard', action: 'view' },
    { subject: 'workspace.documents', action: 'view' },
    { subject: 'workspace.parametres', action: 'view' },
    { subject: 'workspace.parametres.diffusion', action: 'view' },
    { subject: 'workspace.parametres.diffusion.annonces', action: 'view' },
    { subject: 'workspace.parametres.diffusion.ubiflow', action: 'view' },
    { subject: 'workspace.parametres.secteurs', action: 'update' },
    { subject: 'workspace.parametres.secteurs', action: 'view' },
    { subject: 'workspace.projets', action: 'cancel' },
    { subject: 'workspace.projets', action: 'create' },
    { subject: 'workspace.projets', action: 'update' },
    { subject: 'workspace.projets', action: 'view' },
    { subject: 'workspace.projets.avps', action: 'create' },
    { subject: 'workspace.projets.avps', action: 'demande-nd' },
    { subject: 'workspace.projets.avps', action: 'update' },
    { subject: 'workspace.projets.avps', action: 'view' },
    { subject: 'workspace.projets.avps.documents.davp', action: 'create' },
    { subject: 'workspace.projets.avps.documents.dnd', action: 'create' },
    { subject: 'workspace.projets.avps.notices', action: 'edit' },
    { subject: 'workspace.projets.avps.notices', action: 'present' },
    { subject: 'workspace.projets.avps.notices.draft', action: 'view' },
    { subject: 'workspace.projets.contrat', action: 'update' },
    { subject: 'workspace.projets.contrat', action: 'view' },
    { subject: 'workspace.projets.contrat', action: 'view_prices' },
    { subject: 'workspace.projets.contrat.avenants', action: 'create' },
    { subject: 'workspace.projets.contrat.avenants', action: 'update' },
    { subject: 'workspace.projets.contrat.avenants', action: 'view' },
    { subject: 'workspace.projets.contrat.facturation', action: 'update' },
    { subject: 'workspace.projets.contrat.facturation', action: 'view' },
    { subject: 'workspace.projets.contrat.signature', action: 'update' },
    { subject: 'workspace.projets.delais', action: 'view' },
    { subject: 'workspace.projets.indicateursVentes', action: 'update' },
    { subject: 'workspace.projets.pp', action: 'view' },
    { subject: 'workspace.projets.pp.ci', action: 'view' },
    { subject: 'workspace.projets.pp.epe', action: 'view' },
    { subject: 'workspace.projets.pp.epe.documents', action: 'update' },
    { subject: 'workspace.projets.pp.financement', action: 'update' },
    { subject: 'workspace.projets.pp.financement', action: 'view' },
    { subject: 'workspace.projets.pp.pc', action: 'update' },
    { subject: 'workspace.projets.pp.pc', action: 'view' },
    { subject: 'workspace.projets.pp.prepaschantiers', action: 'view' },
    { subject: 'workspace.projets.pp.terrain', action: 'update' },
    { subject: 'workspace.projets.pp.terrain', action: 'view' },
    { subject: 'workspace.projets.travaux', action: 'view' },
    { subject: 'workspace.projets.travaux.planification', action: 'view' },
    { subject: 'workspace.widgets.contratSignes', action: 'view' },
    { subject: 'workspace.widgets.demandeAvp', action: 'view' },
    { subject: 'workspace.widgets.pipeCommerciale', action: 'view' },
  ],
  cs_conduc: [
    { subject: 'workspace.catalogues', action: 'view' },
    { subject: 'workspace.catalogues.constructions', action: 'view' },
    { subject: 'workspace.catalogues.constructions.gammes', action: 'view' },
    { subject: 'workspace.catalogues.constructions.modeles', action: 'view' },
    { subject: 'workspace.contacts', action: 'view' },
    { subject: 'workspace.contacts.clients', action: 'view' },
    { subject: 'workspace.contacts.ssttfours', action: 'view' },
    { subject: 'workspace.dashboard', action: 'view' },
    { subject: 'workspace.documents', action: 'view' },
    { subject: 'workspace.projets', action: 'view' },
    { subject: 'workspace.projets.avps', action: 'view' },
    { subject: 'workspace.projets.avps.documents.vt', action: 'create' },
    { subject: 'workspace.projets.contrat', action: 'view' },
    { subject: 'workspace.projets.contrat', action: 'view_prices' },
    { subject: 'workspace.projets.contrat.avenants', action: 'view' },
    { subject: 'workspace.projets.contrat.facturation', action: 'download' },
    { subject: 'workspace.projets.contrat.facturation', action: 'generate' },
    { subject: 'workspace.projets.contrat.facturation', action: 'view' },
    { subject: 'workspace.projets.delais', action: 'view' },
    { subject: 'workspace.projets.pp', action: 'view' },
    { subject: 'workspace.projets.pp.ci', action: 'view' },
    { subject: 'workspace.projets.pp.epe', action: 'view' },
    { subject: 'workspace.projets.pp.pc', action: 'view' },
    { subject: 'workspace.projets.pp.prepaschantiers', action: 'update' },
    { subject: 'workspace.projets.pp.prepaschantiers', action: 'view' },
    { subject: 'workspace.projets.pp.terrain', action: 'view' },
    { subject: 'workspace.projets.sav', action: 'view' },
    { subject: 'workspace.projets.travaux', action: 'view' },
    { subject: 'workspace.projets.travaux.avenants', action: 'view' },
    { subject: 'workspace.projets.travaux.cmds', action: 'assign_mt' },
    { subject: 'workspace.projets.travaux.cmds', action: 'view' },
    { subject: 'workspace.projets.travaux.cmds', action: 'view_margins' },
    { subject: 'workspace.projets.travaux.cmds', action: 'view_prices' },
    { subject: 'workspace.projets.travaux.cmds.cmd', action: 'view_prices' },
    { subject: 'workspace.projets.travaux.cmds.cmd.datesLiv', action: 'update' },
    { subject: 'workspace.projets.travaux.cmds.cmd.datesLiv', action: 'view_prices' },
    { subject: 'workspace.projets.travaux.cmds.pdf', action: 'view' },
    { subject: 'workspace.projets.travaux.cmds.pdf', action: 'view_prices' },
    { subject: 'workspace.projets.travaux.documents', action: 'create' },
    { subject: 'workspace.projets.travaux.documents', action: 'view' },
    { subject: 'workspace.projets.travaux.planification', action: 'view' },
    { subject: 'workspace.projets.travaux.planification.reception', action: 'view' },
    { subject: 'workspace.travaux', action: 'view' },
    { subject: 'workspace.widgets.contratSignes', action: 'view' },
    { subject: 'workspace.widgets.pipeCommerciale', action: 'view' },
  ],
  cs_conduc_ext: [
    { subject: 'workspace.contacts', action: 'view' },
    { subject: 'workspace.contacts.clients', action: 'view' },
    { subject: 'workspace.contacts.ssttfours', action: 'view' },
    { subject: 'workspace.dashboard', action: 'view' },
    { subject: 'workspace.documents', action: 'view' },
    { subject: 'workspace.projets', action: 'view' },
    { subject: 'workspace.projets.avps', action: 'view' },
    { subject: 'workspace.projets.avps.documents.vt', action: 'create' },
    { subject: 'workspace.projets.contrat', action: 'view' },
    { subject: 'workspace.projets.contrat.avenants', action: 'view' },
    { subject: 'workspace.projets.contrat.facturation', action: 'download' },
    { subject: 'workspace.projets.contrat.facturation', action: 'generate' },
    { subject: 'workspace.projets.contrat.facturation', action: 'view' },
    { subject: 'workspace.projets.delais', action: 'view' },
    { subject: 'workspace.projets.pp', action: 'view' },
    { subject: 'workspace.projets.pp.ci', action: 'view' },
    { subject: 'workspace.projets.pp.epe', action: 'view' },
    { subject: 'workspace.projets.pp.pc', action: 'view' },
    { subject: 'workspace.projets.pp.prepaschantiers', action: 'update' },
    { subject: 'workspace.projets.pp.prepaschantiers', action: 'view' },
    { subject: 'workspace.projets.pp.terrain', action: 'view' },
    { subject: 'workspace.projets.sav', action: 'view' },
    { subject: 'workspace.projets.travaux', action: 'view' },
    { subject: 'workspace.projets.travaux.avenants', action: 'view' },
    { subject: 'workspace.projets.travaux.cmds', action: 'assign_mt' },
    { subject: 'workspace.projets.travaux.cmds', action: 'view' },
    { subject: 'workspace.projets.travaux.cmds.cmd.datesLiv', action: 'update' },
    { subject: 'workspace.projets.travaux.cmds.pdf', action: 'view' },
    { subject: 'workspace.projets.travaux.documents', action: 'create' },
    { subject: 'workspace.projets.travaux.documents', action: 'view' },
    { subject: 'workspace.projets.travaux.planification', action: 'view' },
    { subject: 'workspace.projets.travaux.planification.reception', action: 'view' },
    { subject: 'workspace.travaux', action: 'view' },
    { subject: 'workspace.widgets.contratSignes', action: 'view' },
  ],
  rm_admin: [
    { subject: 'workspace.dashboard', action: 'view' },
    { subject: 'workspace.widgets.contratSignes', action: 'view' },
    { subject: 'workspace.widgets.pipeCommerciale', action: 'view' },
  ],
  rm_commerce_ext: [
    { subject: 'workspace.catalogues', action: 'view' },
    { subject: 'workspace.catalogues.annonces', action: 'update' },
    { subject: 'workspace.catalogues.annonces', action: 'view' },
    { subject: 'workspace.catalogues.constructions', action: 'view' },
    { subject: 'workspace.catalogues.constructions.gammes', action: 'view' },
    { subject: 'workspace.catalogues.constructions.modeles', action: 'view' },
    { subject: 'workspace.catalogues.terrains', action: 'delete' },
    { subject: 'workspace.catalogues.terrains', action: 'update' },
    { subject: 'workspace.catalogues.terrains', action: 'view' },
    { subject: 'workspace.contacts', action: 'view' },
    { subject: 'workspace.contacts.clients', action: 'create' },
    { subject: 'workspace.contacts.clients', action: 'update' },
    { subject: 'workspace.contacts.clients', action: 'view' },
    { subject: 'workspace.dashboard', action: 'view' },
    { subject: 'workspace.documents', action: 'view' },
    { subject: 'workspace.projets', action: 'cancel' },
    { subject: 'workspace.projets', action: 'create' },
    { subject: 'workspace.projets', action: 'update' },
    { subject: 'workspace.projets', action: 'view' },
    { subject: 'workspace.projets.avps', action: 'create' },
    { subject: 'workspace.projets.avps', action: 'demande-nd' },
    { subject: 'workspace.projets.avps', action: 'update' },
    { subject: 'workspace.projets.avps', action: 'view' },
    { subject: 'workspace.projets.avps.documents.davp', action: 'create' },
    { subject: 'workspace.projets.avps.documents.dnd', action: 'create' },
    { subject: 'workspace.projets.avps.notices', action: 'edit' },
    { subject: 'workspace.projets.avps.notices', action: 'present' },
    { subject: 'workspace.projets.contrat', action: 'update' },
    { subject: 'workspace.projets.contrat', action: 'view' },
    { subject: 'workspace.projets.contrat.avenants', action: 'view' },
    { subject: 'workspace.projets.contrat.signature', action: 'update' },
    { subject: 'workspace.projets.delais', action: 'view' },
    { subject: 'workspace.projets.indicateursVentes', action: 'update' },
    { subject: 'workspace.projets.pp', action: 'view' },
    { subject: 'workspace.projets.pp.ci', action: 'view' },
    { subject: 'workspace.projets.pp.epe', action: 'view' },
    { subject: 'workspace.projets.pp.epe.documents', action: 'update' },
    { subject: 'workspace.projets.pp.financement', action: 'update' },
    { subject: 'workspace.projets.pp.financement', action: 'view' },
    { subject: 'workspace.projets.pp.pc', action: 'update' },
    { subject: 'workspace.projets.pp.pc', action: 'view' },
    { subject: 'workspace.projets.pp.prepaschantiers', action: 'view' },
    { subject: 'workspace.projets.pp.terrain', action: 'update' },
    { subject: 'workspace.projets.pp.terrain', action: 'view' },
    { subject: 'workspace.projets.travaux', action: 'view' },
    { subject: 'workspace.projets.travaux.planification', action: 'view' },
    { subject: 'workspace.widgets.contratSignes', action: 'view' },
    { subject: 'workspace.widgets.demandeAvp', action: 'view' },
  ],
  cs_resp_tvx: [
    { subject: 'workspace.catalogues', action: 'view' },
    { subject: 'workspace.catalogues.articles', action: 'view' },
    { subject: 'workspace.catalogues.articles.gammes', action: 'view' },
    { subject: 'workspace.catalogues.articles.lots', action: 'view' },
    { subject: 'workspace.catalogues.articles.prix', action: 'view' },
    { subject: 'workspace.catalogues.articles.types_articles', action: 'view' },
    { subject: 'workspace.catalogues.constructions', action: 'view' },
    { subject: 'workspace.catalogues.constructions.gammes', action: 'view' },
    { subject: 'workspace.catalogues.constructions.modeles', action: 'view' },
    { subject: 'workspace.catalogues.metres', action: 'view' },
    { subject: 'workspace.catalogues.metres.debours', action: 'view' },
    { subject: 'workspace.catalogues.metres.lignes.debours', action: 'view' },
    { subject: 'workspace.catalogues.metres.lignes.prixunitaire', action: 'view' },
    { subject: 'workspace.catalogues.metres.marge', action: 'view' },
    { subject: 'workspace.catalogues.metres.options.debours', action: 'view' },
    { subject: 'workspace.catalogues.metres.options.marge', action: 'view' },
    { subject: 'workspace.catalogues.terrains', action: 'view' },
    { subject: 'workspace.contacts', action: 'view' },
    { subject: 'workspace.contacts.clients', action: 'create' },
    { subject: 'workspace.contacts.clients', action: 'update' },
    { subject: 'workspace.contacts.clients', action: 'view' },
    { subject: 'workspace.contacts.ssttfours', action: 'delete' },
    { subject: 'workspace.contacts.ssttfours', action: 'update' },
    { subject: 'workspace.contacts.ssttfours', action: 'view' },
    { subject: 'workspace.dashboard', action: 'view' },
    { subject: 'workspace.documents', action: 'update' },
    { subject: 'workspace.documents', action: 'view' },
    { subject: 'workspace.gesfi', action: 'view' },
    { subject: 'workspace.gesfi.achats', action: 'view' },
    { subject: 'workspace.gesfi.dashboards', action: 'view' },
    { subject: 'workspace.gesfi.dashboards.facturation', action: 'view' },
    { subject: 'workspace.projets', action: 'view' },
    { subject: 'workspace.projets.avps', action: 'view' },
    { subject: 'workspace.projets.avps.documents.vt', action: 'create' },
    { subject: 'workspace.projets.avps.metres', action: 'view' },
    { subject: 'workspace.projets.avps.metres.debours', action: 'view' },
    { subject: 'workspace.projets.avps.metres.lignes', action: 'view' },
    { subject: 'workspace.projets.avps.metres.lignes.debours', action: 'view' },
    { subject: 'workspace.projets.avps.metres.lignes.prixunitaire', action: 'view' },
    { subject: 'workspace.projets.avps.metres.marge', action: 'view' },
    { subject: 'workspace.projets.avps.metres.options', action: 'view' },
    { subject: 'workspace.projets.avps.metres.parametres', action: 'view' },
    { subject: 'workspace.projets.contrat', action: 'view' },
    { subject: 'workspace.projets.contrat', action: 'view_prices' },
    { subject: 'workspace.projets.contrat.analytics', action: 'view' },
    { subject: 'workspace.projets.contrat.avenants', action: 'create' },
    { subject: 'workspace.projets.contrat.avenants', action: 'update' },
    { subject: 'workspace.projets.contrat.avenants', action: 'update_pdf' },
    { subject: 'workspace.projets.contrat.avenants', action: 'validate' },
    { subject: 'workspace.projets.contrat.avenants', action: 'view' },
    { subject: 'workspace.projets.contrat.facturation', action: 'download' },
    { subject: 'workspace.projets.contrat.facturation', action: 'generate' },
    { subject: 'workspace.projets.contrat.facturation', action: 'update' },
    { subject: 'workspace.projets.contrat.facturation', action: 'view' },
    { subject: 'workspace.projets.delais', action: 'view' },
    { subject: 'workspace.projets.pp', action: 'view' },
    { subject: 'workspace.projets.pp.ci', action: 'view' },
    { subject: 'workspace.projets.pp.epe', action: 'view' },
    { subject: 'workspace.projets.pp.financement', action: 'view' },
    { subject: 'workspace.projets.pp.garanties', action: 'view' },
    { subject: 'workspace.projets.pp.pc', action: 'update' },
    { subject: 'workspace.projets.pp.pc', action: 'view' },
    { subject: 'workspace.projets.pp.prepaschantiers', action: 'update' },
    { subject: 'workspace.projets.pp.prepaschantiers', action: 'view' },
    { subject: 'workspace.projets.pp.terrain', action: 'update' },
    { subject: 'workspace.projets.pp.terrain', action: 'view' },
    { subject: 'workspace.projets.sav', action: 'view' },
    { subject: 'workspace.projets.travaux', action: 'view' },
    { subject: 'workspace.projets.travaux.avenants', action: 'create' },
    { subject: 'workspace.projets.travaux.avenants', action: 'delete' },
    { subject: 'workspace.projets.travaux.avenants', action: 'update' },
    { subject: 'workspace.projets.travaux.avenants', action: 'view' },
    { subject: 'workspace.projets.travaux.cmds', action: 'assign_cmd' },
    { subject: 'workspace.projets.travaux.cmds', action: 'assign_mt' },
    { subject: 'workspace.projets.travaux.cmds', action: 'create' },
    { subject: 'workspace.projets.travaux.cmds', action: 'delete' },
    { subject: 'workspace.projets.travaux.cmds', action: 'update' },
    { subject: 'workspace.projets.travaux.cmds', action: 'view' },
    { subject: 'workspace.projets.travaux.cmds', action: 'view_margins' },
    { subject: 'workspace.projets.travaux.cmds', action: 'view_prices' },
    { subject: 'workspace.projets.travaux.cmds.cmd', action: 'update' },
    { subject: 'workspace.projets.travaux.cmds.cmd', action: 'view_prices' },
    { subject: 'workspace.projets.travaux.cmds.cmd.datesLiv', action: 'update' },
    { subject: 'workspace.projets.travaux.cmds.cmd.datesLiv', action: 'view_prices' },
    { subject: 'workspace.projets.travaux.cmds.pdf', action: 'create' },
    { subject: 'workspace.projets.travaux.cmds.pdf', action: 'delete' },
    { subject: 'workspace.projets.travaux.cmds.pdf', action: 'update' },
    { subject: 'workspace.projets.travaux.cmds.pdf', action: 'view' },
    { subject: 'workspace.projets.travaux.cmds.pdf', action: 'view_prices' },
    { subject: 'workspace.projets.travaux.documents', action: 'create' },
    { subject: 'workspace.projets.travaux.documents', action: 'delete' },
    { subject: 'workspace.projets.travaux.documents', action: 'view' },
    { subject: 'workspace.projets.travaux.planification', action: 'update' },
    { subject: 'workspace.projets.travaux.planification', action: 'view' },
    { subject: 'workspace.projets.travaux.planification.reception', action: 'view' },
    { subject: 'workspace.travaux', action: 'view' },
    { subject: 'workspace.travaux.marches', action: 'update' },
    { subject: 'workspace.travaux.marches', action: 'view' },
    { subject: 'workspace.travaux.mt', action: 'validate' },
    { subject: 'workspace.travaux.mt', action: 'view' },
    { subject: 'workspace.widgets.commandesSurfacturees', action: 'view' },
    { subject: 'workspace.widgets.contratSignes', action: 'view' },
    { subject: 'workspace.widgets.pipeCommerciale', action: 'view' },
  ],
  cs_vt: [
    { subject: 'workspace.dashboard', action: 'view' },
    { subject: 'workspace.documents', action: 'view' },
    { subject: 'workspace.projets', action: 'view' },
    { subject: 'workspace.projets.avps', action: 'update' },
    { subject: 'workspace.projets.avps', action: 'view' },
    { subject: 'workspace.projets.avps.documents.vt', action: 'create' },
    { subject: 'workspace.widgets.pipeCommerciale', action: 'view' },
  ],
};
