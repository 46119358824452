import { defineStore } from 'pinia';
import { OptionMetreStatus, ParametreKeys } from '~~/types/Enums';
import { useMetreLignesStore } from './metreLignes';
import { useProjetsStore } from './projets';
import { useParamValue } from '~/composables/workspaces/parametres/useParamValue';
import { useMetreModelesStore } from './metreModeles';
import type { Metre } from '~/types/models/metre';
import type { ModeleMetre } from '~/types/models/modeleMetre';
import type { OptionMetre } from '~/types/models/optionMetre';
import type { LigneMetre } from '~/types/models/ligneMetre';
import { useCurrentProjet } from '~/composables/projets/useCurrentProjet';

export const useMetreOptionsStore = defineStore('metre-options-store', {
  state: () => {
    return {
      collection: [] as OptionMetre[],
      collectionIntegrees: [] as OptionMetre[],
      lignesMetre: [] as LigneMetre[],
      editedOption: null as OptionMetre | Creatable<OptionMetre> | null,
      page: 1,
      pageCount: 1,
      sortField: 'createdAt',
      sortDirection: ':desc',
      query: '',
      creationPanelOpened: false,
      currentOption: null as OptionMetre | null,
      api: useApi().metresOptions,
    };
  },
  actions: {
    async fetchAll(metreId: number, fetchIntegrees = true) {
      const fetches = [this.fetch(metreId), this.fetchLignesMetre(metreId)];
      if (fetchIntegrees) {
        fetches.push(this.fetchIntegrees(useCurrentProjet().value!.id));
      }
      await Promise.all(fetches);
    },

    async fetch(metreId: number) {
      const response = await this.api.findByMetreId(metreId, this.getFullSortField, this.query);
      if (response?.data) {
        this.collection = response.data;
        this.collection.forEach((o) => {
          o.lignes?.sort((a, b) => (a.article?.type?.ordre || 0) - (b.article?.type?.ordre || 0));
        });
        this.page = response.meta.pagination.page;
        this.pageCount = response.meta.pagination.pageCount;
      }
      return response;
    },

    async refresh(metre?: Metre) {
      const id = metre?.id || this.currentMetre?.id;
      if (!id) return;
      await this.fetch(id);
    },

    async fetchIntegrees(projetId: number) {
      const res = await useApi().metresOptions.findOptionsIntegreesByProjetId(projetId);
      this.collectionIntegrees = res?.data || [];
    },

    async fetchOne(id: number) {
      const res = await this.api.findOne(id);
      if (res?.data) {
        this.setCurrentOption(res?.data);
      }
      return res;
    },

    async fetchLignesMetre(metreId: number) {
      const res = await useApi().metresLignes.findByMetreId(metreId, '', '');
      this.lignesMetre = res?.data || [];
    },

    async create(item: Creatable<OptionMetre>) {
      return await this.api.create(item);
    },

    async update(item: Updatable<OptionMetre>) {
      return await this.api.update(item);
    },

    async delete(item: OptionMetre) {
      return await this.api.delete(item);
    },

    async fixOption(option: OptionMetre) {
      if (option.lignes && option.lignes.length) {
        const ligneStore = useMetreLignesStore();
        for (const l of option.lignes) {
          await ligneStore.fixLigne(l);
        }
      }
    },

    async freeOption(option: OptionMetre) {
      if (option.lignes && option.lignes.length) {
        const ligneStore = useMetreLignesStore();
        for (const l of option.lignes) {
          await ligneStore.freeLigne(l);
        }
      }
    },

    setMetaSortPage({ page = 1, direction = '', sortField = '' }) {
      this.page = page;
      if (direction) this.sortDirection = direction;
      if (sortField) this.sortField = sortField;
    },

    setQuery(query: string) {
      this.page = 1;
      this.query = query;
    },
    setEditedOption(option: OptionMetre | Creatable<OptionMetre> | null) {
      this.editedOption = option;
    },
    setCreationPanelOpened(value: boolean) {
      this.creationPanelOpened = value;
      if (!value) this.setEditedOption(this.getNewOption());
    },
    editOption(option: OptionMetre) {
      this.setEditedOption(option);
      this.setCreationPanelOpened(true);
    },
    createNewOption(metreId: number) {
      this.setEditedOption(this.getNewOption(metreId));
      this.setCreationPanelOpened(true);
    },
    setCurrentOption(option: OptionMetre) {
      this.currentOption = option;
    },
    resetCollections() {
      this.collection = [];
      this.collectionIntegrees = [];
    },
    getNewOption(metreId?: number, marge?: number, tva?: number): Creatable<OptionMetre> {
      const currentMetre = (useMetresStore().currentMetre ||
        (useMetreModelesStore().currentItem as ModeleMetre | null)?.metre) as
        | Metre
        | null
        | undefined;

      const margeOption =
        marge ??
        currentMetre?.marge ??
        ((useParamValue(ParametreKeys.MARGE_METRE_DEFAULT) ?? 0) as number);

      return {
        code: '',
        libelle: '',
        description: '',
        statut: OptionMetreStatus.CREE,
        lignes: [],
        metre: metreId ? ({ id: metreId } as Metre) : undefined,
        tva: tva ?? parseFloat((useParamValue(ParametreKeys.TAUX_TVA) as string) ?? '20'),
        marge: margeOption,
      };
    },
  },
  getters: {
    currentMetre(): Metre | null {
      return useProjetsStore().avp?.metre;
    },
    getFullSortField: (state) => (state.sortField ? state.sortField + state.sortDirection : ''),

    getLigneMetre() {
      return (articleCode: string): LigneMetre | undefined => {
        return this.lignesMetre.find((l) => l.article?.code === articleCode);
      };
    },
    getQuantiteInMetre() {
      return (articleCode: string) => {
        const ligne = this.getLigneMetre(articleCode);
        return useRound(ligne?.quantite ?? 0);
      };
    },

    getLigneInMetre() {
      return (articleCode: string) => {
        return this.lignesMetre.find((l) => l.article?.code === articleCode);
      };
    },

    prixUnitaireLigneOption() {
      return (ligneOption: LigneMetre) => {
        const code = ligneOption?.article?.code;

        const ligneInMetre = code ? this.getLigneMetre(code) : null;

        const targetedLigne =
          ligneInMetre && useProjetsStore().isProjetSousEngagementNotice(useCurrentProjet().value)
            ? ligneInMetre
            : ligneOption;

        return (
          targetedLigne.prix_unitaire ??
          targetedLigne.article_prix ??
          targetedLigne.article?.prix ??
          0
        );
      };
    },
  },
});
