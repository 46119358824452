import { defineNuxtPlugin } from '#app';
import { io, Socket } from 'socket.io-client';
import type { Workspace } from '~/types/models/workspace';

export type BriikSocket = {
  socket: Socket | null;
  connect: (workspace?: Workspace) => void;
  disconnect: () => void;
};

export default defineNuxtPlugin((nuxtApp) => {
  const serverUrl = useRuntimeConfig().public.STRAPI_URL;

  let socket: Socket | null = null;

  const state = useSocketEvents();

  const appWatchableEvents = [
    'signature:updated',
    'commande:signature:updated',
    'avenant:signature:signed',
    'avenant:signature:canceled',
  ];

  const connect = (workspace?: Workspace) => {
    if (socket) {
      disconnect();
    }
    socket = io(serverUrl, {
      addTrailingSlash: true,
      path: '/io/',
      transports: ['websocket'],
      reconnection: true,
      query: {
        'workspace-slug': workspace?.slug || '',
      },
    });

    socket.on('version', (version: string) => {
      useAppStore().setVersionFromServer(version);
      console.info('Server version:', version);
    });

    socket.on('connect', () => {
      console.info('Socket connected');

      useAppStore().setSocketConnexionStatus(true);
    });

    socket.on('disconnect', () => {
      console.info('Socket disconnected');

      useAppStore().setSocketConnexionStatus(false);
    });

    for (const event of appWatchableEvents) {
      socket.on(event, ({ data }) => {
        state.value.push({ event, data });
      });
    }
  };

  const disconnect = () => {
    if (socket) {
      socket.disconnect();
      socket = null;
    }
  };

  connect();

  nuxtApp.provide('socket', { socket, connect, disconnect } as BriikSocket);
});
