export enum Units {
  ENS = 'ENS',
  M = 'M',
  M2 = 'M2',
  M3 = 'M3',
  M4 = 'M4',
  ML = 'ML',
  PC = 'PC',
  U = 'U',
  FF = 'FF',
  FOR = 'FOR',
  FT = 'FT',
  F = 'F',
}

export enum MetreTypes {
  LIBRE = 'Libre',
  // AUTO = 'Auto',
}

export enum OptionMetreStatus {
  CREE = 'créé',
  INTEGRE = 'intégré',
}

export enum MetreStatus {
  EN_COURS = 'en cours',
  VALIDE = 'validé',
  ARCHIVE = 'archivé',
}

export enum ParametreTypes {
  TEXT = 'text',
  TEXTAREA = 'textarea',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
  DATE = 'date',
  COLOR = 'color',
  FILE = 'file',
}

export enum AvenantStatus {
  EN_COURS = 'en cours',
  PROPOSITION_EN_COURS = 'proposition en cours',
  PROPOSITION_RETOURNEE = 'proposition retournée',
  VALIDE = 'validé',
  ENVOYE = 'envoyé',
  SIGNE = 'signé',
  ANNULE = 'annulé',
}

export enum ParametreKeys {
  DUREE_VALIDITE_NOTICE = 'duree_validite_notices',
  DUREE_ENGAGEMENT_PRIX = 'duree_engagement_prix',
  DUREE_ENGAGEMENT_NOTICE = 'duree_engagement_notice',
  MARGE_METRE_DEFAULT = 'marge_metre_defaut',
  TAUX_TVA = 'taux_tva',
  TAUX_TVA_1 = 'taux_tva_1',
  TAUX_TVA_2 = 'taux_tva_2',
  EDITION_LOGO = 'edition_logo',
  MAIN_COLOR = 'main_color',
  SECONDARY_COLOR_1 = 'secondary_color_1',
  SECONDARY_COLOR_2 = 'secondary_color_2',
  MENTION_FP = 'mentions_fp',
  MENTION_ND = 'mentions_nd',
  PPCST_CONTENT = 'ppcst_content',
  TITRE_FP = 'titre_fp',
  TITRE_ND = 'titre_nd',
  NOTICE_CARAC_CONSTRUCTION = 'notice_carac_construction',
  NOTICE_REF_PLAN = 'notice_ref_plan',
  NOTICE_MODELE_APS = 'notice_modele_aps',
  NOTICE_OBJET_PROJET = 'notice_objet_projet',
  TITRE_OBJET_PROJET = 'titre_objet_projet',
  NOTICE_ZONE_CONSTRUCTION = 'notice_zone_construction',
  NOTICE_COLONNE_1 = 'notice_colonne_1',
  NOTICE_COLONNE_2 = 'notice_colonne_2',
  TITRE_COLONNE_1_2 = 'titre_colonne_1_2',
  NOTICE_COLONNE_3 = 'notice_colonne_3',
  TITRE_COLONNE_3 = 'titre_colonne_3',
  QUANTITE_COLONNE_3 = 'quantite_colonne_3',
  NOTICE_COLONNE_4 = 'notice_colonne_4',
  TITRE_COLONNE_4 = 'titre_colonne_4',
  QUANTITE_COLONNE_4 = 'quantite_colonne_4',
  NOTICE_COLONNE_5 = 'notice_colonne_5',
  TITRE_COLONNE_5 = 'titre_colonne_5',
  NOTICE_COLONNE_6 = 'notice_colonne_6',
  TITRE_COLONNE_6 = 'titre_colonne_6',
  NOTICE_COLONNE_7 = 'notice_colonne_7',
  TITRE_COLONNE_7 = 'titre_colonne_7',
  COMPRIS_COLONNE_7 = 'compris_colonne_7',
  NOTICE_COLONNE_8 = 'notice_colonne_8',
  TITRE_COLONNE_8 = 'titre_colonne_8',
  NONCOMPRIS_COLONNE_8 = 'noncompris_colonne_8',
  NUMEROS_COLONNE = 'numeros_colonne',
  NOTICES_TOTAUX_PVTTC_NONCOMPRIS = 'notices_totaux_pvttc_noncompris',
  TITRE_TOTAUX_PVTTC_NONCOMPRIS = 'titre_totaux_pvttc_noncompris',
  NOTICES_TOTAUX_PVHT_COMPRIS = 'notices_totaux_pvht_compris',
  TITRE_TOTAUX_PVHT_COMPRIS = 'titre_totaux_pvht_compris',
  TOTAUX_TVA = 'totaux_tva',
  TOTAUX_TTC_COMPRIS = 'totaux_ttc_compris',
  TITRE_TOTAUX_TTC = 'titre_totaux_ttc',
  TOTAUX_TTC_ALL = 'totaux_ttc_all',
  TITRE_TOTAUX_TTC_ALL = 'titre_totaux_ttc_all',
  NOTICE_MENTION_MANUSCRITE = 'notice_mention_manuscrite',
  TEXT_MENTION_MANUSCRITE = 'text_mention_manuscrite',
  DELAI_PAIEMENT_FACTURE = 'delai_paiement_facture',
}

export enum ParametreCategories {
  NOTICES = 'notices',
  COMMERCE = 'commerce',
  FINANCIER = 'financier',
  EDITION_PDF = 'edition pdf',
  PPCST = 'ppcst',
}

export enum NatureProjet {
  MI = 'Maison Individuelle personnalisée',
  EEXTENSION = "Extension d'une maison",
  AUTRE = 'Autre',
}

export enum TypeConstructeur {
  CG = 'CG',
  CCMI = 'CCMI',
  MO = 'MO',
}

export enum TypeNotice {
  NDD = 'NDD',
  NDT = 'NDT',
  FP = 'FP',
}

export enum TablesNames {
  DEFAULT = 'default_table',
  ARTICLES = 'articles',
  PROJETS = 'projets',
  CONSTRUCTION_MODELES = 'construction_modeles',
  CONSTRUCTON_GAMMES = 'construction_gammes',
  METRE_OPTIONS = 'metre_options',
  METRE_OPTIONS_INTEGRES = 'metre_options_integres',
  METRE_MODELE_OPTIONS = 'metre_modele_options',
  SECTIONS_NOTICES = 'sections_notices',
  DOCUMENTS = 'documents',
  METRE_LIGNES = 'metre_lignes',
  METRE_MODELE_LIGNES = 'metre_modele_lignes',
  AVENANTS = 'avenants',
  AVENANT_OPTIONS = 'avenant_options',
  AVENANT_ARTICLES_SUPPLEMENTAIRES = 'avenant_articles_supplementaires',
  GARANTS = 'garants',
  METRES_TRAVAUX = 'metres_travaux',
  MT_PRECOMMANDES = 'mt_precommandes',
  MT_PRECOMMANDE_MARCHES = 'mt_precommande_marches',
  MT_PRECOMMANDE_COMMANDES = 'mt_precommande_commandes',
  ARTICLES_PRECOMMANDE = 'articles_precommande',
  SSTTFOURS = 'ssttfours',
  SSTTFOUR_CONTACTS = 'ssttfour_contacts',
  SSTTFOUR_TYPES_INTERVENTIONS = 'ssttfour_types_interventions',
  SECTEURS = 'secteurs',
  TERRAINS = 'terrains',
  ANNONCES = 'annonces',
  COMMANDES_PROJET = 'commandes',
  LIGNES_COMMANDE = 'lignes_commande',
  PROJET_COMMANDES = 'projet_commandes',
  PROJET_COMMANDES_INTERVENANTS = 'projet_commandes_intervenants',
  ECHEANCIERS = 'echeanciers',
  JALONS_PROJET = 'jalons_projet',
  SUIVI_MARCHES = 'suivi_marches',
  AVENANTS_MARCHE = 'avenants_marche',
  TYPES_FACTURATION = 'types_facturation',
  FACTURES = 'factures',
  FACTURES_COMMANDES = 'factures_commandes',
  REGLEMENTS = 'reglements',
  REGLEMENTS_EXPORT_SEPA = 'reglements_export_sepa',
  CONTRAT_ANALYTIQUE_DETAILS_ACHATS = 'contrat_analytique_details_achats',
  CONTRAT_ANALYTIQUE_DETAILS_VENTES = 'contrat_analytique_details_ventes',
  COMMANDES = 'commandes_gesfi',
  CLIENTS = 'clients',
  TYPES_ARTICLES = 'types_articles',
}

export enum DocumentCategory {
  DEMANDE_AVP = 'demande_avp',
  DEMANDE_ND = 'demande_nd',
  AVP = 'avp',
  VT = 'vt',
  CONSTRUCTION_VERSION = 'construction_version',
  CONTRAT = 'contrat',
  ADF = 'adf',
  PC = 'pc',
  FINANCEMENT = 'financement',
  TERRAIN_PROJET = 'terrain_projet',
  EPE = 'epe',
  CCI = 'cci',
  GARANTIES = 'garanties',
  SSTTFOUR = 'ssttfour',
  PRECOMMANDE = 'precommande',
  TERRAIN = 'terrain',
  PHOTO_TERRAIN = 'photo_terrain',
  PREPACHANTIER = 'prepa_chantier',
  COMMANDE = 'commande',
  COMMANDE_HORS_TRAVAUX = 'commande_hors_travaux',
  CHANTIER = 'chantier',
  SAV = 'sav',
  WORKSPACE = 'workspace',
  FACTURE = 'facture',
}

export enum DocumentCategoryName {
  DEMANDE_AVP = 'Demande AVP',
  DEMANDE_ND = 'Demande de notice',
  AVP = 'Avant projet',
  VT = 'Visite terrain',
  CONSTRUCTION_VERSION = 'Modèle de construction',
  CONTRAT = 'Contrat',
  ADF = 'Appel de fonds',
  PC = 'Permis de construire',
  FINANCEMENT = 'Financement',
  TERRAIN_PROJET = 'Aquisition terrain',
  EPE = 'Études et plans d’exécution',
  CCI = 'Conception intérieure',
  GARANTIES = 'Garanties',
  SSTTFOUR = 'Sous-traitant et fournisseur',
  PRECOMMANDE = 'Précommande',
  TERRAIN = 'Terrain constructible',
  PHOTO_TERRAIN = 'Photo terrain',
  PREPACHANTIER = 'Préparation de chantier',
  COMMANDE = 'Commande travaux',
  COMMANDE_HORS_TRAVAUX = 'Commande hors travaux',
  CHANTIER = 'Travaux',
  SAV = 'SAV',
  WORKSPACE = 'Marque',
  FACTURE = 'Facture',
}

export enum JalonsProjet {
  CCI = 'Cahier de Conception Intérieure',
  FINANCEMENT = 'Financement',
  ACQUISITION_TERRAIN = 'Acquisition terrain',
  PC = 'PC',
  RECOURS_TIERS_PURGE = 'Recours de tiers purgé',
  PLANS_EXECUTION_SIGNE = "Plans d'execution signés",
  CONTRAT = 'Contrat',
  GARANTIES = 'Garanties',
  METRETRAVAUX = 'Préparation marchés',
  VTC = 'VTC',
  RPO = 'RPO',
  DROC = 'DROC',
}

export enum TypePrestation {
  FO = 'FO',
  PO = 'PO',
  FO_PO = 'FO/PO',
}

export enum PrecommandeStatus {
  EN_COURS = 'en cours',
  VALIDE = 'validé',
  TERMINE = 'terminé',
}

export enum MetreTravauxStatus {
  EN_COURS = 'en cours',
  VALIDE = 'validé',
  TERMINE = 'terminé',
}

export enum PrecommandeTypes {
  CMD = 'cmd',
  MT = 'mt',
}

export enum PrecommandeTypesNames {
  CMD = 'Commande',
  MT = 'Marché de travaux',
}

export enum RegimesTva {
  REEL = 'Réel',
  AUTOLIQUIDATION = 'Autoliquidation',
}

export enum TypeContact {
  ADMINISTRATIF = 'administratif',
  TRAVAUX = 'travaux',
  COMMANDES = 'commandes',
  FACTURATION = 'facturation',
  COMMERCIAL = 'commercial',
  TECHNIQUE = 'technique',
  AUTRE = 'autre',
}

export enum Metier {
  TERRASSIER = 'Terrassier',
  MACON_SOUBASSEMENT_PLANCHER = 'Maçon Soubassement et plancher',
  MACON_ELEVATION = 'Maçon elevation',
  MACON_ESCALIER_BETON = 'Maçon escalier béton',
  TRAITEMENT_ANTI_TERMITES = 'Traitement anti termites',
  CHARPENTIER = 'Charpentier',
  COUVREUR = 'Couvreur',
  ETANCHEITE = 'Etanchéité',
  ZINGUEUR = 'Zingueur',
  MENUISIER = 'Menuisier',
  PLAQUISTE = 'Plaquiste',
  ISOLATION_COMBLES = 'Isolation des combles',
  ELECTRICIEN = 'Electricien',
  PLOMBIER = 'Plombier',
  CHAUFFAGISTE = 'Chauffagiste',
  CHAPISTE = 'Chapiste',
  ISOLATION_SOL = 'Isolation du sol',
  CARRELEUR = 'Carreleur',
  ENDUISEUR = 'Enduiseur',
  DIAGNOSTIQUEUR = 'Diagnostiqueur',
  POSE_PARQUET = 'Pose parquet',
  SOCIETE_NETTOYAGE = 'Société de nettoyage',
}

export enum TerrainStatus {
  NOUVEAU = 'nouveau',
  OPTION = 'option en cours',
  VENDU = 'vendu',
}

export enum AnnonceTypes {
  T = 'T',
  TM = 'TM',
}

export enum CommandeStatus {
  ATTRIBUE = 'attribué',
  BROUILLON = 'brouillon',
  ENVOYE = 'envoyé',
  SIGNE = 'signé',
  ANNULE = 'annulé',
  FACTURE = 'facturé',
  TERMINE = 'terminé',
}

export enum FactureClientStatus {
  A_GENERER = 'à générer',
  A_ENVOYER = 'à envoyer',
  ENVOYE = 'en attente de règlement',
  REGLEMENT_PARTIEL = 'partiellement réglé',
  REGLE = 'réglé',
}

export enum ContactOrigins {
  INDETERMINE = 'Indéterminé',
  RELATIONNEL = 'Relationnel',
  APPORTEUR = 'Apporteur',
  SITE_WEB = 'Site web',
  CONFIGURATEUR = 'Configurateur',
  MAILING = 'Mailing',
  BOUCHE_A_OREILLE = 'Bouche à oreille',
  PARAINAGE = 'Parrainage',
  PA = 'Petites annonces',
  PORTES_OUVERTES = 'Portes ouvertes',
  SALONS_ET_FOIRES = 'Salons et foires',
  RESEAUX_SOCIAUX_PRESSE = 'Réseaux sociaux/presse',
}

export enum JalonPnl {
  SIGNATURE_CONTRAT = 'signature_contrat',
  CREATION_METRE_TRAVAUX = 'creation_metre_travaux',
  GENERATION_BDC = 'generation_bdc',
  SIGNATURE_AVENANT = 'signature_avenant',
  RECEPTION = 'reception',
  AUJOURDHUI = 'aujourd_hui',
  REEL_FACTURE = 'reel_facture',
  ECART_1_2 = 'ecart_1_2',
}

export enum LabelPnl {
  SIGNATURE_CONTRAT = 'Signature du contrat',
  CREATION_METRE_TRAVAUX = 'Préparation des marchés',
  GENERATION_BDC = 'Ouverture',
  SIGNATURE_AVENANT = "Signature d'avenant",
  RECEPTION = 'Réception',
  AUJOURDHUI = 'Aujourd’hui',
  REEL_FACTURE = 'Facturé',
  ECART_1_2 = 'Écart (1-2)',
}

export enum JalonFacturationFixe {
  ACOMPTE = 'ACOMPTE',
}

export enum FromDelaiFacturation {
  PREV = 'Jalon précédent',
  SIGNATURE_CONTRAT = 'Signature du contrat',
  OUVERTURE = 'Ouverture des travaux',
}

export enum FactureStatus {
  IMPORTATION = 'importation',
  A_TRAITER = 'à traiter',
  A_VALIDER = 'à valider',
  A_REGLER = 'à régler',
  REGLEMENT_EN_COURS = 'règlement en cours',
  REGLEE = 'réglée',
  REFUSEE = 'refusée',
}

export enum FactureReglementStatus {
  A_VENIR = 'à venir',
  A_REGLER = 'à régler',
  REGLEMENT_PARTIEL = 'partiellement réglé',
  REGLE = 'réglé',
  VERIFIE = 'vérifié',
}

export const EntitesRattachementFacture = {
  PROJET: {
    id: 'api::projet.projet',
    label: 'Projet',
    apiName: 'projets',
    labelField: 'code',
    filters: {
      avancement: {
        code: { $notIn: [useConstants.avancements.ANNULE] },
      },
    },
  },
  AGENCE: {
    id: 'api::agence.agence',
    label: 'Agence',
    apiName: 'agences',
    labelField: 'name',
    filters: {},
  },
};

export enum ModeReglement {
  VIREMENT = 'virement',
  CHEQUE = 'chèque',
  ESPECE = 'espèce',
  CARTE_BANCAIRE = 'carte bancaire',
  PRELEVEMENT = 'prélèvement',
  LCR = 'LCR',
  AVOIR = 'avoir',
}

export const useModeReglementMetas = (modeReglement?: ModeReglement) => {
  switch (modeReglement) {
    case ModeReglement.VIREMENT:
      return [
        { key: 'iban', label: 'IBAN' },
        { key: 'bic', label: 'BIC' },
      ];
    default:
      return [];
  }
};

export enum SsttfourTypes {
  TRAVAUX = 'TRAVAUX',
  AUTRE = 'AUTRE',
}

export enum TypeFacturation {
  APPEL_DE_FONDS = 0,
  AVANCEMENT_GLOBAL = 1,
  AVANCEMENT_DETAILLE = 2,
  FIN_DE_PRESTATION = 3,
}

export const TypeFacturationLabel = {
  [TypeFacturation.APPEL_DE_FONDS]: 'Appel de fonds',
  [TypeFacturation.AVANCEMENT_GLOBAL]: 'Avancement global',
  [TypeFacturation.AVANCEMENT_DETAILLE]: 'Avancement détaillé',
  [TypeFacturation.FIN_DE_PRESTATION]: 'Fin de prestation',
};

export enum SystemeNumerotation {
  MENSUEL = 0,
  ANNUEL = 1,
}

export const SystemeNumerotationLabel = {
  [SystemeNumerotation.MENSUEL]: 'Numérotation menuselle (ex: 2024-06-000XXX)',
  [SystemeNumerotation.ANNUEL]: 'Numérotation annuelle (ex: 2024-000412)',
};

export enum SignFieldType {
  SIGNATURE = 'signature',
  MENTION = 'mention',
  TEXTE = 'read_only_text',
}

export const SignFieldFontSizes = {
  [SignFieldType.SIGNATURE]: 18,
  [SignFieldType.MENTION]: 12,
  [SignFieldType.TEXTE]: 12,
};

export const SignMentions = [
  'Lu et approuvé',
  'Lu et approuvé, signé le %date%',
  'Lu et approuvé, bon pour accord',
  'Lu et approuvé, bon pour accord, signé le %date%',
  'Bon pour accord',
  'Bon pour accord, signé le %date%',
  'Bon pour acceptation',
  'Bon pour acceptation, signé le %date%',
  'Signé le %date%',
  '%date%',
];

export enum SignatureStatus {
  EN_COURS = 'en cours',
  SIGNE = 'réalisée',
  EXPIRE = 'expirée',
  VALIDE = 'ok',
  ANNULE = 'annulée',
}

export const heatColors = ['#99ccff', '#1e90ff', '#00ff00', '#ffa500', '#ff4500', '#ff0000'];
export const heatLabels = ['Très faible', 'Faible', 'Modérée', 'Élevée', 'Très élevée', 'Certain'];
export const heatValues = [0, 1, 2, 3, 4, 5];

export enum WidgetScreen {
  DASHBOARD = 'dashboard',
}

export enum WidgetName {
  WIDGETDEMANDEAVP = 'WidgetDemandeAvp',
  WIDGETCONTARTSIGNE = 'WidgetContratSigne',
  WIDGETPIPECOMMERCIALE = 'WidgetPipeCommerciale',
  WIDGETAVPENCOURS = 'WidgetAvpEnCours',
  WIDGETCOMMANDESURFACTUREES = 'WidgetCommandesSurfacturees',
}
