import { defineStore } from 'pinia';
import type { Commande } from '~/types/models/commande';
import { useFiltersStore } from './filters';
import { CommandeStatus, PrecommandeTypes, RegimesTva, TablesNames } from '~/types/Enums';
import type { CommandeLigne } from '~/types/models/commandeLigne';
import type { Document } from '~/types/models/document';
import { usePdfBdc } from '~/composables/pdf/usePdfBdc';
import JSZip from 'jszip';
import { DocumentType } from '~/documents/types';
import { DocumentPDF } from '~/src/models/DocumentPDF';
import { useProjetsStore } from './projets';

export const useCommandesStore = defineStore('commandes', {
  state: () => ({
    commandes: [] as Commande[],
    marchesEnSignatures: [] as Commande[],
    page: 1,
    pageCount: 1,
    sortField: 'createdAt',
    sortDirection: ':desc',
    query: '',
    filters: {},
    currentCommande: null as Commande | null,
    preparingDownload: false,
    docsToDelete: [] as Document[],
    docsToUpdate: [] as Document[],
    lignesToDelete: [] as CommandeLigne[],
  }),
  getters: {
    queryFilters: (state) => useFiltersStore().queryFilters(TablesNames.COMMANDES_PROJET),
    querySorts: () => useDatasTablesState(TablesNames.COMMANDES_PROJET).querySorts(),

    montantTotalHt() {
      return (commande: Commande): number =>
        commande.lignes.reduce((acc, item) => acc + item.prix, 0) +
        (commande.avenants?.reduce(
          (acc, avt) =>
            avt.statut !== CommandeStatus.ANNULE && !avt.exclueDebours
              ? acc + this.montantTotalHt(avt)
              : acc,
          0,
        ) || 0);
    },
    montantTotalTtc() {
      return (commande: Commande): number =>
        useApplyTva(this.montantTotalHt(commande), this.tauxTva(commande));
    },

    tauxTva() {
      return (commande: Commande): number => {
        if (commande.ssttfour?.regimeTva === RegimesTva.REEL) {
          return useTauxTva();
        }
        return 1;
      };
    },

    tvaPercent() {
      return (commande: Commande): number => {
        const taux = this.tauxTva(commande);

        return taux * 100 - 100;
      };
    },
    totalPourSousLot() {
      return (commande: Commande, sousLotId: number): number => {
        return commande.lignes.reduce((acc, item) => {
          if (item.sousLot?.id === sousLotId) return acc + item.prix;
          return acc;
        }, 0);
      };
    },

    canGenerateBdc() {
      return (commande: Commande): boolean => {
        return commande.statut !== CommandeStatus.BROUILLON && !!commande.reference;
      };
    },

    deboursTotal() {
      return (commandes: Commande[]): number => {
        return commandes
          .filter((c) => c.statut !== CommandeStatus.ANNULE && !c.exclueDebours)
          .reduce((acc, item) => acc + this.montantTotalHt(item), 0);
      };
    },
    currentProjet(): any {
      return useProjetsStore().currentProjet;
    },
  },
  actions: {
    async fetch() {
      this.filters = await useApi().commandes.getFilters();
      const res = await useApi().commandes.find(this.page, this.querySorts, this.query, 100, {
        $and: [this.queryFilters],
      });
      if (res && res.data) {
        this.commandes = res.data;
        this.page = res.meta?.pagination.page || 1;
        this.pageCount = res.meta?.pagination.pageCount || 1;
      }
    },

    async fetchOne(id: number) {
      const res = await useApi().commandes.findOne(id);
      if (res && res.data) {
        this.currentCommande = res.data;
        this.docsToDelete = [];
        this.docsToUpdate = [];
        this.lignesToDelete = [];
      }
    },

    async fetchByCurrentProjet() {
      this.filters = await useApi().commandes.getFilters();
      if (!this.currentProjet) return;
      const res = await useApi().commandes.find(this.page, this.querySorts, this.query, 100, {
        $and: [
          this.queryFilters,
          {
            projet: { id: { $eq: this.currentProjet.id } },
          },
          { parent: { id: { $null: true } } },
        ],
      });
      if (res && res.data) {
        this.commandes = res.data;
        this.page = res.meta?.pagination.page || 1;
        this.pageCount = res.meta?.pagination.pageCount || 1;
      }
    },

    async fetchMarchesEnSignatures() {
      const res = await useApi().commandes.find(this.page, this.querySorts, this.query, 25, {
        type: { $eq: PrecommandeTypes.MT },
        statut: { $eq: CommandeStatus.ENVOYE },
      });

      if (res && res.data) {
        this.marchesEnSignatures = res.data;
        this.page = res.meta?.pagination.page || 1;
        this.pageCount = res.meta?.pagination.pageCount || 1;
      } else {
        this.marchesEnSignatures = [];
      }
    },

    async relanceMarcheEnSignature(marche: Commande, date: string) {
      if (!marche.hasOwnProperty('relances') || !marche.relances) {
        marche.relances = [];
      }
      marche.relances.push(date);

      await this.update({ id: marche.id, relances: marche.relances } as Commande);
    },

    async create(commande: Creatable<Commande>, projetId?: number) {
      commande.projet = projetId || this.currentProjet.id;

      const domaines = await useApi().factureDomaines.find(1, '', '', 1, {
        affectations: {
          cmdDefault: { $eq: true },
        },
      });

      const defaultDomaine = domaines?.data[0];

      if (defaultDomaine) commande.domaine = domaines.data[0];

      const defaultAffectation = defaultDomaine?.affectations?.find(
        (aff) => aff.cmdDefault === true,
      );

      if (defaultAffectation) commande.affectation = defaultAffectation;

      const res = await useApi().commandes.create(commande);
      if (res && res.data) {
        this.commandes.push(res.data);
        return res.data;
      }
      return null;
    },

    async update(commande: Commande) {
      const res = await useApi().commandes.update(commande);
      if (res && res.data) {
        const index = this.commandes.findIndex((item) => item.id === commande.id);
        this.commandes[index] = res.data;
        if (this.currentCommande?.id === commande.id) {
          this.currentCommande = res.data;
        }
        return res.data;
      }
      return null;
    },

    async updateLigne(ligne: Updatable<CommandeLigne>) {
      const res = await useApi().commandesLignes.update(ligne, {});
      if (res && res.data) {
        return res.data;
      }
      return null;
    },
    async createLigne(ligne: Creatable<CommandeLigne>) {
      (ligne as any).id = null;
      const res = await useApi().commandesLignes.create(ligne);
      if (res && res.data) {
        return res.data as CommandeLigne;
      }
      return null;
    },

    async deleteLigne(ligne: CommandeLigne) {
      const res = await useApi().commandesLignes.delete(ligne);
      if (res && res.data) {
        return res.data;
      }
      return null;
    },

    setMetaSortPage({ page = 1, direction = '', sortField = '' }) {
      this.page = page;
      if (direction) this.sortDirection = direction;
      if (sortField) this.sortField = sortField;
    },

    setQuery(query: string) {
      this.page = 1;
      this.query = query;
    },

    setCurrentCommande(commande: Commande | null) {
      this.currentCommande = commande;
    },

    async delete(commande: Commande) {
      const res = await useApi().commandes.delete(commande);
      if (res && res.data) {
        const index = this.commandes.findIndex((item) => item.id === commande.id);
        this.commandes.splice(index, 1);
      }
    },

    async refresh() {
      await this.fetchByCurrentProjet();
    },

    async refreshOne(commande: Commande) {
      if (commande.id) {
        const res = await useApi().commandes.findOne(commande.id);
        if (res && res.data) {
          return res.data;
        }
      }
      return null;
    },

    async refreshCurrent() {
      if (this.currentCommande) {
        this.currentCommande = await this.refreshOne(this.currentCommande);
      }
    },

    async merge(from: Commande, to: Commande) {
      to.commentaires = `${to.commentaires || ''}${
        from.commentaires ? '\n\n' + from.commentaires : ''
      }`;

      for (let i = 0; i < from.lignes.length; i += 1) {
        const ligne = from.lignes[i];
        to.lignes.push(ligne);
      }

      for (let i = 0; i < from.documents.length; i += 1) {
        const document = from.documents[i];
        to.documents.push(document);
      }

      await this.update(to);
      await this.delete(from);
    },

    reset() {
      this.resetCommandes();
      this.resetCurrentCommande();
    },

    resetCommandes() {
      this.query = '';
      this.commandes = [];
    },

    resetCurrentCommande() {
      this.currentCommande = null;
      this.docsToDelete = [];
      this.docsToUpdate = [];
      this.lignesToDelete = [];
    },

    async downloadBdcPdf(commande: Commande) {
      const doc = await this.getBdcPdf(commande);
      if (doc instanceof DocumentPDF) {
        doc.download(document);
        return;
      }
      useDownloadFile(doc.media.url, doc.name);
    },

    async previewBdcPdf(commande: Commande) {
      const doc = await this.getBdcPdf(commande);
      if (doc instanceof DocumentPDF) {
        doc.preview();
        return;
      }
      useOpenFileInNewTab(doc.media.url, doc.name);
    },

    async zipAndDownloadFiles(commande: Commande) {
      this.preparingDownload = true;
      const zip = new JSZip();

      const bdcPdf = await this.getBdcPdf(commande);
      if (bdcPdf instanceof DocumentPDF) {
        zip.file(bdcPdf.name, bdcPdf.getAsBlob());
      }

      for (const doc of commande.documents) {
        const response = await fetch(doc.media.url);
        const blob = await response.blob();
        zip.file(doc.name, blob);
      }
      const content = await zip.generateAsync({ type: 'blob' });
      useDownloadFile(URL.createObjectURL(content), `${commande.reference}.zip`);

      this.preparingDownload = false;
    },

    async getBdcPdf(commande: Commande): Promise<Document | DocumentPDF> {
      const bdcSigne = commande.documents.find((doc) => doc.type === DocumentType.BDC_SIGNE.name);
      if (bdcSigne) return bdcSigne;
      const bdcEnvoye = commande.documents.find((doc) => doc.type === DocumentType.BDC.name);
      if (bdcEnvoye) return bdcEnvoye;

      return await usePdfBdc(commande);
    },

    async saveCommande(commande: Commande) {
      if (commande.ssttfour && commande.statut === CommandeStatus.BROUILLON) {
        commande.statut = CommandeStatus.ATTRIBUE;
      }
      if (!commande.ssttfour && commande.statut === CommandeStatus.ATTRIBUE) {
        commande.statut = CommandeStatus.BROUILLON;
      }

      for (const ligne of commande.lignes) {
        const isNewLigne = ligne.id === -1;

        if (isNewLigne) {
          const lineToCreate = { ...ligne } as Creatable<CommandeLigne>;
          // @ts-ignore
          delete lineToCreate.id;
          const resLigne = await this.createLigne(lineToCreate);
          if (resLigne) {
            ligne.id = resLigne.id;
          }
        } else {
          const currentLigne = this.currentCommande?.lignes.find((l) => l.id === ligne.id);
          if (JSON.stringify(ligne) !== JSON.stringify(currentLigne)) {
            await useApi().commandesLignes.update(ligne, {});
          }
        }
      }

      for (const ligne of this.lignesToDelete) {
        await this.deleteLigne(ligne);
      }

      this.lignesToDelete = [];

      for (const d of this.docsToDelete) {
        if (d.id) await useApi().documents.delete(d.id);
      }

      this.docsToDelete = [];

      for (const d of this.docsToUpdate) {
        if (d) {
          // @ts-ignore
          await useApi().documents.update({ id: d.id, commande: commande.id }, {});
        }
      }

      this.docsToUpdate = [];

      const commandeToUpdate = { ...commande } as Commande;
      // @ts-ignore
      delete commandeToUpdate.lignes;
      // @ts-ignore
      delete commandeToUpdate.documents;
      delete commandeToUpdate.projet;

      await this.update(commandeToUpdate);
    },

    async addLigneToDelete(l: CommandeLigne) {
      this.lignesToDelete.push(l);
    },

    async addDocToUpdate(d: Document) {
      this.docsToUpdate.push(d);
    },
    async addDocToDelete(d: Document) {
      this.docsToDelete.push(d);
    },

    async cleanBeforeLeaving() {
      //delete documentsToUpdate
      for (let i = 0; i < this.docsToUpdate.length; i++) {
        const d = this.docsToUpdate[i];
        if (d.id) await useApi().documents.delete(d.id);
      }
    },

    async cancel(commande: Commande) {
      // cancel avenants
      if (commande.avenants?.length)
        for (let i = 0; i < commande.avenants.length; i++) {
          const avt = commande.avenants[i];
          await this.cancel(avt);
        }

      return await this.update({
        id: commande.id,
        statut: CommandeStatus.ANNULE,
      } as Commande);
    },

    duplicateLigne(ligne: CommandeLigne): Creatable<CommandeLigne> {
      const newLigne: Creatable<CommandeLigne> = {
        description: ligne.description,
        descriptionComplementaire: ligne.descriptionComplementaire,
        gamme: ligne.gamme,
        unite: ligne.unite,
        quantite: ligne.quantite,
        prixUnitaire: ligne.prixUnitaire,
        prix: ligne.prix,
        sousLot: ligne.sousLot,
        type_prestation: ligne.type_prestation,
        devis: ligne.devis,
        commande: -1,
      };

      return newLigne;
    },

    async duplicate(commande: Commande): Promise<Commande | null> {
      const newCommande = {
        type: commande.type,
        libelle: commande.libelle,
        dateLivraisonPrevue: commande.dateLivraisonPrevue,
        statut: CommandeStatus.BROUILLON,
        commentaires: commande.commentaires,
        projet: commande.projet,
        relances: commande.relances,
        reference: '',
        lignes: [],
        documents: [],
        domaine: commande.domaine,
        affectation: commande.affectation,
      } as Creatable<Commande>;

      // create Commande
      const newCreatedCommande = await this.create(newCommande, commande.projet!.id);

      if (newCreatedCommande) {
        // duplicate lignes
        for (let i = 0; i < commande.lignes.length; i++) {
          const ligne = commande.lignes[i];
          const newLigne = this.duplicateLigne(ligne);
          newLigne.commande = newCreatedCommande.id!;
          const newCreatedLigne = await this.createLigne(newLigne);
          if (newCreatedLigne?.id) {
            newCreatedCommande.lignes.push(newCreatedLigne);
          }
        }

        // Create lignes from each avenants
        if (commande.avenants?.length)
          for (let i = 0; i < commande.avenants.length; i++) {
            const avt = commande.avenants[i];
            for (let j = 0; j < avt.lignes.length; j++) {
              const ligne = avt.lignes[j];
              const newLigne = this.duplicateLigne(ligne);
              newLigne.commande = newCreatedCommande.id!;
              const newCreatedLigne = await this.createLigne(newLigne);
              if (newCreatedLigne?.id) {
                newCreatedCommande.lignes.push(newCreatedLigne);
              }
            }
          }

        // move documents
        if (commande.documents?.length) {
          for (let i = 0; i < commande.documents.length; i++) {
            const doc = commande.documents[i];
            if (
              ![
                DocumentType.DEVIS_SSTTFOUR.name,
                DocumentType.BDC_SIGNE.name,
                DocumentType.BDC.name,
              ].includes(doc.type) &&
              !doc.signature
            ) {
              newCreatedCommande.documents.push(doc);
            }
          }

          return await this.update(newCreatedCommande);
        }
        return newCreatedCommande;
      }
      return null;
    },

    async reattribuer(commande: Commande) {
      const newCommande = await this.duplicate(commande);
      if (newCommande) {
        await this.cancel(commande);
      }

      return newCommande;
    },
  },
});
