import type { Document } from '~/types/models/document';

export const useUploadDocToEntity = async (
  file: File,
  documentCategory: string,
  docTypeName: string,
  entities?: { key: string; value: any }[],
) => {
  try {
    const datas: Creatable<Document> = {
      media: file,
      type: docTypeName,
      name: file.name,
      category: documentCategory,
    };

    if (entities?.length) {
      for (const entity of entities) {
        (datas as any)[entity.key] = entity.value;
      }
    }

    const req = await useApi().documents.create(datas);
    return req?.data || null;
  } catch (error) {
    console.error(error);
    return null;
  }
};
