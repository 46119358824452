import { defineStore } from 'pinia';
import type { StatutProjet } from '~/types/models/statutProjet';

export const useStatutsStore = defineStore({
  id: 'statuts-store',
  state: () => {
    return {
      collection: [] as StatutProjet[],
      api: useApi().statuts as GenericApi<StatutProjet>,
    };
  },
  actions: {
    async fetch() {
      if (this.collection?.length) return;
      const response = await this.api.find(1, '', '', 999);
      this.collection = response?.data?.sort((a, b) => a.ordre - b.ordre) || [];
      return this.collection;
    },
  },
  getters: {
    getStatut: (state) => (code: string) => {
      return state.collection?.find((statut) => statut.code === code) || null;
    },
    getAvancement: (state) => (code: string) => {
      for (const statut of state.collection) {
        const avancement = statut.avancements?.find((a) => a.code === code);
        if (avancement) return avancement;
      }
    },
    sortedStatuts: (state) => {
      return state.collection.sort((a, b) => a.ordre - b.ordre);
    },
    avancements: (state) => {
      const avancements = [];
      for (const statut of state.collection) {
        avancements.push(...(statut.avancements?.sort((a, b) => a.ordre - b.ordre) || []));
      }
      return avancements;
    },
  },
});
