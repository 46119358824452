import type { ModeleConstruction } from '~/types/models/modeleConstruction';
import type { ApiClient, GenericApi } from '~~/composables/useApiFactory';

const entityName = 'construction-modeles';
const populate = {
  visuel: true,
  gamme: true,
  versions: {
    populate: { modele: true, visuel: true, metre_modele: { populate: { metre: true } } },
  },
};
const searchFields = ['libelle'];

type Api = GenericApi<ModeleConstruction> & {
  isUnique: (slug: string) => Promise<boolean>;
  fetchAll: () => Promise<ApiResults<ModeleConstruction> | null>;
};

export default (api: ApiClient) => {
  const theApi = useApiFactory(api, entityName, searchFields, populate, ['libelle:asc']) as Api;

  theApi.isUnique = async (slug: string) => {
    const result = await api<boolean>(`${entityName}/unique/${slug}`, {});
    return result;
  };

  theApi.fetchAll = async () => {
    const { $loading } = useNuxtApp();
    let res;
    try {
      const params = {
        populate: populate,
        filters: {},
        sort: ['libelle'],
        pagination: { limit: -1 },
      };
      $loading.value = true;
      res = await api<ApiResults<ModeleConstruction>>(`${entityName}?${useQs(params)}`, {});
    } catch (error) {
      console.error(error);
      res = null;
    }
    $loading.value = false;
    return res;
  };

  return theApi;
};
