
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.default || mod) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
  }
}
import { default as annoncesO8F1SKExWRMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/catalogues/annonces.vue?macro=true";
import { default as gammesHYVN0KaTo0Meta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/catalogues/articles/gammes.vue?macro=true";
import { default as indexxQJErEoGspMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/catalogues/articles/index.vue?macro=true";
import { default as lotszlhoYdYIdjMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/catalogues/articles/lots.vue?macro=true";
import { default as types_articlesJ23ZBq3pv3Meta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/catalogues/articles/types_articles.vue?macro=true";
import { default as articlesJshotVqKYRMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/catalogues/articles.vue?macro=true";
import { default as gammes51D7Dd6tWmMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/catalogues/constructions/gammes.vue?macro=true";
import { default as indexeIhQ7fLAeuMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/catalogues/constructions/index.vue?macro=true";
import { default as indexHHaJ3tMemVMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/catalogues/constructions/modeles/index.vue?macro=true";
import { default as documentsMDBIABVYi4Meta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/catalogues/constructions/modeles/versions/[id]/documents.vue?macro=true";
import { default as indexDnVRZQtPgiMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/catalogues/constructions/modeles/versions/[id]/index.vue?macro=true";
import { default as mediasr6SQCgon2eMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/catalogues/constructions/modeles/versions/[id]/medias.vue?macro=true";
import { default as _91id_93GIFDTs0EigMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/catalogues/constructions/modeles/versions/[id].vue?macro=true";
import { default as modelesigEabaXtpyMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/catalogues/constructions/modeles.vue?macro=true";
import { default as constructionsOKcR2lCOROMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/catalogues/constructions.vue?macro=true";
import { default as indexrksTiY8VS1Meta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/catalogues/index.vue?macro=true";
import { default as indexukMDlnfRQAMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/catalogues/metres/[id]/index.vue?macro=true";
import { default as lignesdCQe8scWHKMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/catalogues/metres/[id]/lignes.vue?macro=true";
import { default as optionsWuOC1oeKrPMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/catalogues/metres/[id]/options.vue?macro=true";
import { default as parametreseB6B0jKdcRMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/catalogues/metres/[id]/parametres.vue?macro=true";
import { default as _91id_93eWeS43ZIEtMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/catalogues/metres/[id].vue?macro=true";
import { default as indexd7oFqwC93AMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/catalogues/metres/index.vue?macro=true";
import { default as metresAAvZFzdopgMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/catalogues/metres.vue?macro=true";
import { default as terrainsZvHKlpwncgMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/catalogues/terrains.vue?macro=true";
import { default as cataloguesxAdyvStKXHMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/catalogues.vue?macro=true";
import { default as _91id_93ILo0oID0dTMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/contacts/clients/[id].vue?macro=true";
import { default as indexgNhnMYBICRMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/contacts/clients/index.vue?macro=true";
import { default as clientsKcOBmFUY5oMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/contacts/clients.vue?macro=true";
import { default as indexqqAf2IvxHtMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/contacts/index.vue?macro=true";
import { default as index1iSIDKRgqwMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/contacts/ssttfours/index.vue?macro=true";
import { default as ssttfours8uXRg3ZIAqMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/contacts/ssttfours.vue?macro=true";
import { default as contactswTZU2qRwzwMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/contacts.vue?macro=true";
import { default as dashboardTaMIvq2vRzMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/dashboard.vue?macro=true";
import { default as documentsCpw5y3wQ9QMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/documents.vue?macro=true";
import { default as commandeshcC5rn2NrWMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/gesfi/achats/commandes.vue?macro=true";
import { default as facturesP1t9wkekCnMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/gesfi/achats/factures.vue?macro=true";
import { default as indexx2M0wX3f0TMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/gesfi/achats/index.vue?macro=true";
import { default as reglementsOp2tUKly7LMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/gesfi/achats/reglements.vue?macro=true";
import { default as achatstqYYmjTyp6Meta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/gesfi/achats.vue?macro=true";
import { default as facturationKDoBxOeGlNMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/gesfi/dashboards/facturation.vue?macro=true";
import { default as indexnbCOREYYaWMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/gesfi/dashboards/index.vue?macro=true";
import { default as ventesC6MvpdPbxQMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/gesfi/dashboards/ventes.vue?macro=true";
import { default as dashboardsyz2i4FvSNEMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/gesfi/dashboards.vue?macro=true";
import { default as exportsPHhjnSQTYXMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/gesfi/exports.vue?macro=true";
import { default as indexLhO9bnHsZCMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/gesfi/index.vue?macro=true";
import { default as indexzW0CSx6Rj6Meta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/gesfi/ventes/index.vue?macro=true";
import { default as suivifacturwnJ4u36oNMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/gesfi/ventes/suivifactu.vue?macro=true";
import { default as ventesXPn6gNMZ9FMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/gesfi/ventes.vue?macro=true";
import { default as gesfieNSDIq6ibOMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/gesfi.vue?macro=true";
import { default as indexNN5eZr8bfMMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/index.vue?macro=true";
import { default as metreseKUlyUGmFaMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/metres.vue?macro=true";
import { default as annonces9tJFGRlHqMMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/parametres/diffusion/annonces.vue?macro=true";
import { default as ubiflowfo7o6l5ykfMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/parametres/diffusion/ubiflow.vue?macro=true";
import { default as diffusionMoACp3PVGHMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/parametres/diffusion.vue?macro=true";
import { default as indexwDcpvERNlaMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/parametres/documents/index.vue?macro=true";
import { default as signaturevUdNRxUNRcMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/parametres/documents/signature.vue?macro=true";
import { default as typesU6qbibQJdkMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/parametres/documents/types.vue?macro=true";
import { default as documentsi6sx3k3KAVMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/parametres/documents.vue?macro=true";
import { default as generalVgckX4WZi0Meta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/parametres/editions/general.vue?macro=true";
import { default as indexJ7kwVYMllHMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/parametres/editions/index.vue?macro=true";
import { default as noticesY0hwAITtwwMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/parametres/editions/notices.vue?macro=true";
import { default as ppcstxY2NSSAl5HMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/parametres/editions/ppcst.vue?macro=true";
import { default as editionsKydCc7Z3ghMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/parametres/editions.vue?macro=true";
import { default as affectations0UxGt7HNjNMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/parametres/financiers/affectations.vue?macro=true";
import { default as banqueQaHHhdu3PvMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/parametres/financiers/banque.vue?macro=true";
import { default as facturationG3jRR88r4AMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/parametres/financiers/facturation.vue?macro=true";
import { default as indexkfAeEk7JJ5Meta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/parametres/financiers/index.vue?macro=true";
import { default as validationsOjnrXbbgVnMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/parametres/financiers/validations.vue?macro=true";
import { default as financiersP1KoDU80jQMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/parametres/financiers.vue?macro=true";
import { default as garantsMR4JejqKM0Meta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/parametres/garants.vue?macro=true";
import { default as indexAyNhb7zJpPMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/parametres/index.vue?macro=true";
import { default as marqueFjofStgbFcMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/parametres/marque.vue?macro=true";
import { default as secteursFji9B1a2PCMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/parametres/secteurs.vue?macro=true";
import { default as utilisateurs9Vq68CXgbgMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/parametres/utilisateurs.vue?macro=true";
import { default as ventemJztgtXWQqMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/parametres/vente.vue?macro=true";
import { default as parametresbB71w0WuaiMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/parametres.vue?macro=true";
import { default as indexCa5hfDZvVOMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/avp/index.vue?macro=true";
import { default as lignesM5a1SuF8L1Meta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/avp/metre/[mid]/lignes.vue?macro=true";
import { default as options8ytRSCrWE4Meta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/avp/metre/[mid]/options.vue?macro=true";
import { default as _91mid_935hSeTo7nViMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/avp/metre/[mid].vue?macro=true";
import { default as avpDxGUclgrmzMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/avp.vue?macro=true";
import { default as analyticsucy1XNbttvMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/contrat/analytics.vue?macro=true";
import { default as avenants1KYhGQw2snMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/contrat/avenants.vue?macro=true";
import { default as facturationunB4pPT9tmMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/contrat/facturation.vue?macro=true";
import { default as indexXUd4txpQiqMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/contrat/index.vue?macro=true";
import { default as contratTfhomkh4mKMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/contrat.vue?macro=true";
import { default as indexh2bQKPkxrDMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/index.vue?macro=true";
import { default as ciHPBAK0bZFOMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/pp/ci.vue?macro=true";
import { default as epeoZoJw7ee3YMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/pp/epe.vue?macro=true";
import { default as financementMAK23nfyjEMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/pp/financement.vue?macro=true";
import { default as garantiesxCUlCsWrVtMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/pp/garanties.vue?macro=true";
import { default as pcaN9gH8Vu8YMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/pp/pc.vue?macro=true";
import { default as prepaschantiersfaqvEwnBHbMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/pp/prepaschantiers.vue?macro=true";
import { default as terrainZygnRfiabgMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/pp/terrain.vue?macro=true";
import { default as pp7jCLnVMBFJMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/pp.vue?macro=true";
import { default as indexRxf5inxd4ZMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/sav/index.vue?macro=true";
import { default as indexoXS7VpquI3Meta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/travaux/cmd/[cid]/index.vue?macro=true";
import { default as _91cid_93Yly8m0MhzHMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/travaux/cmd/[cid].vue?macro=true";
import { default as indexlfYZPies1RMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/travaux/cmd/index.vue?macro=true";
import { default as cmdTWyhTaufYZMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/travaux/cmd.vue?macro=true";
import { default as documentskK1svMRntSMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/travaux/documents.vue?macro=true";
import { default as indexDY6gB5ITl2Meta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/travaux/index.vue?macro=true";
import { default as planificationgpvU9z9L98Meta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/travaux/planification.vue?macro=true";
import { default as travaux2pXkcNyAOmMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/travaux.vue?macro=true";
import { default as _91id_93OxT4ttmiBYMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id].vue?macro=true";
import { default as indexPrlvpykgJMMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/index.vue?macro=true";
import { default as projetsifgIhsTfYvMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets.vue?macro=true";
import { default as indexL4SC2lCbVJMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/travaux/index.vue?macro=true";
import { default as commandesbxisnwXOheMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/travaux/mt/[id]/commandes.vue?macro=true";
import { default as indextX1WtVN9DxMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/travaux/mt/[id]/index.vue?macro=true";
import { default as marchesSXmuBsT0wQMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/travaux/mt/[id]/marches.vue?macro=true";
import { default as _91id_93ktkCO77EMRMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/travaux/mt/[id].vue?macro=true";
import { default as indexHLrnrP0KlTMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/travaux/mt/index.vue?macro=true";
import { default as mtvnTOAkV1jDMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/travaux/mt.vue?macro=true";
import { default as suiviIEHYxclLDuMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/travaux/suivi.vue?macro=true";
import { default as travauxVFJJSQHwvJMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/travaux.vue?macro=true";
import { default as _91workspace_93fK95EgRCqnMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/[workspace].vue?macro=true";
import { default as forgot_45passwordXtpFf0NvLvMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/forgot-password.vue?macro=true";
import { default as index2kz8pE2NYfMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/index.vue?macro=true";
import { default as loginuFPUG0ObeWMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/login.vue?macro=true";
import { default as reset_45password6d5n5oQ7trMeta } from "/home/runner/work/briik-frontend/briik-frontend/pages/reset-password.vue?macro=true";
export default [
  {
    name: _91workspace_93fK95EgRCqnMeta?.name,
    path: "/:workspace()",
    meta: _91workspace_93fK95EgRCqnMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace].vue"),
    children: [
  {
    name: cataloguesxAdyvStKXHMeta?.name,
    path: "catalogues",
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/catalogues.vue"),
    children: [
  {
    name: "workspace-catalogues-annonces",
    path: "annonces",
    meta: annoncesO8F1SKExWRMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/catalogues/annonces.vue")
  },
  {
    name: articlesJshotVqKYRMeta?.name,
    path: "articles",
    meta: articlesJshotVqKYRMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/catalogues/articles.vue"),
    children: [
  {
    name: "workspace-catalogues-articles-gammes",
    path: "gammes",
    meta: gammesHYVN0KaTo0Meta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/catalogues/articles/gammes.vue")
  },
  {
    name: "workspace-catalogues-articles",
    path: "",
    meta: indexxQJErEoGspMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/catalogues/articles/index.vue")
  },
  {
    name: "workspace-catalogues-articles-lots",
    path: "lots",
    meta: lotszlhoYdYIdjMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/catalogues/articles/lots.vue")
  },
  {
    name: "workspace-catalogues-articles-types_articles",
    path: "types_articles",
    meta: types_articlesJ23ZBq3pv3Meta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/catalogues/articles/types_articles.vue")
  }
]
  },
  {
    name: constructionsOKcR2lCOROMeta?.name,
    path: "constructions",
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/catalogues/constructions.vue"),
    children: [
  {
    name: "workspace-catalogues-constructions-gammes",
    path: "gammes",
    meta: gammes51D7Dd6tWmMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/catalogues/constructions/gammes.vue")
  },
  {
    name: "workspace-catalogues-constructions",
    path: "",
    meta: indexeIhQ7fLAeuMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/catalogues/constructions/index.vue")
  },
  {
    name: modelesigEabaXtpyMeta?.name,
    path: "modeles",
    meta: modelesigEabaXtpyMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/catalogues/constructions/modeles.vue"),
    children: [
  {
    name: "workspace-catalogues-constructions-modeles",
    path: "",
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/catalogues/constructions/modeles/index.vue")
  },
  {
    name: _91id_93GIFDTs0EigMeta?.name,
    path: "versions/:id()",
    meta: _91id_93GIFDTs0EigMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/catalogues/constructions/modeles/versions/[id].vue"),
    children: [
  {
    name: "workspace-catalogues-constructions-modeles-versions-id-documents",
    path: "documents",
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/catalogues/constructions/modeles/versions/[id]/documents.vue")
  },
  {
    name: "workspace-catalogues-constructions-modeles-versions-id",
    path: "",
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/catalogues/constructions/modeles/versions/[id]/index.vue")
  },
  {
    name: "workspace-catalogues-constructions-modeles-versions-id-medias",
    path: "medias",
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/catalogues/constructions/modeles/versions/[id]/medias.vue")
  }
]
  }
]
  }
]
  },
  {
    name: "workspace-catalogues",
    path: "",
    meta: indexrksTiY8VS1Meta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/catalogues/index.vue")
  },
  {
    name: metresAAvZFzdopgMeta?.name,
    path: "metres",
    meta: metresAAvZFzdopgMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/catalogues/metres.vue"),
    children: [
  {
    name: _91id_93eWeS43ZIEtMeta?.name,
    path: ":id()",
    meta: _91id_93eWeS43ZIEtMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/catalogues/metres/[id].vue"),
    children: [
  {
    name: "workspace-catalogues-metres-id",
    path: "",
    meta: indexukMDlnfRQAMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/catalogues/metres/[id]/index.vue")
  },
  {
    name: "workspace-catalogues-metres-id-lignes",
    path: "lignes",
    meta: lignesdCQe8scWHKMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/catalogues/metres/[id]/lignes.vue")
  },
  {
    name: "workspace-catalogues-metres-id-options",
    path: "options",
    meta: optionsWuOC1oeKrPMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/catalogues/metres/[id]/options.vue")
  },
  {
    name: "workspace-catalogues-metres-id-parametres",
    path: "parametres",
    meta: parametreseB6B0jKdcRMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/catalogues/metres/[id]/parametres.vue")
  }
]
  },
  {
    name: "workspace-catalogues-metres",
    path: "",
    meta: indexd7oFqwC93AMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/catalogues/metres/index.vue")
  }
]
  },
  {
    name: "workspace-catalogues-terrains",
    path: "terrains",
    meta: terrainsZvHKlpwncgMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/catalogues/terrains.vue")
  }
]
  },
  {
    name: contactswTZU2qRwzwMeta?.name,
    path: "contacts",
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/contacts.vue"),
    children: [
  {
    name: clientsKcOBmFUY5oMeta?.name,
    path: "clients",
    meta: clientsKcOBmFUY5oMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/contacts/clients.vue"),
    children: [
  {
    name: "workspace-contacts-clients-id",
    path: ":id()",
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/contacts/clients/[id].vue")
  },
  {
    name: "workspace-contacts-clients",
    path: "",
    meta: indexgNhnMYBICRMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/contacts/clients/index.vue")
  }
]
  },
  {
    name: "workspace-contacts",
    path: "",
    meta: indexqqAf2IvxHtMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/contacts/index.vue")
  },
  {
    name: ssttfours8uXRg3ZIAqMeta?.name,
    path: "ssttfours",
    meta: ssttfours8uXRg3ZIAqMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/contacts/ssttfours.vue"),
    children: [
  {
    name: "workspace-contacts-ssttfours",
    path: "",
    meta: index1iSIDKRgqwMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/contacts/ssttfours/index.vue")
  }
]
  }
]
  },
  {
    name: "workspace-dashboard",
    path: "dashboard",
    meta: dashboardTaMIvq2vRzMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/dashboard.vue")
  },
  {
    name: "workspace-documents",
    path: "documents",
    meta: documentsCpw5y3wQ9QMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/documents.vue")
  },
  {
    name: gesfieNSDIq6ibOMeta?.name,
    path: "gesfi",
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/gesfi.vue"),
    children: [
  {
    name: achatstqYYmjTyp6Meta?.name,
    path: "achats",
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/gesfi/achats.vue"),
    children: [
  {
    name: "workspace-gesfi-achats-commandes",
    path: "commandes",
    meta: commandeshcC5rn2NrWMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/gesfi/achats/commandes.vue")
  },
  {
    name: "workspace-gesfi-achats-factures",
    path: "factures",
    meta: facturesP1t9wkekCnMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/gesfi/achats/factures.vue")
  },
  {
    name: "workspace-gesfi-achats",
    path: "",
    meta: indexx2M0wX3f0TMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/gesfi/achats/index.vue")
  },
  {
    name: "workspace-gesfi-achats-reglements",
    path: "reglements",
    meta: reglementsOp2tUKly7LMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/gesfi/achats/reglements.vue")
  }
]
  },
  {
    name: dashboardsyz2i4FvSNEMeta?.name,
    path: "dashboards",
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/gesfi/dashboards.vue"),
    children: [
  {
    name: "workspace-gesfi-dashboards-facturation",
    path: "facturation",
    meta: facturationKDoBxOeGlNMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/gesfi/dashboards/facturation.vue")
  },
  {
    name: "workspace-gesfi-dashboards",
    path: "",
    meta: indexnbCOREYYaWMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/gesfi/dashboards/index.vue")
  },
  {
    name: "workspace-gesfi-dashboards-ventes",
    path: "ventes",
    meta: ventesC6MvpdPbxQMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/gesfi/dashboards/ventes.vue")
  }
]
  },
  {
    name: "workspace-gesfi-exports",
    path: "exports",
    meta: exportsPHhjnSQTYXMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/gesfi/exports.vue")
  },
  {
    name: "workspace-gesfi",
    path: "",
    meta: indexLhO9bnHsZCMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/gesfi/index.vue")
  },
  {
    name: ventesXPn6gNMZ9FMeta?.name,
    path: "ventes",
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/gesfi/ventes.vue"),
    children: [
  {
    name: "workspace-gesfi-ventes",
    path: "",
    meta: indexzW0CSx6Rj6Meta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/gesfi/ventes/index.vue")
  },
  {
    name: "workspace-gesfi-ventes-suivifactu",
    path: "suivifactu",
    meta: suivifacturwnJ4u36oNMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/gesfi/ventes/suivifactu.vue")
  }
]
  }
]
  },
  {
    name: "workspace",
    path: "",
    meta: indexNN5eZr8bfMMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/index.vue")
  },
  {
    name: "workspace-metres",
    path: "metres",
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/metres.vue")
  },
  {
    name: parametresbB71w0WuaiMeta?.name,
    path: "parametres",
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/parametres.vue"),
    children: [
  {
    name: "workspace-parametres-diffusion",
    path: "diffusion",
    meta: diffusionMoACp3PVGHMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/parametres/diffusion.vue"),
    children: [
  {
    name: "workspace-parametres-diffusion-annonces",
    path: "annonces",
    meta: annonces9tJFGRlHqMMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/parametres/diffusion/annonces.vue")
  },
  {
    name: "workspace-parametres-diffusion-ubiflow",
    path: "ubiflow",
    meta: ubiflowfo7o6l5ykfMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/parametres/diffusion/ubiflow.vue")
  }
]
  },
  {
    name: documentsi6sx3k3KAVMeta?.name,
    path: "documents",
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/parametres/documents.vue"),
    children: [
  {
    name: "workspace-parametres-documents",
    path: "",
    meta: indexwDcpvERNlaMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/parametres/documents/index.vue")
  },
  {
    name: "workspace-parametres-documents-signature",
    path: "signature",
    meta: signaturevUdNRxUNRcMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/parametres/documents/signature.vue")
  },
  {
    name: "workspace-parametres-documents-types",
    path: "types",
    meta: typesU6qbibQJdkMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/parametres/documents/types.vue")
  }
]
  },
  {
    name: editionsKydCc7Z3ghMeta?.name,
    path: "editions",
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/parametres/editions.vue"),
    children: [
  {
    name: "workspace-parametres-editions-general",
    path: "general",
    meta: generalVgckX4WZi0Meta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/parametres/editions/general.vue")
  },
  {
    name: "workspace-parametres-editions",
    path: "",
    meta: indexJ7kwVYMllHMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/parametres/editions/index.vue")
  },
  {
    name: "workspace-parametres-editions-notices",
    path: "notices",
    meta: noticesY0hwAITtwwMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/parametres/editions/notices.vue")
  },
  {
    name: "workspace-parametres-editions-ppcst",
    path: "ppcst",
    meta: ppcstxY2NSSAl5HMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/parametres/editions/ppcst.vue")
  }
]
  },
  {
    name: financiersP1KoDU80jQMeta?.name,
    path: "financiers",
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/parametres/financiers.vue"),
    children: [
  {
    name: "workspace-parametres-financiers-affectations",
    path: "affectations",
    meta: affectations0UxGt7HNjNMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/parametres/financiers/affectations.vue")
  },
  {
    name: "workspace-parametres-financiers-banque",
    path: "banque",
    meta: banqueQaHHhdu3PvMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/parametres/financiers/banque.vue")
  },
  {
    name: "workspace-parametres-financiers-facturation",
    path: "facturation",
    meta: facturationG3jRR88r4AMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/parametres/financiers/facturation.vue")
  },
  {
    name: "workspace-parametres-financiers",
    path: "",
    meta: indexkfAeEk7JJ5Meta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/parametres/financiers/index.vue")
  },
  {
    name: "workspace-parametres-financiers-validations",
    path: "validations",
    meta: validationsOjnrXbbgVnMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/parametres/financiers/validations.vue")
  }
]
  },
  {
    name: "workspace-parametres-garants",
    path: "garants",
    meta: garantsMR4JejqKM0Meta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/parametres/garants.vue")
  },
  {
    name: "workspace-parametres",
    path: "",
    meta: indexAyNhb7zJpPMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/parametres/index.vue")
  },
  {
    name: "workspace-parametres-marque",
    path: "marque",
    meta: marqueFjofStgbFcMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/parametres/marque.vue")
  },
  {
    name: "workspace-parametres-secteurs",
    path: "secteurs",
    meta: secteursFji9B1a2PCMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/parametres/secteurs.vue")
  },
  {
    name: "workspace-parametres-utilisateurs",
    path: "utilisateurs",
    meta: utilisateurs9Vq68CXgbgMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/parametres/utilisateurs.vue")
  },
  {
    name: "workspace-parametres-vente",
    path: "vente",
    meta: ventemJztgtXWQqMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/parametres/vente.vue")
  }
]
  },
  {
    name: projetsifgIhsTfYvMeta?.name,
    path: "projets",
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets.vue"),
    children: [
  {
    name: _91id_93OxT4ttmiBYMeta?.name,
    path: ":id()",
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id].vue"),
    children: [
  {
    name: avpDxGUclgrmzMeta?.name,
    path: "avp",
    meta: avpDxGUclgrmzMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/avp.vue"),
    children: [
  {
    name: "workspace-projets-id-avp",
    path: "",
    meta: indexCa5hfDZvVOMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/avp/index.vue")
  },
  {
    name: "workspace-projets-id-avp-metre-mid",
    path: "metre/:mid()",
    meta: _91mid_935hSeTo7nViMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/avp/metre/[mid].vue"),
    children: [
  {
    name: "workspace-projets-id-avp-metre-mid-lignes",
    path: "lignes",
    meta: lignesM5a1SuF8L1Meta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/avp/metre/[mid]/lignes.vue")
  },
  {
    name: "workspace-projets-id-avp-metre-mid-options",
    path: "options",
    meta: options8ytRSCrWE4Meta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/avp/metre/[mid]/options.vue")
  }
]
  }
]
  },
  {
    name: contratTfhomkh4mKMeta?.name,
    path: "contrat",
    meta: contratTfhomkh4mKMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/contrat.vue"),
    children: [
  {
    name: "workspace-projets-id-contrat-analytics",
    path: "analytics",
    meta: analyticsucy1XNbttvMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/contrat/analytics.vue")
  },
  {
    name: "workspace-projets-id-contrat-avenants",
    path: "avenants",
    meta: avenants1KYhGQw2snMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/contrat/avenants.vue")
  },
  {
    name: "workspace-projets-id-contrat-facturation",
    path: "facturation",
    meta: facturationunB4pPT9tmMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/contrat/facturation.vue")
  },
  {
    name: "workspace-projets-id-contrat",
    path: "",
    meta: indexXUd4txpQiqMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/contrat/index.vue")
  }
]
  },
  {
    name: "workspace-projets-id",
    path: "",
    meta: indexh2bQKPkxrDMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/index.vue")
  },
  {
    name: "workspace-projets-id-pp",
    path: "pp",
    meta: pp7jCLnVMBFJMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/pp.vue"),
    children: [
  {
    name: "workspace-projets-id-pp-ci",
    path: "ci",
    meta: ciHPBAK0bZFOMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/pp/ci.vue")
  },
  {
    name: "workspace-projets-id-pp-epe",
    path: "epe",
    meta: epeoZoJw7ee3YMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/pp/epe.vue")
  },
  {
    name: "workspace-projets-id-pp-financement",
    path: "financement",
    meta: financementMAK23nfyjEMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/pp/financement.vue")
  },
  {
    name: "workspace-projets-id-pp-garanties",
    path: "garanties",
    meta: garantiesxCUlCsWrVtMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/pp/garanties.vue")
  },
  {
    name: "workspace-projets-id-pp-pc",
    path: "pc",
    meta: pcaN9gH8Vu8YMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/pp/pc.vue")
  },
  {
    name: "workspace-projets-id-pp-prepaschantiers",
    path: "prepaschantiers",
    meta: prepaschantiersfaqvEwnBHbMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/pp/prepaschantiers.vue")
  },
  {
    name: "workspace-projets-id-pp-terrain",
    path: "terrain",
    meta: terrainZygnRfiabgMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/pp/terrain.vue")
  }
]
  },
  {
    name: "workspace-projets-id-sav",
    path: "sav",
    meta: indexRxf5inxd4ZMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/sav/index.vue")
  },
  {
    name: travaux2pXkcNyAOmMeta?.name,
    path: "travaux",
    meta: travaux2pXkcNyAOmMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/travaux.vue"),
    children: [
  {
    name: cmdTWyhTaufYZMeta?.name,
    path: "cmd",
    meta: cmdTWyhTaufYZMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/travaux/cmd.vue"),
    children: [
  {
    name: _91cid_93Yly8m0MhzHMeta?.name,
    path: ":cid()",
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/travaux/cmd/[cid].vue"),
    children: [
  {
    name: "workspace-projets-id-travaux-cmd-cid",
    path: "",
    meta: indexoXS7VpquI3Meta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/travaux/cmd/[cid]/index.vue")
  }
]
  },
  {
    name: "workspace-projets-id-travaux-cmd",
    path: "",
    meta: indexlfYZPies1RMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/travaux/cmd/index.vue")
  }
]
  },
  {
    name: "workspace-projets-id-travaux-documents",
    path: "documents",
    meta: documentskK1svMRntSMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/travaux/documents.vue")
  },
  {
    name: "workspace-projets-id-travaux",
    path: "",
    meta: indexDY6gB5ITl2Meta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/travaux/index.vue")
  },
  {
    name: "workspace-projets-id-travaux-planification",
    path: "planification",
    meta: planificationgpvU9z9L98Meta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/[id]/travaux/planification.vue")
  }
]
  }
]
  },
  {
    name: "workspace-projets",
    path: "",
    meta: indexPrlvpykgJMMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/projets/index.vue")
  }
]
  },
  {
    name: travauxVFJJSQHwvJMeta?.name,
    path: "travaux",
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/travaux.vue"),
    children: [
  {
    name: "workspace-travaux",
    path: "",
    meta: indexL4SC2lCbVJMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/travaux/index.vue")
  },
  {
    name: mtvnTOAkV1jDMeta?.name,
    path: "mt",
    meta: mtvnTOAkV1jDMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/travaux/mt.vue"),
    children: [
  {
    name: _91id_93ktkCO77EMRMeta?.name,
    path: ":id()",
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/travaux/mt/[id].vue"),
    children: [
  {
    name: "workspace-travaux-mt-id-commandes",
    path: "commandes",
    meta: commandesbxisnwXOheMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/travaux/mt/[id]/commandes.vue")
  },
  {
    name: "workspace-travaux-mt-id",
    path: "",
    meta: indextX1WtVN9DxMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/travaux/mt/[id]/index.vue")
  },
  {
    name: "workspace-travaux-mt-id-marches",
    path: "marches",
    meta: marchesSXmuBsT0wQMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/travaux/mt/[id]/marches.vue")
  }
]
  },
  {
    name: "workspace-travaux-mt",
    path: "",
    meta: indexHLrnrP0KlTMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/travaux/mt/index.vue")
  }
]
  },
  {
    name: "workspace-travaux-suivi",
    path: "suivi",
    meta: suiviIEHYxclLDuMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/[workspace]/travaux/suivi.vue")
  }
]
  }
]
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordXtpFf0NvLvMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/forgot-password.vue")
  },
  {
    name: "index",
    path: "/",
    meta: index2kz8pE2NYfMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginuFPUG0ObeWMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/login.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45password6d5n5oQ7trMeta || {},
    component: () => import("/home/runner/work/briik-frontend/briik-frontend/pages/reset-password.vue")
  }
]