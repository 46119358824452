import { defineStore } from 'pinia';
import { useProjetsStore } from './projets';
import type { Chantier } from '~/types/models/chantier';
import type { User } from '~/types/models/user';
import type { Projet } from '~/types/models/projet';
import { useToast } from 'vue-toastification';
import { DocumentCategory } from '~/types/Enums';

export const useChantiersStore = defineStore('chantiers', {
  state: () => ({
    chantier: null as Chantier | null,
    conducteurs: [] as User[],
  }),
  getters: {
    currentProjet: (): Projet | null => {
      return useProjetsStore().currentProjet;
    },
  },
  actions: {
    async fetch() {
      if (this.currentProjet?.chantier?.id) {
        const res = await useApi().chantiers.findOne(this.currentProjet.chantier.id);
        if (res && res.data) {
          this.chantier = res.data;
        }
      }
    },

    async fetchConducteurs() {
      this.conducteurs = await useApi().users.findByRoles([
        useConstants.roles.CS_CONDUC,
        useConstants.roles.CS_CONDUC_EXT,
      ]);
    },

    async create(chantier: Creatable<Chantier>) {
      const res = await useApi().chantiers.create(chantier);
      if (res && res.data) {
        this.chantier = res.data;
      }
    },

    async update(chantier: Updatable<Chantier>) {
      const res = await useApi().chantiers.update(chantier);
      if (res && res.data) {
        this.chantier = res.data;
      }
      await useProjetsStore().fetchJalons();
    },

    async refresh() {
      if (this.chantier) {
        await this.fetch();
        await useProjetsStore().fetchJalons();
      }
    },

    async uploadFile(file: File, typeName: string) {
      if (!this.chantier) {
        useToast().error("Le chantier n'est pas défini");
        return;
      }
      if (!file) {
        useToast().error('Aucun fichier sélectionné');
        return;
      }

      const doc = await useUploadDocToEntity(file, DocumentCategory.CHANTIER, typeName, [
        { key: 'chantier', value: this.chantier.id },
      ]);

      if (!doc) {
        useToast().error(`Une erreur est survenue lors de l'enregistrement du fichier.`);
        console.error("Erreur lors de l'enregistrement du fichier", typeName);
        return;
      }

      if (!this.chantier.documents) {
        this.chantier.documents = [];
      }
      this.chantier.documents.push(doc);
    },

    reset() {
      this.chantier = null;
    },
  },
});
