import type { Sav } from '~/types/models/sav';
import type { ApiClient, GenericApi } from '~~/composables/useApiFactory';

const entityName = 'savs';
const populate = {
  documents: { populate: { media: true, signature: true } },
};
const searchFields: string[] = [];

export default (api: ApiClient) => {
  const theApi = useApiFactory(api, entityName, searchFields, populate) as GenericApi<Sav>;

  return theApi;
};
