import type { Terrain } from '~/types/models/terrain';
import type { FiltersValues } from '~/types/TableTypes';
import type { ApiClient, GenericApi } from '~~/composables/useApiFactory';

const entityName = 'terrains';

const populate = {
  agence: true,
  commercial: true,
  secteur: true,
  documents: { populate: { media: true, signature: true } },
  versions: { populate: { modele: true } },
};

const searchFields = ['reference', 'adresse_commune', 'statut', 'secteur.nom'];

type Api = GenericApi<Terrain> & {
  getFilters: () => Promise<FiltersValues>;
};

export default (api: ApiClient) => {
  const theApi = useApiFactory(api, entityName, searchFields, populate) as Api;

  theApi.getFilters = async () => {
    let res;
    try {
      res = await api(`${entityName}/filters`, {});
    } catch (error) {
      console.error(error);
      res = null;
    }
    return res as FiltersValues;
  };

  return theApi;
};
