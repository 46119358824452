import { defineStore } from 'pinia';
import { useMetreLignesStore } from './metreLignes';
import { MetreStatus, ParametreKeys, TypeNotice } from '~~/types/Enums';
import { DateTime } from 'luxon';
import { useProjetsStore } from './projets';
import { useParamValue } from '~/composables/workspaces/parametres/useParamValue';
import { useTvaValues } from '~/composables/workspaces/parametres/useTvaValues';
import { useCurrentProjet } from '~/composables/projets/useCurrentProjet';

const emptyMetre = () => ({
  avp: { projet: null },
  type: 'Libre',
  historique: '[]',
  status: MetreStatus.EN_COURS,
  marge: parseFloat(useParamValue(ParametreKeys.MARGE_METRE_DEFAULT)),
  metreur: null,
  tva: parseFloat(useParamValue(ParametreKeys.TAUX_TVA)),
  multiTva: useTvaValues().length > 1,
});

export const useMetresStore = defineStore({
  id: 'metres-store',
  state: () => {
    return {
      collection: [],
      editedMetre: undefined,
      page: 1,
      pageCount: 1,
      sortField: 'createdAt',
      sortDirection: ':desc',
      query: '',
      currentMetre: null,
      api: useApi().metres,
    };
  },
  actions: {
    setMetaSortPage({ page = 1, direction = '', sortField = '' }) {
      this.page = page;
      if (direction) this.sortDirection = direction;
      if (sortField) this.sortField = sortField;
    },

    setQuery(query) {
      this.page = 1;
      this.query = query;
    },
    setEditedMetre(metre) {
      this.editedMetre = metre;
    },

    newMetre() {
      this.setEditedMetre(emptyMetre());
    },

    setCurrentMetre(metre) {
      this.currentMetre = metre;
      useMetreLignesStore().resetGroupLabels();
    },

    async fetchOne(id) {
      const res = await this.api.findOne(id);
      if (res && res.data) {
        this.setCurrentMetre(res.data);
      }
      return res;
    },

    async create(item) {
      return await this.api.create(item);
    },

    async update(item) {
      return await this.api.update(item);
    },

    async delete(item) {
      return await this.api.delete(item);
    },

    async mergeOptions(optionsIds, metreId, isMajor = false) {
      const mid = metreId || this.currentMetre?.id;
      if (!mid) {
        console.error('mergeOptions: aucun id metre fourni');
        return null;
      }
      return await this.api.mergeOptions(mid, optionsIds, isMajor);
    },

    async validateMetre(metreId) {
      return await this.api.validateMetre(metreId);
    },

    async refreshCurrent() {
      if (!this.currentMetre) return;
      await this.fetchOne(this.currentMetre.id);
    },

    async moveTo(metre, status, { action = '', date = DateTime.now().toISO(), version = '' }) {
      if (!metre) return;
      metre.status = status;
      if (action) {
        await this.addHistory(metre, action, version, date);
      }
      await this.api.update({ id: metre.id, status: metre.status }, {});
      await this.refreshCurrent();
    },

    async fixMetre(metreId) {
      return await this.api.fixMetre(metreId);
    },

    async freeMetre(metreId) {
      return await this.api.freeMetre(metreId);
    },

    async freeCurrentMetre(majPrix) {
      const isFp = useProjetsStore().projetAvancementIs(
        useProjetsStore().currentProjet,
        '<',
        useConstants.avancements.ND_DEMANDEE,
      );

      const avpDatas = {
        id: this.currentMetre.avp.id,
      };

      if (isFp) {
        avpDatas.date_edition_fp = null;
        avpDatas.date_validite_fp = null;
        avpDatas.date_presentation_fp = null;
      } else {
        avpDatas.date_edition_nd = null;
        avpDatas.date_validite_nd = null;
        avpDatas.date_presentation_nd = null;
      }

      let futurStatutProjet = useProjetsStore().projetAvancementIs(
        useProjetsStore().currentProjet,
        '>=',
        useConstants.avancements.ND_DEMANDEE,
      )
        ? useConstants.avancements.ND_EN_COURS
        : useConstants.avancements.FP_EN_COURS;

      await this.moveTo(this.currentMetre, MetreStatus.EN_COURS, {
        action: 'Dévérouillage du métré',
        version: this.currentMetre.avp.version,
      });

      await useProjetsStore().moveTo(this.currentProjet.id, futurStatutProjet);

      await useApi().avps.update(avpDatas, {});

      if (majPrix) await this.freeMetre(this.currentMetre.id);

      await this.refreshCurrent();
    },

    async updateMetreMarge(metre, marge) {
      await this.api.updateMarge(metre.id, marge);
    },

    reset() {
      this.currentMetre = null;
    },

    async addHistory(metre, action, version, date) {
      if (!metre) return;
      metre.historique = JSON.stringify([
        ...JSON.parse(metre.historique),
        {
          action,
          version,
          date,
        },
      ]);
      await this.api.update({ id: metre.id, historique: metre.historique }, {});
    },
  },
  getters: {
    currentProjet: (state) => {
      return useCurrentProjet().value;
    },

    getFullSortField: (state) => (state.sortField ? state.sortField + state.sortDirection : ''),

    isEditable: (state) => (metre) => {
      return metre?.status === MetreStatus.EN_COURS;
    },

    noticeIsDraft: (state) => (projet, type) => {
      const pStore = useProjetsStore();
      switch (type) {
        case TypeNotice.FP:
          return pStore.projetAvancementIs(projet, '<', useConstants.avancements.FP_DISPONIBLE);
        case TypeNotice.NDD:
        case TypeNotice.NDT:
          return pStore.projetAvancementIs(projet, '<', useConstants.avancements.ND_DISPONIBLE);
      }
    },

    metreIsArchived: (state) => (metre) => {
      return metre.status === MetreStatus.ARCHIVE;
    },
  },
});
