<template>
  <Listbox ref="listBox" as="div" v-model="selectedItem" class="relative min-w-20" :by="compareFn">
    <input
      type="text"
      class="pointer-events-none absolute inset-0 opacity-0"
      :value="selectedItem"
      :required="required"
      @select="open = false" />

    <div class="relative" ref="listContainer">
      <ListboxButton
        class="relative flex w-1 min-w-full cursor-default rounded-md border border-skin-border-dark bg-white pr-9 text-left shadow-sm focus:border-skin-accent focus:border-opacity-100 focus:outline-1 focus:outline-skin-accent focus-visible:outline-1 sm:text-sm"
        :class="[
          { 'opacity-40': listBox && listBox.$props.disabled },
          small ? 'py-1.5 pl-2 pr-2' : 'py-2 pl-3 pr-10',
        ]"
        ref="listButton"
        @click="open = !open">
        <div v-if="search" class="min-w-0 flex-1">
          <input
            v-model="query"
            type="text"
            class="text-md w-full border-none py-0 outline-none ring-0 focus:ring-0"
            placeholder="Rechercher"
            @click.stop />
        </div>
        <div v-else class="min-w-0 flex-1" :title="selectedLabel(selectedItem)">
          <div class="truncate">
            <span
              :class="{
                'text-skin-muted': !isItemSelected,
                'first-letter:uppercase': capitalize,
                uppercase: uppercase,
              }"
              v-html="selectedLabel(selectedItem)"></span>
          </div>
        </div>
        <span class="absolute inset-y-0 right-0 flex items-center pr-2">
          <ChevronUpDownIcon class="h-5 w-5 cursor-pointer text-skin-heading" aria-hidden="true" />
        </span>
      </ListboxButton>

      <transition
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
        v-if="open">
        <ListboxOptions
          static
          ref="selectDropdown"
          class="z-dropdown mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          :class="[strategy === 'absolute' ? 'absolute' : 'fixed']"
          :style="[dropDownPositionStyle]">
          <div v-for="group in optionsGroups" :key="group.id">
            <div
              v-if="group.id !== -1"
              class="border-b border-skin-border-dark px-3 pb-2 pt-4 text-skin-muted">
              {{ group.name }}
            </div>
            <ListboxOption
              as="template"
              v-for="(item, index) in group.items"
              :key="index"
              :value="item"
              v-slot="{ active, selected }"
              :disabled="itemIsDisabled(item)">
              <div
                :class="[
                  active ? 'active bg-skin-accent text-white' : 'text-skin-base',
                  'relative cursor-default select-none ',
                  small ? 'py-1.5 pl-8 pr-2' : 'py-2 pl-8 pr-4',
                  itemIsDisabled(item) ? 'opacity-40' : '',
                ]"
                :title="itemLabel(item)"
                :style="{ 'min-width': listContainerBound.width.value + 'px' }">
                <span
                  :class="[
                    selected ? 'font-medium' : 'font-normal',
                    'w-content block truncate',
                    capitalize ? 'first-letter:uppercase' : '',
                    uppercase ? 'uppercase' : '',
                  ]">
                  <slot name="label" :item="item" :active="active" :selected="selected">
                    <span v-html="itemLabel(item)"></span>
                    <span class="text-skin-danger" v-if="item && item.required">*</span>
                  </slot>
                </span>
                <span
                  v-if="selected"
                  :class="[
                    active ? 'text-white' : 'text-skin-accent',
                    'absolute inset-y-0 left-0 flex items-center pl-1.5',
                  ]">
                  <CheckIcon class="h-5 w-5" aria-hidden="true" />
                </span>
              </div>
            </ListboxOption>
          </div>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script setup lang="ts">
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid';

type Props = {
  items: any[];
  modelValue: any;
  labelProps?: string[];
  labelSeparator?: string;
  required?: boolean;
  idField?: string;
  valueField?: string;
  capitalize?: boolean;
  uppercase?: boolean;
  placeholder?: string;
  small?: boolean;
  labelFn?: (item: any) => string;
  disabledItems?: any[];
  strategy?: 'fixed' | 'absolute';
  search?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  labelProps: () => ['name'],
  required: false,
  idField: 'id',
  capitalize: false,
  uppercase: false,
  small: false,
  labelFn: (item: any) => item,
  strategy: 'fixed',
  labelSeparator: '-',
});

const emit = defineEmits(['update:modelValue', 'scroll-end']);

const open = defineModel('open', {
  type: Boolean,
  default: false,
});

const listBox = ref();

const listContainer = ref();

const listContainerBound = useElementBounding(listContainer);

onClickOutside(listContainer, () => {
  open.value = false;
});

const listButton = ref();

const listButtonBound = useElementBounding(listButton);

const query = defineModel('query', {
  type: String,
  default: '',
});

const optionsGroups = computed(() => {
  return (
    props.items?.reduce((acc: { id: number; name: string; items: any[] }[], item: any) => {
      const group = item && item.hasOwnProperty('group') ? item.group : { id: -1, name: 'none' };
      if (!group.hasOwnProperty('id')) group.id = -1;
      if (!group.hasOwnProperty('name')) group.name = 'none';
      if (!acc.find((g) => g.id === group.id))
        acc.push({ id: group.id, name: group.name, items: [] });
      const groupIndex = acc.findIndex((g) => g.id === group.id);
      acc[groupIndex].items.push(item);
      return acc;
    }, []) || []
  );
});

const isItemSelected = computed(() => {
  return selectedItem.value !== null && selectedItem.value !== undefined;
});

const selectedItem = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    if (props.modelValue != null && value != null) {
      if (typeof value !== 'string' && value[props.idField] === props.modelValue[props.idField])
        return;
    }
    const valueToEmit =
      typeof value !== 'string' && props.valueField && value != null
        ? value[props.valueField]
        : value;

    open.value = false;

    emit('update:modelValue', valueToEmit);
  },
});

watch(selectedItem, () => {
  if (!selectedItem.value) {
    open.value = false;
  }
});

const selectedLabel = (item: any) => {
  let itemToLabel = item;
  if (item !== null && item !== undefined) {
    if (typeof item !== 'string') {
      if (props.valueField)
        itemToLabel = props.items.find((i) => {
          return i && props.valueField && i[props.valueField] === item;
        });
      else
        itemToLabel = props.items.find((i) => {
          const itemId = typeof item === 'object' ? item[props.idField] : item;
          return i && i[props.idField]?.toString() === itemId?.toString();
        });
    } else {
      itemToLabel = props.items.find(
        (i) =>
          (i !== null && typeof i === 'object' && i[props.idField]?.toString() === item) ||
          i === item,
      );
    }
  }

  const label = getLabel(itemToLabel, props.placeholder || 'Sélectionnez une valeur');

  return label;
};

const itemLabel = (item: any) => {
  return getLabel(item, 'Aucun');
};

const getLabel = (item: any, placeholder: string) => {
  if (typeof item === 'string') return (props.labelFn ? props.labelFn(item) : item) || placeholder;

  const res = item
    ? useUtils.combinedExtractedPropertiesValue(item, props.labelProps, props.labelSeparator)
    : placeholder;

  return props.labelFn ? props.labelFn(res) : res;
};

const compareFn = (a: any, b: any) => {
  if (typeof a === 'number') a = a.toString();
  if (typeof b === 'number') b = b.toString();
  if (a === null && b === null) return true;
  if (a === null || b === null) return false;

  if (typeof a === 'string' && typeof b === 'string') return a === b;

  if (typeof a === 'string' && typeof b === 'object')
    return b && a === b[props.idField]?.toString();

  if (typeof a === 'object' && typeof b === 'string') return a && a[props.idField].toString() === b;

  return a && b && a[props.idField] === b[props.idField];
};

const selectDropdown = ref();
const scrollThreshold = 100; // Adjust this value as needed
const dropdownEl = computed(() => selectDropdown.value?.$el);

const { y, isScrolling } = useScroll(dropdownEl);
const { height } = useElementBounding(dropdownEl);

const dropDownTop = computed(() => {
  return (props.strategy === 'fixed' ? listButtonBound.y.value : 0) + listButtonBound.height.value;
});

const dropDownIsOutsideScreenBottom = computed(() => {
  return dropDownTop.value + height.value > window.innerHeight;
});

const dropDownPositionStyle = computed(() => {
  if (dropDownIsOutsideScreenBottom.value) {
    return {
      bottom: 5 + 'px',
    };
  }
  return {
    top: dropDownTop.value + 'px',
  };
});

const scrollEndSent = ref(false);

const handleScroll = () => {
  if (isScrolling.value) {
    const remaining = dropdownEl.value.scrollHeight - y.value - height.value;
    if (remaining < scrollThreshold) {
      if (!scrollEndSent.value) {
        scrollEndSent.value = true;
        emit('scroll-end');
      }
    }
    if (remaining > scrollThreshold) {
      scrollEndSent.value = false;
    }
  }
};

watch(() => y.value, handleScroll);

const itemIsDisabled = (item: any) => {
  return props.disabledItems?.find((i) => {
    return compareFn(i, item);
  });
};
</script>
