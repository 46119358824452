import { defineStore } from 'pinia';
import { useProjetsStore } from './projets';
import type { Financement } from '~~/types/models/financement';
import { DocumentCategory } from '~/types/Enums';
import { DateTime } from 'luxon';
import { DocumentType } from '~/documents/types';

export const useFinancementsStore = defineStore('financements', {
  state: () => ({
    financement: null as Financement | null,
  }),
  getters: {
    currentProjet: (state): any => {
      return useProjetsStore().currentProjet;
    },

    missingDocuments: (state) => {
      const mandatoryDocs = useDocumentTypes()[DocumentCategory.FINANCEMENT].filter(
        (d) => d.required,
      );

      const mdrDocTypes = mandatoryDocs.filter((d) => {
        if (state.financement?.autofinancement) {
          return ![DocumentType.SIMULATION.name, DocumentType.OFFRE_DE_PRET.name].includes(d.name);
        } else {
          return d.name != DocumentType.JUSTIF_APPORT_PERSONNEL.name;
        }
      });

      const uploadedDocTypes = state.financement?.documents?.map((d) => d.type);

      if (!state.financement?.autofinancement) {
        if (uploadedDocTypes?.includes(DocumentType.SIMULATION.name)) {
          // remove OFFRE_DE_PRET if SIMULATION is uploaded
          mdrDocTypes.splice(
            mdrDocTypes.findIndex((d) => d.name === DocumentType.OFFRE_DE_PRET.name),
            1,
          );
        }
        if (uploadedDocTypes?.includes(DocumentType.OFFRE_DE_PRET.name)) {
          // remove SIMULATION if OFFRE_DE_PRET is uploaded
          mdrDocTypes.splice(
            mdrDocTypes.findIndex((d) => d.name === DocumentType.SIMULATION.name),
            1,
          );
        }
      }

      const missingDocs = mdrDocTypes.filter(
        (d) =>
          !(
            uploadedDocTypes?.includes(d.name) ||
            (d.substitute && !uploadedDocTypes?.includes(d.substitute.name))
          ),
      );

      return missingDocs;
    },

    delaiRetractionEnCours: (state) => {
      if (state.financement?.dateReceptionOffre) {
        const dr = DateTime.fromISO(state.financement.dateReceptionOffre).plus({ days: 10 });
        const now = DateTime.now();
        return now < dr;
      }
      return undefined;
    },
  },
  actions: {
    async fetch() {
      if (this.currentProjet) {
        const res = await useApi().financements.findOne(this.currentProjet.financement.id);
        if (res && res.data) {
          this.financement = res.data;
        }
      }
    },

    async create(financement: Creatable<Financement>) {
      const res = await useApi().financements.create(financement);
      if (res && res.data) {
        this.financement = res.data;
      }
    },

    async update(financement: Updatable<Financement>) {
      const res = await useApi().financements.update(financement);
      if (res && res.data) {
        this.financement = res.data;
      }
      await useProjetsStore().fetchJalons();
    },

    async refresh() {
      if (this.financement) {
        await this.fetch();
        await useProjetsStore().fetchJalons();
      }
    },

    reset() {
      this.financement = null;
    },
  },
});
