import type { DocumentCategory } from '~/types/Enums';
import type { Document } from '~/types/models/document';
import type { Projet, ProjetSummary } from '~/types/models/projet';
import type { SignDestinataire } from '~/types/models/signature';
import type { ApiClient, ApiResult, GenericApi } from '~~/composables/useApiFactory';
import { useQs } from '~~/composables/useQs';
import type { Jalon } from '~~/types/models/jalon';

const entityName = 'projets';

const populate = {
  avancement: { populate: { statut: true } },
  historique: {
    populate: { avancement: true },
    sort: ['createdAt:desc'],
  },
  contrat: {
    populate: {
      avenants: true,
      // {
      //   populate: {
      //     options: { populate: { lignes: { populate: { article: true } } } },
      //     articlesLibres: true,
      //     contrat: true,
      //   },
      // },
      documents: { populate: { media: true, signature: true } },
    },
  },
  clients: {
    populate: {
      agence: true,
    },
  },
  commercial: {
    populate: { agence: true },
  },
  dessinateur: true,
  avps: {
    populate: {
      documents: { populate: { media: true, signature: true } },
      metre: true,

      // {
      //   populate: {
      //     avp: {
      //       populate: {
      //         projet: { populate: { avancement: { populate: { statut: true } } } },
      //       },
      //     },
      //   },
      // },
      // projet: { populate: { avancement: true } },
    },
    sort: ['createdAt:desc'],
  },
  pdc: true,
  financement: true,
  terrain_projet: true,
  epe: true,
  cci: true,
  garantie: {
    populate: {
      garant_gl: true,
      garant_do: true,
    },
  },
  prepachantier: true,
  chantier: {
    populate: {
      conduc: true,
      documents: { populate: { media: true, signature: true } },
    },
  },
  echeancier: true,
  metre_travaux: true,
  visuel: true,
};

const searchFields = ['code', 'adresse_commune', 'adresse_ligne', 'adresse_cp'];

type Api = GenericApi<Projet> & {
  searchProjetForMetreCreation: (query: string) => Promise<any>;
  cancel: (id: number) => Promise<any>;
  getProjetsByClients: (clients: any[]) => Promise<any>;
  getNextProjetNum: (clientsIds: number[]) => Promise<number>;
  getFilters: () => Promise<any>;
  moveTo: (id: number, code: string) => Promise<any>;
  jalons: (id: number) => Promise<Jalon[]>;
  getEligibleProjetForMt: () => Promise<any[]>;
  generateMetreTravaux: (id: number) => Promise<any>;
  getDocuments: (
    id: number,
    categories: DocumentCategory[],
  ) => Promise<ApiResult<Document[] | null>>;
  getPipeCommerciale: () => Promise<ProjetSummary[]>;
  getAvpEnCours: () => Promise<ProjetSummary[]>;
  findSignContact: (
    id: number,
    ssttfours: number[] | boolean,
    withClients?: boolean,
    query?: string,
  ) => Promise<SignDestinataire[]>;
};

export default (api: ApiClient) => {
  const theApi = useApiFactory(api, entityName, searchFields, populate) as Api;

  theApi.searchProjetForMetreCreation = async (query) =>
    await theApi.find(1, '', query, 5, {
      avancement: {
        code: { $in: [useConstants.avancements.CREE, useConstants.avancements.DEMANDE_AVP] },
      },
    });

  theApi.cancel = async (id) => {
    const { $loading } = useNuxtApp();
    let res;
    try {
      const params = {};
      $loading.value = true;
      res = await api(`${entityName}/${id}/cancel?${useQs(params)}`, {
        method: 'PUT',
      });
    } catch (error) {
      res = null;
    }
    $loading.value = false;
    return res;
  };

  theApi.getNextProjetNum = async (clientsIds: number[]) => {
    let res = 1;
    try {
      res = await api(`${entityName}/nextnum/${clientsIds.join('/')}`, {
        method: 'GET',
      });
    } catch (error) {}
    return res;
  };

  theApi.getProjetsByClients = async (clients: any[]) => {
    const clientsIds = clients.map((c) => c.id);
    const filters = { $and: [] as any[] };
    clientsIds.forEach((c) => {
      filters.$and.push({
        clients: {
          id: { $in: [c] },
        },
      });
    });

    const projetsReq = await theApi.find(1, '', '', 9999, filters);

    const projets = projetsReq?.data;

    return projets?.filter((p: any) => p.clients?.length === clients.length) || [];
  };

  theApi.getFilters = async () => {
    let res;
    try {
      res = await api(`${entityName}/filters`, {});
    } catch (error) {
      res = null;
    }
    return res;
  };

  theApi.moveTo = async (id: number, code: string) => {
    let res;
    try {
      res = await api(`${entityName}/${id}/move-to/${code}`, { method: 'PUT' });
    } catch (error) {
      res = null;
    }
    return res;
  };

  theApi.jalons = async (id: number) => {
    let res: Jalon[];
    try {
      res = await api(`${entityName}/${id}/jalons`, { method: 'GET' });
    } catch (error) {
      res = [];
    }
    return res;
  };

  theApi.getEligibleProjetForMt = async () => {
    let res: any[];
    try {
      res = await api(`${entityName}/mt`, { method: 'GET' });
    } catch (error) {
      res = [];
    }
    return res;
  };

  theApi.generateMetreTravaux = async (id: number) => {
    let res;
    try {
      res = await api(`${entityName}/${id}/mt`, { method: 'POST' });
    } catch (error) {
      res = null;
    }
    return res;
  };

  theApi.getDocuments = async (id: number, categories: DocumentCategory[]) => {
    let res;
    try {
      res = await api(`${entityName}/${id}/documents`, {
        method: 'GET',
        params: {
          categories: categories,
        },
      });
    } catch (error) {
      res = null;
    }
    return res as ApiResult<Document[] | null>;
  };

  theApi.getPipeCommerciale = async () => {
    let res: ProjetSummary[] = [];
    try {
      res = await api(`${entityName}/pipe`, { method: 'GET' });
    } catch (error) {}
    return res;
  };

  theApi.getAvpEnCours = async () => {
    let res: ProjetSummary[] = [];
    try {
      res = await api(`${entityName}/encoursavp`, { method: 'GET' });
    } catch (error) {}
    return res;
  };

  theApi.findSignContact = async (
    id: number,
    ssttfours: number[] | boolean,
    withClients?: boolean,
    query?: string,
  ) => {
    const params = { ssttfours, clients: withClients, query };
    try {
      const res = await api<ApiResult<SignDestinataire[]>>(
        `${entityName}/${id}/signcontacts?${useQs(params)}`,
        {
          method: 'GET',
        },
      );

      return res?.data || [];
    } catch (error) {}
    return [];
  };

  return theApi;
};
