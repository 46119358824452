import ccmi from '~/documents/ccmi';
import cg from '~/documents/cg';
import type { DocumentType } from '~/documents/types';
import { useInformationsMarqueStore } from '~/stores/informationsMarque';
import { DocumentCategory, TypeConstructeur } from '~~/types/Enums';

export type DocumentTypes = {
  [key in DocumentCategory]: {
    name: string;
    label: string;
    required: boolean;
    substitute: DocumentType;
  }[];
};

export const useDocumentTypes = (): DocumentTypes => {
  const typeConstructeur = useInformationsMarqueStore().typeConstructeur;
  switch (typeConstructeur) {
    case TypeConstructeur.CG:
      return cg;
    default:
      return ccmi;
  }
};
