import { defineStore } from 'pinia';
import { useProjetsStore } from './projets';
import type { EPE } from '~/types/models/epe';
import { DocumentCategory } from '~/types/Enums';
import { DocumentType } from '~/documents/types';

export const useEpesStore = defineStore('epes', {
  state: () => ({
    epe: null as EPE | null,
  }),
  getters: {
    currentProjet: (state): any => {
      return useProjetsStore().currentProjet;
    },

    mandatoryDocs() {
      return useDocumentTypes()[DocumentCategory.EPE].filter((d) => d.required);
    },

    missingDocuments(state): DocumentType[] {
      const result = [];

      for (const doc of this.mandatoryDocs) {
        const docs = state.epe?.documents?.filter(
          (d) => d.type === doc.name || d.type === doc.substitute?.name,
        );
        if (!docs || docs.length < 1) {
          result.push(doc);
        }
      }
      return result;
    },
  },
  actions: {
    async fetch() {
      if (this.currentProjet) {
        const res = await useApi().epes.findOne(this.currentProjet.epe.id);
        if (res && res.data) {
          this.epe = res.data;
        }
      }
    },

    async create(epe: Creatable<EPE>) {
      const res = await useApi().epes.create(epe);
      if (res && res.data) {
        this.epe = res.data;
      }
    },

    async update(epe: Updatable<EPE>) {
      const res = await useApi().epes.update(epe);
      if (res && res.data) {
        this.epe = res.data;
      }
      await useProjetsStore().fetchJalons();
    },

    async refresh() {
      if (this.epe) {
        await this.fetch();
        await useProjetsStore().fetchJalons();
      }
    },

    reset() {
      this.epe = null;
    },
  },
});
