import type { Prepachantier } from '~/types/models/prepachantier';
import type { ApiClient, GenericApi } from '~~/composables/useApiFactory';

const entityName = 'prepaschantiers';

const populate = {
  documents: { populate: { media: true, signature: true } },
  projet: true,
};

const searchFields = [''];

type Api = GenericApi<Prepachantier> & {};

export default (api: ApiClient) => {
  const theApi = useApiFactory(api, entityName, searchFields, populate) as Api;

  return theApi;
};
