import { defineStore } from 'pinia';
import { DateTime } from 'luxon';

import { useParametresStore } from './parametres';
import { useProjetsStore } from './projets';
import { useMetresStore } from './metres';
import { useMetreOptionsStore } from './metreOptions';
import { usePermisStore } from './permis';
import { useFinancementsStore } from './financements';
import { useTerrainsProjetsStore } from './terrainsProjets';
import { useCcisStore } from './ccis';
import { useEpesStore } from './epes';
import { useGarantiesStore } from './garanties';
import { usePrepaschantiersStore } from './prepaschantiers';
import { useChantiersStore } from './chantiers';

import { DocumentType } from '~/documents/types';

import type { Contrat } from '~~/types/models/contrat';
import type { Avenant, AvenantMetas } from '~~/types/models/avenant';
import type { Document } from '~/types/models/document';
import type { PnlData } from '~~/types/models/pnlProjet';

import {
  AvenantStatus,
  DocumentCategory,
  MetreStatus,
  ParametreKeys,
  JalonPnl,
} from '~~/types/Enums';

const emptyAvenant = (metas?: AvenantMetas): Creatable<Avenant> => ({
  libelle: '',
  conceptionInterieur: metas?.isConception || false,
  travaux: metas?.isTravaux || false,
  dureeAdditionnelleTravaux: metas?.dureeTravauxAdditionnelle || 0,
  options: [],
  articlesLibres: [],
  detailsOptions: [],
});

export const useContratsStore = defineStore('contrats', {
  state: () => ({
    contrat: null as Contrat | null,
    currentAvenant: null as Avenant | Creatable<Avenant> | null,
  }),
  getters: {
    dateEngagementNotice: (state) => {
      if (!state.contrat?.dateSignature) return '';
      const dureeEngagement = useParametresStore().paramByKey(
        ParametreKeys.DUREE_ENGAGEMENT_NOTICE,
      );

      const dateFinEngagement = DateTime.fromISO(state.contrat?.dateSignature)
        .plus({ days: dureeEngagement.valeur })
        .toISODate();

      return dateFinEngagement;
    },

    currentMetre(): any {
      if (!this.currentAvp) return null;
      return this.currentAvp.metre;
    },

    currentAvp(): any {
      if (!this.contrat?.projet?.avps || !this.contrat.projet.avps.length) return null;
      return this.contrat.projet.avps[0];
    },

    currentProjet(): any {
      return useProjetsStore().currentProjet;
    },

    currentProjetAvp(): any {
      return useProjetsStore().lastAvp;
    },

    getNextNumeroAvenant(): number {
      if (!this.contrat?.avenants || !this.contrat.avenants.length) return 1;
      const avenantsSignes = this.contrat.avenants.filter(
        (a: Avenant) => a.statut === AvenantStatus.SIGNE,
      );
      if (!avenantsSignes.length) return 1;

      return Math.max(...avenantsSignes.map((a: Avenant) => a.numero!)) + 1;
    },

    getAvtSigneDocument() {
      return (numeroAvenant: number): Document | null => {
        const avenant = this.contrat?.avenants?.find((a) => a.numero === numeroAvenant);
        if (avenant && avenant.document) {
          return avenant.document;
        }
        for (const doc of this.contrat?.documents || []) {
          if (
            doc.type === DocumentType.AVENANT_SIGNE.name &&
            new RegExp(`^AVT_${numeroAvenant}`).test(doc.name)
          ) {
            return doc;
          }
        }
        return null;
      };
    },

    mandatoryDocs() {
      return useDocumentTypes()[DocumentCategory.CONTRAT].filter((d) => d.required);
    },

    canValidateAdv(): boolean {
      return this.allMissingDocuments?.length === 0;
    },

    isRecepisseRarUploaded(): boolean {
      const rar = this.contrat?.documents?.find(
        (d: Document) => d.type === DocumentType.RECEPISSE_RAR.name,
      );
      return !!rar;
    },

    canMoveToValidAdv(): boolean {
      return !!this.contrat?.dateSignature;
    },

    canMoveToLeveCondSusp(): boolean {
      return !!this.contrat?.dateReceptionRar && this.isRecepisseRarUploaded;
    },

    canMoveToRar(): boolean {
      return !!this.contrat?.dateValidationAdv;
    },

    findDocuments() {
      return (type: { name: string; substitute: DocumentType }): Document[] => {
        return (
          this.contrat?.documents?.filter(
            (d) => d.type === type.name || d.type === type.substitute?.name,
          ) || []
        );
      };
    },

    missingDocuments(): DocumentType[] {
      const result = [];
      let docNumberRequired = 1;
      for (const doc of this.mandatoryDocs) {
        if (doc.name === DocumentType.POUVOIR.name) {
          docNumberRequired =
            this.currentProjet?.clients.length > 1 ? this.currentProjet.clients.length : 0;
        }
        if (this.findDocuments(doc).length < docNumberRequired) {
          result.push(doc);
        }
      }
      return result;
    },

    allMissingDocuments(): { label: string; docs: DocumentType[] }[] {
      const result = [];
      const contratDocs = this.missingDocuments;
      if (contratDocs.length) {
        result.push({ label: 'Contrat', docs: contratDocs });
      }

      const permisDocs = usePermisStore().missingDocuments;
      if (permisDocs.length) {
        result.push({ label: 'PC', docs: permisDocs });
      }

      const finDocs = useFinancementsStore().missingDocuments;
      if (finDocs.length) {
        result.push({ label: 'Financement', docs: finDocs });
      }

      const terrainDocs = useTerrainsProjetsStore().missingDocuments;
      if (terrainDocs.length) {
        result.push({ label: 'Terrain', docs: terrainDocs });
      }

      const epeDocs = useEpesStore().missingDocuments;
      if (epeDocs.length) {
        result.push({ label: 'Plans EXE', docs: epeDocs });
      }

      return result;
    },

    delaiRetractionEnCours(): boolean | undefined {
      if (this.contrat?.dateReceptionRar) {
        const dr = DateTime.fromISO(this.contrat.dateReceptionRar).plus({ days: 10 });
        const now = DateTime.now();
        return now < dr;
      }
      return undefined;
    },

    isDelaiConditionsModifiable() {
      return (contrat: Contrat): boolean => {
        if (!contrat?.projet) return false;
        return useProjetsStore().projetAvancementIs(
          contrat.projet,
          '<',
          useConstants.avancements.TRAVAUX,
        );
      };
    },

    isDelaiTravauxModifiable() {
      return (contrat: Contrat): boolean => {
        // TODO: check if projet is in SAV
        return true;
      };
    },

    pendingAvenants(): Avenant[] {
      return (
        this.contrat?.avenants?.filter(
          (a) => a.statut === AvenantStatus.VALIDE || a.statut === AvenantStatus.ENVOYE,
        ) || []
      );
    },

    isAvenantPropositionSent() {
      return (avenant: Avenant): boolean => !!avenant.dateProposition;
    },
    isAvenantPropositionReturned() {
      return (avenant: Avenant): boolean =>
        this.isAvenantPropositionSent(avenant) &&
        !!avenant.dateRetourProposition &&
        DateTime.fromISO(avenant.dateRetourProposition) >=
          DateTime.fromISO(avenant.dateProposition!);
    },
    isAvenantPropositionPending() {
      return (avenant: Avenant): boolean =>
        this.isAvenantPropositionSent(avenant) && !this.isAvenantPropositionReturned(avenant);
    },
  },
  actions: {
    openAvenantPanel(avenant?: Avenant, metas?: AvenantMetas) {
      this.currentAvenant = avenant ? avenant : emptyAvenant(metas);
    },

    closeAvenantPanel() {
      this.currentAvenant = null;
    },

    async fetchByProjet(id: number) {
      if (id < 0) return;
      this.contrat = await useApi().contrats.findByProjet(id);
      this.currentProjet.contrat = this.contrat;
    },

    async fetch() {
      await this.fetchByProjet(this.currentProjet.id);
    },

    async refresh() {
      if (this.contrat) {
        await this.fetchByProjet(this.contrat.projet.id);
      }
    },

    async update(contrat: Contrat) {
      if (contrat?.dateSignature) {
        this.setReference(contrat);
        this.setBT01(contrat);
      }
      const result = await useApi().contrats.update(contrat);
      if (result) {
        this.contrat = result.data;
      }
    },

    async createAllPPs() {
      if (this.contrat?.projet?.id) {
        await Promise.all([
          usePermisStore().create({ projet: this.contrat.projet.id }),
          useFinancementsStore().create({ projet: this.contrat.projet.id }),
          useTerrainsProjetsStore().create({ projet: this.contrat.projet.id }),
          useCcisStore().create({ projet: this.contrat.projet.id }),
          useEpesStore().create({ projet: this.contrat.projet.id }),
          useGarantiesStore().create({ projet: this.contrat.projet.id }),
          usePrepaschantiersStore().create({ projet: this.contrat.projet.id }),
          useChantiersStore().create({ projet: this.contrat.projet.id }),
        ]);
      }
    },

    async create(contrat: Contrat) {
      if (contrat?.dateSignature) {
        const result = await useApi().contrats.create(contrat);
        if (result) {
          this.contrat = result.data;

          if (this.contrat) {
            await this.update(this.contrat);
            await this.createAllPPs();
            useMetresStore().moveTo(this.currentMetre, MetreStatus.VALIDE, {
              action: 'Signature contrat',
              date: this.contrat.dateSignature,
              version: this.currentAvp.version,
            });
            await useProjetsStore().moveTo(
              this.currentProjet.id,
              useConstants.avancements.VALIDATION_ADV,
            );
          }
        }
      }
    },

    getLastPnlProjet(contrat: Contrat): PnlData | null {
      if (!contrat?.pnlProjet?.datas?.length) return null;
      return contrat.pnlProjet.datas[contrat.pnlProjet.datas.length - 1];
    },

    setMetadatas(contrat: Contrat, dureeConditions?: number, dureeTravaux?: number) {
      if (!contrat) return null;
      if (!contrat.metadatas) {
        contrat.metadatas = {};
      }
      if (dureeConditions) contrat.metadatas.dureeConditions = dureeConditions;
      if (dureeTravaux) contrat.metadatas.dureeTravaux = dureeTravaux;
      return contrat;
    },

    async signContrat(
      contrat: Contrat,
      date: string,
      acompte: number,
      dureeConditions?: number,
      dureeTravaux?: number,
    ) {
      const isCreation = !contrat?.id;
      if (isCreation) {
        (contrat as Creatable<Contrat>) = {
          projet: this.currentProjet.id,
        };
      }

      contrat.dateSignature = date;
      contrat.montantAcompte = acompte;

      this.setMetadatas(contrat, dureeConditions, dureeTravaux);
      if (isCreation) {
        await this.create(contrat);
      } else {
        await this.update(contrat);
      }

      await useApi().contrats.savePnl(this.contrat!.id, JalonPnl.SIGNATURE_CONTRAT, date);

      await this.refresh();
    },

    howmanyDocUploaded(docType: string): number {
      let count = 0;
      for (const doc of this.contrat?.documents || []) {
        if (doc.type === docType) count++;
      }
      return count;
    },

    async validateAvenant(avenant: Avenant) {
      if (!avenant) return;
      avenant.statut = AvenantStatus.VALIDE;
      avenant.dateValidation = DateTime.now().toISODate() || '';
      avenant.numero = this.getNextNumeroAvenant;

      if (avenant.options && avenant.options.length) {
        const optionsStore = useMetreOptionsStore();
        for (const option of avenant.options) {
          await optionsStore.fixOption(option);
        }
      }

      await this.updateOrCreateAvenant(avenant);
    },

    async unvalidateAvenant(avenant: Avenant) {
      if (!avenant) return;
      if (this.isAvenantPropositionReturned(avenant)) {
        avenant.statut = AvenantStatus.PROPOSITION_RETOURNEE;
      } else if (this.isAvenantPropositionPending(avenant)) {
        avenant.statut = AvenantStatus.PROPOSITION_EN_COURS;
      } else {
        avenant.statut = AvenantStatus.EN_COURS;
      }

      avenant.dateValidation = null as any;
      avenant.numero = 0;

      if (avenant.options && avenant.options.length) {
        const optionsStore = useMetreOptionsStore();
        for (const option of avenant.options) {
          await optionsStore.freeOption(option);
        }
      }

      await this.updateOrCreateAvenant(avenant);
    },

    async cancelAvenant(avenant: Updatable<Avenant>) {
      if (!avenant) return;
      avenant.statut = AvenantStatus.ANNULE;
      avenant.numero = null;
      avenant.dateValidation = null as any;
      if (avenant.options && avenant.options.length) {
        const optionsStore = useMetreOptionsStore();
        for (const option of avenant.options) {
          await optionsStore.freeOption(option);
        }
      }
      avenant.options = [];

      await this.updateOrCreateAvenant(avenant);
    },

    async sendAvenant(avenant: Avenant) {
      if (!avenant) return;
      avenant.statut = AvenantStatus.ENVOYE;
      avenant.dateEnvoi = DateTime.now().toISODate() || '';
      await this.updateOrCreateAvenant(avenant);
    },

    async signAvenant(avenant: Avenant, date: string) {
      if (!avenant || !this.contrat) return;
      avenant.statut = AvenantStatus.SIGNE;
      avenant.dateSignature = date;
      await this.updateOrCreateAvenant(avenant);

      const avenantAdditionalDelay = avenant.dureeAdditionnelleTravaux || 0;

      if (avenantAdditionalDelay > 0) {
        if (!this.contrat.metadatas) {
          this.contrat.metadatas = { dureeTravaux: 0 };
        }

        this.contrat.metadatas.dureeTravaux =
          (this.contrat.metadatas.dureeTravaux || 0) + avenantAdditionalDelay;

        await useApi().contrats.update({
          id: this.contrat.id,
          metadatas: this.contrat.metadatas,
        });
      }

      await useApi().contrats.savePnl(
        this.contrat.id,
        JalonPnl.SIGNATURE_AVENANT,
        date,
        avenant.id,
      );

      await this.refresh();
    },

    setReference(contrat: Contrat) {
      if (!contrat?.dateSignature) return;
      const dateSignature = DateTime.fromISO(contrat?.dateSignature);
      const year = dateSignature.year.toString().substring(2);
      contrat.reference = `${year}_${contrat?.id}`;
    },

    setBT01(contrat: Contrat) {
      if (!contrat?.dateSignature) return;
      const dateSignature = DateTime.fromISO(contrat?.dateSignature);
      const dureeEngagementPrix = useParametresStore().paramByKey(
        ParametreKeys.DUREE_ENGAGEMENT_PRIX,
      )?.valeur;
      if (!dureeEngagementPrix) return;
      const bt01 = dateSignature.plus({ days: dureeEngagementPrix }).toISODate();

      contrat.BT01 = bt01 || '';
    },

    async updateOldContrat() {
      if (this.contrat?.dateSignature && !this.contrat?.BT01) {
        this.setBT01(this.contrat);
        this.setReference(this.contrat);
        await this.update(this.contrat);
      }
    },

    reset() {
      this.contrat = null;
    },

    async updateOrCreateAvenant(avenant: Creatable<Avenant> | Updatable<Avenant>) {
      if (this.contrat) {
        const avenantAlreadyCreated = avenant.contrat?.id !== undefined;

        if (!avenantAlreadyCreated) {
          if (!avenant.statut) avenant.statut = AvenantStatus.EN_COURS;
          avenant.contrat = { id: this.contrat.id };
        }

        const action = avenantAlreadyCreated
          ? useApi().contratAvenants.update
          : useApi().contratAvenants.create;

        // @ts-ignore
        const result = await action(avenant);
        if (result && result.data) {
          if (this.contrat?.avenants) {
            if (avenantAlreadyCreated) {
              const index = this.contrat.avenants.findIndex((a) => a.id === result.data.id);
              if (index >= 0) {
                this.contrat.avenants.splice(index, 1, result.data);
              }
            } else {
              this.contrat.avenants.push(result.data);
            }
          }
          this.currentAvenant = null;
        }
      }
    },

    async deleteAvenant(avenant: Avenant) {
      if (this.contrat) {
        const result = await useApi().contratAvenants.delete(avenant);
        if (result && result.data) {
          for (const option of avenant.options || []) {
            await useMetreOptionsStore().freeOption(option);
          }
          this.contrat.avenants = this.contrat.avenants?.filter((a) => a.id !== avenant.id);
        }
      }
    },
  },
});
